#feedback--section {
    width: 70%;
    // height: 150px;
    margin: 0 auto;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 20px;
    margin-bottom: 25px;

    p.head-text{
        color: black;
        font-size: larger;
        font-weight: 500;
        font-style: italic;
        position: absolute;
        top: -10px;
        left: 10px
    }
    p.faint-text{
        position: absolute;
        top: 25px;
        left: 20px;
        font-size: small;
        color: gray;
        margin-bottom: 10px;
    }
    .block-1{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 55px;
        margin-bottom: 20px;

        input.input-comment{
            width: 65%;
            height: 30px;
            border-radius: 8px;
            border: 1px solid gray;
            text-indent: 7px;
            // color: gray;
            font-size: small;
        }
        button.add-comment{
            background-color: #3A86FF;
            padding: 6px 0px;
            width: 145px;
            color: white;
            font-size: smaller;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}