#cancelSub {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #828282;
    border-radius: 8px;

    .left-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .cry {
            width: 10%;
            height: 10%;
        }
        span {
            color: #3681DE;
        }
        h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            text-align: center;
            color: #3681DE;
        }
        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #7E7E7E;
            margin-top: 14px;
        }
        .btn {
            width: 40%;
            margin: 10px 0;
        }
        .back-to-course {
            text-decoration: underline;
        }
    }

    .right-content {
        width: 50%;
        .image {
            width: 100%;
            height: 100%;
            align-items: center;
        }
    }
}