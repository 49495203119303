@import '../../../globalStyles/helpers/variables';

#myCourses {
    #header {
        display: flex;
        justify-content: space-between;

        h1{
            font-size: 28px;
            color: $secondaryColor;
        }

        Button {
            width: auto;
        }

    }
    // .filter__section{
    //     width: 150px;
    //     // padding-inline: 2em;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;

    // }
    .search__et__create{
        margin-block: 1em;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input__cont{
            width: 80%;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 8px;
            position: relative;
            height: 100%;

            input{
                color: $grey3;
                border-radius: 8px;
            }

            &:focus{
                outline: none;
            }

            img{
                position: absolute;
                z-index: 100;
                right: 10px;
                top: 8px;
                cursor: pointer;
            }

        }
        a{
            height: 100%;
            display: block;
            padding: .2em;
            // width: 18%;
            border-radius: 8px;
            cursor: pointer;
        }

        .create_btn{
            width: 100%;
            border-radius: 8px;
            background-color: #3A86FF;
            padding-inline: 1em;
            font-size: $body;
            cursor: pointer
        }
    }

    .card__wrapper{
        width: 100%;
        margin-top: 3em;

        .loader_cont{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .empty_data{
            height: 10vh;
            font-weight: 600;
            font-size: $mediumHeadings;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media screen and (max-width: 850px) {
        .card__wrapper{
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            padding-inline: 1em;
            margin-block: 0;
        }

        .search__et__create{
            // .input__cont{

            // }
            a{
                padding: .1em;
            }
        }
    }

    @media (max-width: 500px) {
        .search{
            flex-direction: column;
        }

        .search__et__create{
            a{
                padding: 0 !important;
                font-size: 12px;
                // width: 30%;
            }

            .input__cont input::placeholder{
                font-size: .8em;
            }
        }
    }
}