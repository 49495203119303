@import "../../../../globalStyles/helpers/variables";

#what {
  // background: $lightGrey;
  background: #F8F8F8;
  .container {
    padding-top: 4em;
    padding-bottom: 4em;
    h2 {
      color: $secondaryColor;
      // text-align: center;
      display: flex;
      justify-content: center;
      // background-color: red;
      // padding-bottom: 3rem;
      font-size: 40px;
      width: 100%;
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .images{
        width: 48%;
        height: 100%;
        position: relative;
        text-align: center;

        .main-img{
          width: 90%;
          height: 100%;
          position: relative;
          z-index: 1;

          img{
            width: 100%;
            height: 100%;
          }
        }

        .elipse-graphic{
          position: absolute;

          &.e1{
            top: -4em;
            right: .1em;
          }

          &.e2{
            bottom: -4em;
            left: -4em;
          }
        }
      }
      .text-content {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .item {
          // margin: 1rem 0;
          height: 25vh;
          display: flex;
          flex-direction: column;
          overflow:auto;
          h3 {
            margin-bottom: .1em;
            color: $secondaryColor;
          }
          ul li {
            margin: 10px 0;
            color: black;
            font-size: 1em;
            line-height: 24.4px
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  #what .container {
    &>h2{
      margin-bottom: 2em;
    }
     
    .content{
      flex-direction: column;

      .images{
        width: 80%;

        .main-img{
          width: 100%;
        }

        .elipse-graphic{
          &.e1{
            right: -4em;
          }
        }
      }

      .text-content{
        margin-top: 4em;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #what {
    .container {
      padding-bottom: 3rem;
      h2 {
        padding-bottom: 2rem;
      }
      .content {
        flex-direction: column;
        .images {
          margin-right: 0;
          margin-bottom: 2rem;
          .main-img {
            width: 100%;
          }
        }
      }
    }
  }
}
