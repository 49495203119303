#teacherInfo {
  background: #F8F8F8;
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 2fr;
  margin: 40px 0px;
  padding: 40px 0px;

  #content {
    grid-column: 2;

    #teacherGraphic2{
      display: none;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #03023B;
      text-align: center;
    }
    #bio {
      #teacherName {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.06em;
        color: #03023B;
      }
      #jobTitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.06em;
      }
      #follow {
        margin-right: 30px;
      }
      a{
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.06em;
        color: #3A86FF;
      }
    }

    #ratings {
      margin: 30px 0px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: repeat(4, 25%);

      #teacherGraphic1 {
        grid-column: 1;
        grid-row: span 4;
        margin: auto;
        width: 90px;
      }
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: #4F4F4F;
        display: inline;
      }
      img {
        display: inline-block;
      }
    }





    .info {
        padding-right: 5%;
        padding-left: 1%;
    }
      
    .readHideTeach {
        color: #0837E5;
        margin-left: 40%;
        cursor: pointer;
    }
   
  }
}


//min-width: 768px:
@media only screen and (min-width: 768px) {
  #teacherInfo {
    grid-template-columns: 15vw 2fr 15vw;

    #content {
      display: grid;
      grid-template-columns: 120px 1fr;
      grid-template-rows: 28px 60px 60px 1fr;
      h2{
        text-align: start;
      }
      #teacherText {
        grid-column: span 2;
      }
      .text {
        grid-column: span 3;
        grid-row: 4;
      }



      #teacherGraphic2 {
        display: block;
        grid-column: 1;
        grid-row-start: 2;
        grid-row-end: 4;
        margin: auto;
      }
      #bio {
        grid-column: span 2;
        grid-row: 2;
        display: grid;
        grid-template-columns: 25% 20% 55%;
        grid-template-rows: repeat(2, 50%);
        padding-left: 10px;

        

        #jobTitle {
          grid-row: 2;
          grid-column: span 3;
        }
      }

      #ratings{
        grid-column: span 2;
        grid-row: 3;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 25px 25px;
        margin: 0px;
        padding-left: 10px;
        #teacherGraphic1 {
          display: none;
        }
        #rating1{
          grid-column: 1;
          grid-row: 1;
        }
        #rating2{
          grid-column: 2;
          grid-row: 1;
        }
        #rating3{
          grid-column: 1;
          grid-row: 2;
        }
        #rating4{
          grid-column: 2;
          grid-row: 2;
        }
        p{
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.06em;
        }
      }

      .readHideTeach {
        display: flex;
        h2 {
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.02em;
        }

      
        .text {
          position: relative;
          overflow: hidden;
        }
      
        .text .readHide { 
          position: absolute; 
          bottom: 0; 
          left: 0;
          width: 100%; 
          text-align: center; 
          margin: 0;
          background-image: linear-gradient(to bottom, transparent, black);
        }

      }
    

    }
  }
}

  //min-width: 1024px:
  @media only screen and (min-width: 1024px) {
    #teacherInfo {
      grid-template-columns: 15vw 2fr 15vw;

      #content {
        grid-template-rows: 50px 60px 60px 1fr;
        h2{
          font-size: 24px;
          line-height: 29px;
          padding-bottom: 20px;
        }
        #ratings {
          grid-template-columns: 25% 23% 30% 22%;
          grid-template-rows: 1fr;
          #rating3 {
            grid-column: 3;
            grid-row: 1;
          }
          #rating4 {
            grid-column: 4;
            grid-row: 1;
          }
        }
      }
    }
  }