@import '../../../globalStyles/helpers/variables';

// ACTIVE, EMPTY, INVALID INPUT STYLING
:root {
    --input-border: 1px solid;
    --input-active-border: $secondaryColor;
    --input-valid-border: $successColor;
    --input-error-border: $errorColor;
    --input-shadow: 0px 0px 0px 4px;
    --input-active-shadow: rgba(78, 147, 255, 0.2);
    --input-valid-shadow: rgba(49, 208, 170, 0.2);
    --input-error-shadow: rgba(237, 104, 104, 0.2);
    --input-radius: 8px;
}

.error {
    margin-top: 0.5rem;
    color: $errorColor;
    list-style: none;
    padding: 0;
    font-size: 12px;
    text-align: left;
}
.tc {
    margin-left: 40px;
    margin-bottom: 1px;
}

.form-group .has-error .form-contrl {
    border: 1px solid red;
}
*,
input::before,
input::after {
    box-sizing: border-box;
}

input:required:focus:enabled {
    border: var(--input-border) var(--input-active-border);
    box-shadow: var(--input-shadow) var(--input-active-shadow);
    border-radius: var(--input-radius);
}

input:required:valid {
    border: var(--input-border) var(--input-valid-border);
    box-shadow: var(--input-shadow) var(--input-valid-shadow);
    border-radius: var(--input-radius);
}

input:focus:required:invalid {
    border: var(--input-border) var(--input-error-border);
    box-shadow: var(--input-shadow) var(--input-error-shadow);
    border-radius: var(--input-radius);
}

.form-btn {
    display: flex;
    align-items: center;
    margin: 50px auto;
    width: 100%;
    height: 50px;
    background: $grey5;
    border: 2px solid $grey5;
    box-sizing: border-box;
    border-radius: 8px;
    a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

// REGISTER PAGE
#register {
    // background: linear-gradient(to right, #f8f8f8 53.3%, white 45%);
    max-height: 100vh;
    overflow: hidden;
    // margin-top: 0;

    position: relative;
    .contain {
        display: grid;
        grid-template-columns: 52% 1fr;
        grid-template-rows: 1fr;
        // margin: 0 auto;
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }
    .exit-to-home-right {
        position: fixed;
        right: 20px;
        font-size: 1rem;
        z-index: 1;
        color: black;
        cursor: pointer;
        top: 20px;
    }

    .form-content-left {
        display: flex;
        padding: 26px;
        flex-direction: column;
        background: #f8f8f8;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .form-content-right {
        overflow-y: auto;
    }

    // .logo-zustech {
    //     margin-top: 40px;
    //     margin-bottom: 64px;
    //     background: white;
    //     width: fit-content;
    //     padding: 5px 10px 5px 10px;
    //     border-radius: 112px;
    // }

    .signup-text {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        width: 100%;
        h1 {
            font-weight: bold;
            line-height: 75px;
            letter-spacing: 0.05em;
            color: $primaryColor;
            margin-bottom: 1.5rem;
        }
        p {
            color: $grey3;
            font-size: 24px;
            line-height: 29px;
            font-weight: bold;
            letter-spacing: 0.02em;
            margin-bottom: 60px;
        }
    }

    .img-1 {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        .signup-img {
            height: auto;
            width: calc(100% - 50px);
        }
    }

    .logo-zustech-form {
        position: relative;
    }

    .signup-text-right {
        display: none;
    }

    // form {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    // }

    .signup-form {
        margin: auto;
        max-width: 80%;
        overflow-x: hidden !important;
        overflow-y: scroll;
    }

    .signUp-btn {
        height: 47px;
        float: right;
        width: 100%;
        background: white;
        color: $secondaryColor;
        border: 1px solid $grey5;
        box-sizing: border-box;
        border-radius: $normal-radius;
        font-weight: bold;
        font-size: 16px;
        line-height: 17px;
        align-items: center;
        letter-spacing: 0.04em;
        cursor: pointer;
        transition: 0.5s ease;
        &:hover {
            background: white;
            color: $primaryColor;
        }
    }

    .signIn-btn {
        height: 47px;
        float: left;
        width: 100%;
        border-radius: 6px 0 0 6px;
        background: $grey5;
        border: 1px solid $grey5;
        color: #828282;
        font-weight: bold;
        font-size: 16px;
        line-height: 17px;
        align-items: center;
        letter-spacing: 0.04em;
        cursor: pointer;
        transition: 0.5s ease;
        &:hover {
            background: $lightGrey;
            color: $grey1;
        }
    }

    .form-contrl,
    textarea {
        font-size: $body;
    }

    .form-inputs p {
        color: $errorColor;
    }

    .top-row {
        display: flex;
        width: 80%;
        margin-bottom: 1.5rem;
        &:after {
            content: '';
            display: table;
            clear: both;
        }

        > .form-group {
            float: left;
            width: 48%;
            margin-right: 4%;
            &:last-child {
                margin: 0;
            }
        }
        .form-contrl {
            display: inline-block;
            padding-left: 10px;
            height: 48px;
            border: 1px solid $grey4;
            border-radius: $normal-radius;
            width: 100%;
            outline: none;
        }
    }

    // .form-inputs {
    //     position: relative;
    //     margin-bottom: 1rem;
    //     width: 360px;
    //     .form-contrl {
    //         display: block;
    //         padding: 0 10px;
    //         // outline: none;
    //         width: 100%;
    //         border-radius: $normal-radius;
    //         height: 48px;
    //         width: 100%;
    //         // border: 1px solid $grey4;
    //     }
    // }

    .pwd {
        position: relative;
        margin-bottom: 0rem;
        .password-toggle-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
    }

    button:disabled {
        opacity: 0.3;
    }

    .separator {
        display: flex;
        width: 80%;
        margin: 10px auto;
    }

    .separator .line {
        background: gray;
        border-bottom: 1px solid gray;
        width: 8em;
        height: 0px;
        margin: 10px auto;
    }

    .btn-wrapper {
        display: flex;
        justify-content: center;
        width: 80%;
    }

    .form-input-btn {
        box-sizing: border-box;
        border-radius: $medium-radius;
        padding: 15px 41px;
        background: $secondaryColor;
        color: white;
        font-weight: bold;
        font-size: $body;
        cursor: pointer;
        transition: 0.5s ease;
        &:hover {
            background: $primaryColor;
            color: $grey6;
        }
    }
}

.logo-zustech-form {
    background: white;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    margin-bottom: 30px;
    border-radius: 112px;
    a {
        text-decoration: none;
    }
}

@media (max-width: 900px) {
    .welcome-message {
        display: none;
    }
    .exit-to-home-right {
        position: fixed;
        right: 20px;
        font-size: 1rem;
        z-index: 1;
        color: black;
        cursor: pointer;
        top: 20px;
    }
    #register {
        background: $lightGrey;
        overflow: visible;
        height: 100vh;

        .contain {
            grid-template-columns: 1fr;
            background: $lightGrey;
        }

        .reg-btn-wrapper {
            margin: 140px 0 40px 0;
        }

        .form-content-left {
            display: none;
        }

        .form-content-right {
            background: $lightGrey;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .signup-text-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            h1 {
                max-width: 400px;
                font-weight: bold;
                font-size: 2.37em;
                line-height: 36px;
                letter-spacing: 0.02em;
                color: $primaryColor;
                margin-bottom: 2rem;
            }
            p {
                color: $grey3;
                font-size: 18px;
                margin-bottom: 32px;
            }
        }

        .signup-form {
            top: 40%;
        }

        .form-btn {
            margin: 2rem auto;
        }
    }
}
@media (min-width: 813px) and (max-width: 1260px) {
    #register {
        .reg-btn-wrapper {
            margin: 60px 0 40px 0;
        }
    }
}

@media (max-width: 812px) {
    #register {
        height: 100%;
        .contain {
            grid-template-columns: 1fr;
            justify-content: center;
            width: 100%;
        }

        .return-home-right {
            display: none;
        }

        .signup-form {
            max-width: 80%;
        }

        .form-content-left {
            display: none;
        }

        .form-btn {
            margin: 50px auto;
        }
    }
}

@media screen and (max-width: 767px) {
    #register {
        background: white;
        .contain {
            background: white;
            .form-content-right {
                background: white;
            }
        }
    }
}

// @media screen and (max-width: 1023px) {
//   #register .signup-text h1 {
//     margin-bottom: 0;
//   }
// }

// fixes content overflowing from top
@media screen and (max-height: 812px) {
    #register {
        height: 100%;
        .contain .form-content-right {
            height: 100%;
            .signup-form .form-btn {
                margin: 50px auto;
            }
        }
    }
}

// SIGNIN PAGE
/*for mobile screen devices 375px and 480px */
#signin {
    width: 100%;
    margin-top: 0;
    position: relative;

    .welcome-message {
        padding-left: 4rem;
    }

    main {
        width: 100%;
        height: 100vh;
    }
    .form-container {
        max-width: 400px;
        width: 100%;
        .form-btn {
            display: flex;
            align-items: center;
            margin: 2rem auto;
            width: 80%;
            height: 50px;
            background: $grey5;
            border: 2px solid $grey5;
            box-sizing: border-box;
            border-radius: 8px;
            a {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }
        .signIn-btn {
            height: 47px;
            float: right;
            width: 100%;
            background-color: white;
            color: $secondaryColor;
            border: 1px solid $grey5;
            box-sizing: border-box;
            border-radius: $normal-radius;
            font-weight: bold;
            font-size: 16px;
            line-height: 17px;
            align-items: center;
            letter-spacing: 0.04em;
            cursor: pointer;
            transition: 0.5s ease;
            &:hover {
                background: white;
                color: $primaryColor;
            }
        }

        .signUp-btn {
            height: 47px;
            float: left;
            width: 100%;
            border-radius: 6px 0 0 6px;
            background: $grey5;
            border: 1px solid $grey5;
            color: #828282;
            font-weight: bold;
            font-size: 16px;
            line-height: 17px;
            align-items: center;
            letter-spacing: 0.04em;
            cursor: pointer;
            transition: 0.5s ease;
            &:hover {
                background: $lightGrey;
                color: $grey1;
            }
        }
    }

    // .signIn-btn {
    //   width: 120px;
    //   height: 50px;
    //   margin-left: 0;
    //   background-color: white;
    //   color: $secondaryColor;
    //   border: 2px solid $grey5;
    //   box-sizing: border-box;
    //   border-radius: 8px;
    //   font-family: "Roboto";
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 16px;
    //   line-height: 17px;
    //   align-items: center;
    //   letter-spacing: 0.04em;
    // }

    // .signUp-btn {
    //   width: 120px;
    //   height: 48px;
    //   border-radius: 0 6px 6px 0;
    //   padding: 5px 24px;
    //   background: $grey5;
    //   border: 1px solid $grey5;
    //   color: #828282;
    //   font-family: "Roboto";
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 16px;
    //   line-height: 17px;
    //   align-items: center;
    //   letter-spacing: 0.04em;
    // }

    form .header {
        margin-bottom: 30px;
    }

    form .header h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #0837e5;
    }

    form .header p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.06em;
        color: $grey3;
    }

    form {
        width: 100%;
        height: 100%;
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-contrl {
        margin: 0px auto;
        margin-bottom: 1.5rem;
        width: 90%;
    }

    .form-contrl input {
        display: inline-flex;
        padding-left: 10px;
        outline: none;
        border-radius: $normal-radius;
        height: 48px;
        width: 100%;
        // border: 1px solid $grey4;
        font-size: 1rem;
    }

    .form-contrl p {
        color: red;
        font-style: italic;
        text-align: right;
        font-size: 10px;
        margin: 1px 30px 0 0;
    }

    form .submit-btn {
        background: #3a86ff;
        border: 2px solid #3a86ff;
        box-sizing: border-box;
        border-radius: 8px;
        display: block;
        width: 128px;
        border-radius: 6px;
        margin: 30px auto;
        padding: 12px 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 17px;
        align-items: center;
        letter-spacing: 0.04em;
        color: #ffffff;
        cursor: pointer;
    }

    .forgot-password {
        margin: 1rem 0;
    }

    .checkbox {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-left: 19px;
        font-weight: 500;
        position: relative;
        label {
            cursor: pointer;
        }
        .remember-details {
            display: flex;
            align-items: center;
        }
    }

    // input[type='checkbox'] {
    //     appearance: none;
    //     background-color: #ecf1f4;
    //     cursor: pointer;
    //     margin-right: 15px;
    //     // margin-left: 20px;
    //     height: 20px;
    //     width: 20px;
    //     // display: flex;
    //     // align-items: center;
    //     // justify-content: center;
    //     transition: 0.5s ease;
    //     border-radius: 4px;
    //     outline: none !important;
    //     // top: -30px;
    //     box-shadow: none !important;
    // }

    // input[type='checkbox']:hover {
    //     background-color: $successColor;
    // }
    // .submit-btn:hover {
    //     background-color: blue;
    // }

    // input[type='checkbox']:checked {
    //     background-color: $successColor;
    // }

    // input[type='checkbox']:checked:after {
    //     display: block;
    // }

    .separator {
        display: flex;
        justify-content: space-aroun;
        width: 80%;
        margin: 15px auto;
    }
    .separator .line {
        background-color: gray;
        border: 1px solid gray;
        width: 7em;
        height: 0px;
        margin: 10px auto;
    }
    .remember-warning {
        color: #f5222d !important;
        text-align: left !important;
        font-size: 13px !important;
        padding-bottom: 3%;
    }
    .forgot-pwd-link a {
        text-decoration: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.06em;
        color: #3a86ff;
        font-weight: 500;
        line-height: 140%;
        align-items: center;
    }
}

.ant-modal.signin{
    .ant-modal-body {
        h3.heading {
            font-size: 1.4em;
            color: $primaryColor;
        }

        .btn_cont{
            margin-top: 1em;
            display: flex;
            justify-content: space-evenly;
        
            .cancel_btn, .logout_btn {
                width: 30%;
                padding: .4em;
                font-size: 1.1em;
                font-weight: 600;
                color: black;
                background-color: $grey5;
                border-radius: 10px;
                cursor: pointer;
            }

            .logout_btn{
                color: white;
                background-color: $primaryColor;
            }
        }
    }
}

.form-contrl .psd {
    position: relative;
}
.form-contrl .psd img {
    position: absolute;
    top: 11px;
    padding: 8px;
    right: 3px;
    min-height: 29px;
    max-width: 36px;
}
.form-field {
    display: flex;
}
.asterisk {
    font-size: large;
    font-weight: 700;
    color: red;
}
/*for ipad and tablet mobile devices*/
@media screen and (min-width: 481px) and (max-width: 768px) {
    #signin {
        main {
            width: 100%;
        }

        main form .form-btn {
            width: 270px;
        }

        .separator {
            display: flex;
            width: 60%;
            margin: 10px auto;
        }
        .separator .line {
            background-color: gray;
            border: 1px solid gray;
            width: 7em;
            height: 0px;
            margin: 10px auto;
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 899px) {
    #signin {
        width: 100%;
        main form .form-btn {
            width: 290px;
        }
        .separator {
            width: 60%;
        }
    }
}

/*for desktop and bigger laptop screens*/
@media screen and (min-width: 900px) and (max-width: 1200px) {
    #signin {
        width: 100%;
        background: linear-gradient(to left, #f8f8f8 53.3%, white 45%);
        .exit-to-home-right {
            position: absolute;
            right: 40px;
            font-size: 1rem;
            z-index: 1;
            color: black;
            cursor: pointer;
            top: 40px;
        }
        .welcome-message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: $lightGrey;
            .welcome-container {
                padding-left: 2rem;
            }
        }
        .welcome-message h1 {
            font-weight: bold;
            line-height: 75px;
            letter-spacing: 0.02em;
            color: $primaryColor;
            margin-bottom: 1.5rem;
        }
        .welcome-message p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.02em;
            color: #828282;
            position: relative;
            margin-bottom: 60px;
            left: 10px;
        }
        .welcome-message img {
            width: 100%;
            height: auto;
            max-width: 524px;
        }

        form .header {
            display: none;
        }

        main {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 0;
        }
        form {
            width: 82%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        main form .form-container .form-btn {
            margin: 50px auto;
        }

        .separator {
            display: flex;
            width: 80%;
            margin: 10px auto;
        }
        .separator .line {
            background-color: gray;
            border: 1px solid gray;
            width: 7.3em;
            height: 0px;
            margin: 10px auto;
        }
    }
}

/*for desktop and bigger laptop screens*/
@media screen and (min-width: 1201px) {
    #signin {
        width: 100%;
        background: linear-gradient(to left, #f8f8f8 53.3%, white 45%);

        .exit-to-home-right {
            position: absolute;
            right: 40px;
            font-size: 1rem;
            z-index: 1;
            color: black;
            cursor: pointer;
            top: 40px;
        }
        .welcome-message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: auto;
            width: 100%;
            background: $lightGrey;
        }
        .welcome-message h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            line-height: 75px;
            letter-spacing: 0.05em;
            color: #0837e5;
            margin-bottom: 1.5rem;
        }
        .welcome-message p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.02em;
            color: #828282;
            margin-bottom: 3rem;
        }
        form .header {
            display: none;
        }

        main {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 0;
        }
        form {
            width: 82%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        main form .form-container .form-btn {
            margin-bottom: 50px;
        }

        .separator {
            display: flex;
            width: 80%;
            margin: 10px auto;
        }
        .separator .line {
            background-color: gray;
            border: 1px solid gray;
            width: 7.3em;
            height: 0px;
            margin: 10px auto;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
    #signin {
        background: #f8f8f8;
    }
}

// fixes content overflowing from top
@media screen and (max-width: 899px) and (max-height: 676px) {
    #signin {
        height: 100%;
        main {
            height: 100%;
        }
    }
}

@media screen and (max-height: 676px) {
    #signin {
        height: 100%;
        main {
            height: 100%;
        }
    }
}

#change-password {
    max-width: 300px;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin: auto auto;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        font-size: 1.5em;
        line-height: 29px;
        /* or 121% */

        letter-spacing: 0.02em;
        /* Blue Secondary */
        color: #3a86ff;
        margin-bottom: 10px;
    }
}
