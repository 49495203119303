.cards-section {
    width: 100%;
    margin-bottom: 20px;
    // margin-left: 180px;
    // margin-right: 180px;
    .no_courseError{
        text-align: center;
        font-size: 1.3em;
        color: red;
        width: 100%;
        height: 4em;
    }
}

.top-card {
    margin-top: 40px;
}

.top-picks-container, .popular-container, .trending-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding-inline: 1em;
    justify-content: space-evenly;

    // .no_courseError{
    //     text-align: center;
    //     font-size: 1.3em;
    //     color: red;
    //     width: 100%;
    // }
    // grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    // margin: 20px 0px 30px 0px;
}

// .popular-container {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1em;
//     padding-inline: 1em;
//     // grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//     // margin: 20px 0px 30px 0px;
// }

// .trending-container {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1em;
//     padding-inline: 1em;
//     // grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//     // margin: 20px 0px 30px 0px;
// }

@media (min-width: 1910px) {
    .top-picks-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 20px 100px;
        margin: 20px 0px 30px 0px;
    }

    // .top-picks-title {
    //     font-size: 28px;
    //     display: flex;
    //     justify-content: center;
    //     font-weight: 800;
    //     margin-bottom: 20px;
    // }
}

@media (max-width: 1935px) {
    .cards-section {
        // margin-left: 10%;
        // margin-right: 10%;
        margin-bottom: 20px;
    }
}

@media (max-width: 1050px) {
    .cards-section {
        // margin-left: 8%;
        // margin-right: 8%;
        margin-bottom: 20px;
    }
}

@media (max-width: 1024px) {
    .cards-section {
        transform: scale(0.9);
        margin: -10px -20px 20px -20px;
    }
}

@media (max-width: 992px) {
    .cards-section {
        transform: scale(1);
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 40px;
    }
}

@media (max-width: 727px) {
    .cards-section {
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }
    // .top-picks-container {
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // }
    // .popular-container {
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // }

    // .trending-container {
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // }
}

// @media (max-width: 425px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .cards-section {
//         margin-left: 30px;
//     }
// }

// @media (max-width: 400px) {
//     .cards-section {
//         margin-left: 18px;
//     }
// }

// @media (max-width: 375px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .cards-section {
//         margin-left: 10px;
//     }
// }

// @media (max-width: 350px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }

//     .cards-section {
//         margin-left: -7px;
//     }
// }