@import '../../../globalStyles/helpers/variables';

#payoutpage{
    // padding: 1em;

    h2{
        color: $secondaryColor;
        font-size: 1.6rem;
    }

    .paymentOptions{
        margin: 0;

        .stripeConnect{
            max-width: 550px;
            background-color: #fff;
            padding: 1em;
            border-radius: 7px;
            box-shadow: 1px 1px .1em 1px grey;
            margin: 0;

            .text_cont, .ctas{
                padding-inline: 1em;
            }

            .text_cont{
                margin-bottom: 1em;
                p{
                    margin-block: .8em;
                }
            }

            .ctas{
                display: flex;
                justify-content: space-between;
                align-items: center;

                a{
                    text-decoration: underline;
                    text-transform: lowercase;
                    cursor: pointer;
                }

                button{
                    padding: .4em 1em;
                    background-color: $secondaryColor;
                    color: white;
                    border-radius: 6px;
                    cursor: pointer;
                    transition: all .4s ease-in;

                    &:hover{
                        background: lightblue;
                        color: rgb(74, 71, 71);
                    }
                }
            }
        }
    }
}

.ant-modal.CreateCustomerLink{
    .ant-modal-content{
        .ant-modal-body{
            button a{
                padding: .4em 1em;
                background-color: $secondaryColor;
                color: white;
                border-radius: 6px;
                cursor: pointer;
                transition: all .4s ease-in;

                &:hover{
                    background: lightblue;
                    color: rgb(74, 71, 71);
                }
            }

            button{
                margin-top: 1em;
            }
        }
    }
}