@import '../../../../../globalStyles/helpers/variables';

.overview_section {
    h2{
        font-size: $mediumHeadings;
        font-weight: 700;
        padding-inline: .5em ;
    }
    
    p{
        font-size: $body;
        font-weight: 400;
        width: 100%;
        padding-inline: 1.4em ;
    }

    .course_info{
        width: 100%;
        padding-inline: 1.4em;
        min-height: 15vh;
        border-block: 2px solid rgba(224, 224, 224, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block: 1em;
        flex-wrap: wrap;
        gap: 1em;
        
        .info_item{
            max-height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            text-align: center;

            .info_header{
                font-size: $smallHeadings;
                font-weight: 700;
                text-align: center;
            }
        }
    }

    .objectives{
        min-height: 15vh;
        padding-bottom: 1.4em;
        // background-color: blue;
        
        .objectives_items ul{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(auto, 5vh);
            gap: 1em;
            list-style-type: none;
            list-style-image: url('../../../../../assets/icons/obj_icon.svg');
            
            li{
                width: 100%;
                padding-inline: .3em;
            }
        }
    }
    
    .prerequisite{
        min-height: 15vh;
        padding-block: 1.4em;
        // background-color: red;
        border-block: 2px solid rgba(224, 224, 224, 1);

        .prerequisite_items ul{
            list-style-type: none;
            list-style-image: url('../../../../../assets/icons/prep_icon.svg');
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(auto, 5vh);
            gap: 1.3em;

            li{
                width: 100%;
                padding-inline: .3em;
            }
        }
    }

    .instructor_into{
        padding: 1em;
        width: 100%;

        h3{
            color: black !important;
        }

        .instructor_details{
            width: 100%;
            height: auto;
            display: flex;
            font-size: $body;
            margin-bottom: 1em;
            
            .img_cont{
                margin-right: 2em;
            }

            .instructor_info{
                width: 100%;

                .first_layer{
                    width: 80%;
                    display: flex;
                    justify-content: space-between;
                    // flex-wrap: wrap;

                    div{
                        width: 50%;
                        display: flex;
                        justify-content: space-between;

                        .follow{
                            color: $secondaryColor;
                            font-weight: 400;
                            cursor: pointer;
                            padding-inline: .2em;
                        }
                    }

                    &>span{
                        min-width: 40%;
                        font-weight: 700;
                    }
                }
                
                .next_layer{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    flex-wrap: wrap;
                    
                    img{
                        margin-right: .4em;
                    }
                }
            }
        }
    }
}