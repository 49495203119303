@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#student-support{
    width: 100%;
    height: 100%;
    // margin: 15px;
    font-family: "Roboto", sans-serif;
    .top-background{
        width: 100%;
        height: 300px;
        background-color: #c1d3ef;
        position: relative;
    
        .group1-img{
            position: absolute;
            top: 30%;
            left: 90px;
        }
        .group2-img{
            position: absolute;
            top: 20%;
            right: 45%;
        }
        .search-article{
            position: absolute;
            bottom: 15%;
            right: 5%;
            width: 300px;
            height: 40px;
            border: 1px solid #3A86FF;
            border-radius: 8px;
            text-indent: 10px;
        }
    }
    .top-link{
        margin: 10px 20px;
    }
    .nav-btns{
        width: 80%;
        display: flex;
        gap: 15px;
        margin: 15px auto;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 25px;
        background-color: transparent;
    }
    .nav-btns .btn{
        border: 1px solid #3A86FF;
        color: white;
        padding: 10px;
        background: #3A86FF;
        border-radius: 8px;
    }
    .btn:hover{
        background: white;
        color: #3A86FF;
    }
    .content-settings{
        padding-inline: 20px;
        h2{
            color: #3A86FF;
            font-size: 1.8rem;
        }
        p{
            font-size: 1.2rem;
            padding: 0 35px;
            padding-bottom: 12px;
        }
        .questions-container{
            position: relative;
            width: 80%;
            margin: 0 auto;
            
            .accordion__button{
                font-size: 1.2rem;
            }

            .accordion__panel{
                font-size: .8rem;
            }
        }
        .faq{
            width: 570px;
            // background-color:#F8F8F8;
            font-size: 18px;
            color: #0837E5;
            margin-bottom: 10px;
            // border: 0px solid black;
            border-radius: 5px;
            padding: 10px 20px 20px 20px;
            margin: 10px auto;
            box-shadow: -0px 4px 2px grey;
        }
        .article-faq{
            padding-bottom: 12px;
            color: #0837E5;
            cursor: pointer;
            .btn-question{
                 width:50px; 
            }
            .title-faq{
                display: flex;
                justify-content: space-between;
                color: #0837E5;
                text-decoration: underline;
                // ::before{
                //     content: "•";
                //     color: #0837E5;
                //     font-size: 7rem;
                //     margin: 0;
                //     padding: 0;
                // }
            }
    
        }
    }
}

@media screen and (max-width: 700px) {
    #student-support{
        .content-settings .questions-container{
            width: 90%;
        }
    }
}