@import '../../../globalStyles/helpers/variables';

#payment_loader_cont{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#revenue_page{
    padding-inline: .6em;

    .top_control{
        width: 100%;
        height: 8vh;
        display: flex;

        .info{
            width: 70%;
            height: 100%;

            select h2{
                font-size: 1.7em;
                font-weight: 700;
                color: $secondaryColor;
                padding: 0;
                margin: 0;
            }

            p{
                font-size: .8em;
                font-weight: 400;
                padding: 0;

                span{
                    color: $secondaryColor;
                }
            }
        }

        .main_control{
            height: 80%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .selector_dropDown{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 40%;
                height: 70%;
                border: 2px solid $secondaryColor;
                border-radius: 8px;
                background-color: #fff;
                cursor: pointer;
                box-shadow: 0px 2px 4px 0px #00000040;

                p{
                    width: 80%;
                    text-align: center;
                    color: $secondaryColor;
                }
            }

            #custom_range{
                width: 45%;
                height: 70%;
                font-size: .9em;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                background-color: #75a7f6b3;
                border: 2px solid $secondaryColor;
                border-radius: 8px;
                cursor: pointer;
                box-shadow: 0px 2px 4px 0px #00000040;
            }
        }
    }

    section.btn_cont{
        width: 100%;
        height: 2.3em;
        text-align: right;
        // background-color: red;

        #reportSummary{
            min-width: 15%;
            height: 100%;
            padding: .3em 1em;
            font-weight: 600;
            background-color: #75a7f6b3;
            border: 2px solid $secondaryColor;
            border-radius: 8px;
            cursor: pointer;
        }
    }

    .main_report{
        width: 100%;
        height: 55vh;
        display: flex;
        gap: 1.5em;
        margin-top: 10px;

        .leftSide{
            width: 75%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.5em;

            .upper_breakDown{
                width: 100%;
                height: 25%;
                display: flex;
                gap: 1.5em;
                
                .summary_cont{
                    background-color: #fff;
                    // width: calc(100%/3);
                    // max-width: calc(100%/2);
                    flex: 1;
                    border-radius: 8px;
                    padding: .6em;
                    box-shadow: 0px 2px 4px 0px #00000040;

                    h5{
                        font-size: 1em;
                        margin-bottom: .2em;
                    }

                    p{
                        font-size: 2em;
                        font-weight: 600;
                    }
                }
            }

            .my_course{
                width: 100%;
                max-height: 75%;
                background-color: white;
                padding: .5em;
                border-radius: 8px;
                box-shadow: 0px 2px 4px 0px #00000040;

                .table_hrd{
                    display: flex;
                    justify-content: space-between;
                    color: $secondaryColor;
                    margin-bottom: .2em;
                    font-weight: 600;
                    font-size: 1.9em;
                }

                .ant-table-wrapper{
                    .ant-table-container{
                        .ant-table-content{
                            .ant-table-tbody{
                                .table_row{
                                    font-weight: 500;
                                    font-size: 1.3em;
                                }
                            }
                        }
                    }
                }
            }
        }

        .course_distribution{
            padding: .1em;
            width: 25%;
            height: 100%;
            border-radius: 8px;
            background-color: white;
            box-shadow: 0px 2px 4px 0px #00000040;

            h2{
                font-size: 1em;
                font-weight: 500;
                color: $secondaryColor;
                padding: .3em;
                margin-bottom: 0;
            }

            .chart_key{
                // background-color: red;
                padding-left: 1em;

                .key{
                    display: flex;
                    margin-bottom: .1em;

                    .symbol{
                        width: 20px;
                        height: 20px;
                        margin-right: .5em;
                        padding-bottom: 1em;

                        &.symbol_1{
                            background: #67C587;
                        }

                        &.symbol_2{
                            background: #EAF6ED;
                        }

                        &.symbol_3{
                            background: #C9EAD4;
                        }
                        &.symbol_4{
                            background-color: #616864;
                        }
                    }

                    p{
                        font-size: .8em;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .payment_history{
        background-color: #fff;
        margin-top: 1em;
        padding: 1em;
        border-radius: 8px;
        margin-bottom: 1em;
        box-shadow: 0px 2px 4px 0px #00000040;

        .history_header{
            display: flex;
            justify-content: space-between;
            margin-bottom: .5em;
            color: $secondaryColor;
            font-weight: 700;
            font-size: 1.2em;

            div{
                width: 30%;
                display: flex;
                justify-content: space-between;

                button{
                    padding: .1em;
                    background-color: white;
                    width: 100px;
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 1em;
                }
            }
        }
    }

    .data_rep {
        margin-block: 1em;
        width: 100%;
        height: 500px;
        display: flex;
        flex: 1;
        gap: 1em;

        .data_chart{
            padding: 1em;
            flex: 1;
            flex-grow: 3;
            border-radius: 8px;
            background-color: white;
            box-shadow: 0px 2px 4px 0px #00000040;

            .chart_header{
                height: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                p{
                    min-width: 20%;
                    color: $secondaryColor;
                    font-weight: 700;
                    font-size: 1em;
                }
            }
        }

        .student_detail{
            flex: 1;
            // padding: .6em
            border-radius: 8px;
            background-color: white;
            box-shadow: 0px 2px 4px 0px #00000040;
            // height: auto;
            overflow-y: scroll;


            h2{
                color: $secondaryColor;
                font-weight: 700;
                font-size: 1em;
                padding: .5em 1em;
            }

            .month_card{
                width: 90%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                height: 2.8em;
                align-items: center;
                padding: .2em;
                font-weight: 700;

                div.glassy{
                    width: 50%;
                    background-color: #67C587;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    // font-weight: 700;
                }
            }
        }
    }

    .full_table_sec{
        margin: 0 auto;

        .first_layer_cont{
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: .7em;

            .nav_back{
                width: 30%;
                height: 100%;
                display: flex;
                align-items: center;

                .img_cont{
                    cursor: pointer;
                }

                p{
                    font-size: 1.4em;
                    font-weight: 700;
                    color: $secondaryColor;
                    margin-left: .4em;
                }
            }

            .controls{
                width: 30%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .field_cont{
                    width: 50%;
                    background-color: #fff;
                    color: $secondaryColor;
                    border: 2px solid $secondaryColor;
                    border-radius: 8px;
                    box-shadow: 0px 2px 4px 0px #00000040;
                }

                .filter_cont{
                    width: 30%;
                    color: $secondaryColor;
                    border-radius: 8px;
                    box-shadow: 0px 2px 4px 0px #00000040;
                }
            }
        }
    }
}

#invoice_page{
    #invoice_cont{
        margin: 0 auto;
        width: 95%;
        
        .upperSection{
            width: 100%;
            height:80px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .invoice_unique_info{
                height: 100%;
                width: 25%;

                .top{
                    font-size: 1.6em;
                    display: flex;
                    margin-bottom: 0;
                    align-items: center;

                    h2{
                        margin-bottom: 0;
                    }

                    span{
                        color: $secondaryColor;
                        margin-left: .3em;
                    }
                }

                .lower p{
                    font-size: .9em;
                    font-weight: 400;
                }
            }

            .button_like{
                width: 100px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                color: $secondaryColor;
                border: 1px solid $secondaryColor;
                border-radius: 8px;
                margin-right: 1em;
                cursor: pointer;

                p{
                    margin-left: .5em;
                    font-weight: 600;
                }
            }
        }

        .invoice_detail{
            width: 100%;
            height: auto;
            background-color: #fff;
            border-radius: 10px;
            padding: 1em 2.4em;

            .invoice_header{
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .company_logo{
                    width: 70%;
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .img_cont{
                        width: 200px;
                        height: 80%;
                        margin-left: 65%;
                        
                        img{
                            width: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .downloadDocument{
                    width: 150px;
                    height: 60%;
                    background-color: $secondaryColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    border-radius: 8px;
                    font-weight: 500;
                    cursor: pointer;
                    // margin-right: 4em;

                    .img_cont{
                        width: 30%;
                        height: 70%;
                        margin-left: 5px;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            .invoice_body{
                padding: .6em;
                .info_cont{
                    width: 100%;
                    height: 4em;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .userDetail{
                        width: 30%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        p{
                            text-transform: uppercase;
                            font-weight: 400;
                            font-size: 1em;
                        }

                        h2{
                            font-weight: 700;
                            font-size: 1.8em;
                            margin-top: .2em;
                            color: $secondaryColor;
                        }
                    }

                    .invoice_cred{
                        width: 30%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        p{
                            display: flex;
                            align-items: flex-end;

                            .key{
                                text-transform: uppercase;
                                font-weight: 600;
                                display: flex;
                                justify-content: space-between;
                                width: 40%;
                            }

                            .value{
                                margin-left: 1em;
                            }
                        }
                    }
                }

                .ant-table-thead{
                    background-color: $secondaryColor;

                    th.ant-table-cell{
                        background-color: transparent;
                        color: white;
                        text-align: center;
                    }
                }

                .ant-table-tbody{
                    tr.invoice_table_row:last-child, tr.invoice_table_row:nth-last-child(2){
                        font-weight: 700;
                    }
                }

                &>h5{
                    font-weight: 500;
                    text-align: center;
                    font-size: 1.2em;
                    margin-block: .2em 1em;
                }

                &>p{
                    font-size: .8em;
                    font-weight: 200;
                    color: $grey3;
                    font-style: italic;
                    margin-top: 2em;
                }
            }
        }
    }
}

.ant-modal.DownloadReportModal{
    .ant-modal-body{
        text-align: center;
        height: auto;

        .custom_range, .selector_dropDown, .btn_cont{
            width: 60%;
            height: 2em;
            margin: 0 auto;
            margin-block: 1em;   
        }

        .selector_dropDown, .custom_range{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 60%;
            height: 70%;
            border: 2px solid $secondaryColor;
            border-radius: 8px;
            background-color: #fff;
            cursor: pointer;
            box-shadow: 0px 2px 4px 0px #00000040;
        }

        .colorRed{
            border-color: red;
        }

        .btn_cont #reportSummary{
            min-width: 15%;
            height: 100%;
            padding: 0 1em;
            font-weight: 600;
            background-color: #75a7f6b3;
            border: 2px solid $secondaryColor;
            border-radius: 8px;
            cursor: not-allowed;
        }
    }
}