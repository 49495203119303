.Dashboard_sidebar {
    // height: 100vh;
    // width: %;
    // left: -100vw;
    // position: fixed;
    // z-index: 10;
    flex: 0 0 250px !important;
    // max-width: 250px !important;
    // min-width: 250px !important;
    // width: 250px !important;
    transition: left 0.3s ease-in-out;
}

.close-sidebar {
    display: none;
}

// @media (max-width: 992px) {
//     .ant-layout-sider {
//         display: none;
//     }
// }

.Dashboard_trigger {
    position: absolute;
    top: 46%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background: transparent;
    outline: none;
    // transition: ;
    transition: svg 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
