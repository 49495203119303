#checkout {
    display: block;
    width: 100%;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 2;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    #left-col {
        width: 50%;
        border-right: 3px solid;
        border-image: linear-gradient(#fff, #828282, #fff);
        border-image-slice: 1;
        float: left;
        padding: 1%;
    }

    #right-col {
        float: right;
        width: 50%;
        padding: 1%;
    }

    #purchase-date {
        font-weight: 800;
        font-size: 24px;
        text-align: center;
        padding-bottom: 2%;
        margin-top: 2%;
    }

    #annual-tag {
        padding: 4%;
    }

    #plan-container {
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.1) 29.14%,
            rgba(78, 147, 255, 0) 100%
        );
        border-radius: 8px;
        padding: 10px;
        border: 1px solid #828282;
        width: 80%;
        height: fit-content;
        margin-left: 10%;
    }

    #start-btn {
        padding: 2%;
        margin-left: 10%;
        width: 80%;
        margin-top: 18%;
        font-weight: 500;
    }

    #m1 {
        color: #3a86ff;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
    }

    #plan-desc {
        text-align: center;
        margin-top: 2%;
    }

    #start-text {
        text-align: center;
        margin-top: 15%;
    }

    #renewal-text,
    #price-text {
        font-weight: 800;
        text-align: center;
        margin-top: 5%;
        font-size: 24px;
    }

    #price-text {
        margin-bottom: 2%;
    }

    #thanks-message {
        text-align: center;
        font-size: 34px;
        margin: 2% 0 10%;
    }

    #bodytext {
        color: #03023b;
        font-weight: 400;
        text-align: center;
        width: 85%;
        margin-left: 7.5%;
        font-size: 16px;
    }

    #welcome {
        font-weight: 500;
    }

    #support-message {
        margin-top: 5%;
    }

    #subscription-message,
    #support-message,
    #bottom-thanks {
        font-weight: 300;
        font-size: 14px;
        width: 85%;
        margin-left: 7.5%;
        color: #9d9d9d;
    }

    #bottom-thanks {
        font-size: 18px;
        font-weight: 600;
        margin-top: 3%;
        color: black;
    }

    #subscription-message,
    #support-message {
        margin-top: 5%;
    }

    #continue-btn {
        margin: 5% 24.5% 2%;
        width: 51%;
        display: inline-block;
        font-weight: 500;
    }
}

@media (max-width: 767px) {
    #checkout {
        #left-col {
            width: 100%;
            border-right: 0px;
            border-bottom: 3px solid;
            border-image: linear-gradient(90deg, #fff, #828282, #fff);
            border-image-slice: 1;
            margin-bottom: 5%;
        }
        
        
        #right-col {
            width: 100%;
        }

        #price-text {
            margin-bottom: 5%;
        }
    }
}
