@import "../../../../globalStyles/helpers/variables";

.msg__cont{
    width: 80%;
    margin: 5vh auto 0;
    min-height: 70vh;
    background-color: $offWhite;
    border: 1px solid #BDBDBD;
    padding-block: 2em;
    border-radius: $small-radius;
    box-shadow: 0px 4.320000648498535px 4.320000648498535px 0px #0000001A;

    h3{
        color: $darkColor;
    }
    .top__cont{
        height: 80%;
        width: 100%;
        display: flex;

        .left__sec{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 2px solid gray;

            .img__main-cont{
                width: 70%;
                margin: 0 auto;
                // background-color: blue;

                .sub__cont{
                    width: 100%;

                    img{
                        width: 100%
                    }

                    #mov__up{
                        position: relative;
                        top: -10px;
                    }
                }

                .alone{
                    width: 70%;
                    margin: 0 auto;
                    position: relative;
                    top: -5em;

                    img{
                        width: 100%;
                    }
                }
            }
        }

        .right__sec{
            width: 50%;
            padding: 1.5em 2em;
            
            
            h3{
                text-align: center;
                margin-bottom: 1em;
                // width: 300px;
            }
            
            .text{
                text-align: center;
                font-weight: 400;
                font-size: 2em;
                line-height: 26px;
                // width: 300px;
                // font-size: 16px;

                .first__child, .second__child{
                    padding-bottom: 1em;
                    line-height: 26px;

                    .name{
                        color: $darkColor;
                        font-weight: 700;
                    }
                }
            }

            .more__info{
                font-weight: 500;
                font-size: 14px;
                padding-block: 1em;
                color: #9d9d9d;
                // margin: 1em 0;
            }
        }
    }

    .bott__cont{
        margin-top: 2em;
        text-align: center;
        // position: relative;
        // top: 15px;

        button{
            padding: .5em 2em;
            width: 30%;
            background-color: $secondaryColor;
            color: $offWhite;
            border-radius: $medium-radius;
        }
    }
}

@media (min-width: 901px) and (max-width: 1240px){
    .msg__cont{
        width: 90%;
    }
}

@media (max-width: 900px) {
    .msg__cont{
        padding: 0;

        .bott__cont{
            margin-bottom: 2em;
        }
    }
    .msg__cont .top__cont{
        flex-direction: column;
        width: 100%;

        .left__sec, .right__sec{
            width: 100%;
            text-align: center;
            border: none;

            .img__main-cont{
                width: 100%;

                .alone{
                    width: 40%;
                    top: -7em;
                }
            }

            .text{
                margin: 0 auto;
                font-size: 1em;
            }

            h3, p{
                width: 100%;
            }
        }
    }    
}