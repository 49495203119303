@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

$font-robotto: "Roboto", sans-serif;
$font-nunito: "Nunito", sans-serif;
$font-sans: "sans-serif";

body {
  font-family: $font-robotto;
  // font-size: 1rem;
  font-weight: normal;
}

h1,
h2,
h3 {
  font-weight: 700;
}

// Desktop Font Sizes
h1 {
  font-size: 4rem;
  letter-spacing: 5%;
}
h2 {
  font-size: 2.5rem;
  text-transform: capitalize;
  letter-spacing: 3%;
}
h3 {
  font-size: 1.5rem;
  text-transform: capitalize;
}
h4 {
  font-size: 1.125rem;
}
h3,
h4 {
  letter-spacing: 2%;
}
p {
  line-height: 140%;
  color: var(--dark-blue);
  font-size: 1rem;
  letter-spacing: 6%;
}
.link {
  font-weight: 700;
  letter-spacing: 6%;
  text-decoration: underline;
  color: var(--light-blue);
}
.pre-title {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 35.5%;
  text-transform: uppercase;
}
.small-text {
  font-size: 0.875rem;
  font-weight: 700;
}
// Tablet Font Sizes
@media screen and (max-width: 1024px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.125rem;
  }
}
// Mobile Font Sizes
@media screen and (max-width: 767px) {
  .display {
    font-size: 2.5rem;
  }
  h1 {
    font-size: 2.125rem;
  }
}

