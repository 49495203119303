#FAQSection {
    // width: 100%;

    margin: 40px 0px;

        #content {
            grid-column: 2;
            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #03023B;
                text-align: center;
                grid-column: 2;
                
            }
            .ant-collapse-header, #CourseContentSectionTitle p{
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 130%;
        
                display: flex;
                align-items: center;
                letter-spacing: 0.06em;
                color: #03023B;
            }
        }

}

@media only screen and (min-width: 481px){
    #FAQSection {
        margin: 30px 80px;
    }
}

//min-width: 769px:
@media only screen and (min-width: 769px) {
    #FAQSection {
        margin: 60px 15vw;
        
        #content {

            h2 {
                text-align: start;
                font-size: 24px;
                line-height: 29px;
            }
            .ant-collapse-header, #CourseContentSectionTitle p{
                font-size: 16px;
                line-height: 26px;
            }
        }

        

    }

}


  //min-width: 1024px:
  @media only screen and (min-width: 1024px) {
    #FAQSection {
      grid-template-columns: 15vw 2fr 15vw;
        #content {
            .ant-collapse-header, #CourseContentSectionTitle p{
                font-size: 24px;
                line-height: 29px;
            }
        }
    }
  }