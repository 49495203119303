@import '../../globalStyles/helpers/variables';

input.form-control,
textarea.form-control {
    outline: none !important;
    border-radius: 6px !important;
    padding: 0.7rem;
    box-shadow: none !important;
}

.ant-form-item-explain-error {
    div[role='alert'] {
        color: #f5222d !important;
        text-align: left !important;
        font-size: 14px !important;
    }
    padding-top: 4px;
    // padding-bottom: 16px;
}

input[type='checkbox'] {
    appearance: none;
    background-color: #ecf1f4;
    cursor: pointer;
    margin-right: 15px;
    height: 18px !important;
    width: 18px !important;
    transition: 0.5s ease;
    border-radius: 4px;
    outline: none !important;
    // top: -30px;
    box-shadow: none !important;
}

input[type='checkbox']:hover {
    background-color: $successColor;
}

input[type='checkbox']:checked {
    background-color: $successColor;
}

input[type='checkbox']:checked:after {
    display: block;
}

.form-check {
    text-align: left !important;
}

.eye-icon {
    position: absolute;
    top: 13px;
    right: 12px;
    background: transparent;
    outline: none;
    cursor: pointer;
}
