* {
    padding: 0;
    margin: 0;
}

/* #inputField {
    margin: 20px 0px;
    padding: 15px;
    width: 100%;
}

#toDoInput,
#dateInput {
    font-size: 20px;
    width: 100%;
} */

/* Works on Firefox */

#listFooter {
    display: none;
}

.title {
    font-size: 28px;
    font-weight: 700;
    color: #3a86ff;
}

#list {
    scrollbar-width: thin;
    scrollbar-color: #3a86ff #f0f2f5;
  }
  
  /* Works on Chrome, Edge, and Safari */
  #list::-webkit-scrollbar {
    width: 12px;
  }
  
  #list::-webkit-scrollbar-track {
    background: #f0f2f5;
  }
  
  #list::-webkit-scrollbar-thumb {
    background-color: #3a86ff;
    border-radius: 20px;
  }

#list {
    margin-bottom: 20px;
    overflow-x: auto;
    max-height: 800px;
}

.item {
    border-radius: 10px;
    padding: 15px;
    font-size: 16px;
    color: hsl(234, 39%, 85%);
    font-weight: 400;
    display: grid;
    grid-template-columns: 1fr 5fr;
    margin-bottom: 5%;
    border-left: solid 7.5px green;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.circle,
.circle.selected {
    grid-column: 1;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: auto;
    border: solid 1px;
    border-radius: 100%;
    padding: 10px;
    cursor: pointer;
    grid-row: span 2;
    width: 40px;
    height: 40px;
    display: flex;
    align-self: center;
}

.tick {
    opacity: 0;
}

.ticked {
    opacity: 100;
}

.tick,
.ticked {
    width: 150%;
}

.circle.selected {
    background-color: green;
}

.task {
    grid-column: 2;
    margin: auto 5px;
    cursor: pointer;
    color: #03023b;
}

/* .cross {
    grid-column: 3;
    margin: auto;
    cursor: pointer;
    margin-bottom: 10%;
} */

/* #listFooter {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 15px;
}
#numLeft {
    font-size: 15px;
    color: hsl(234, 11%, 52%);
}
#clearCompleted {
    font-size: 15px;
    text-align: right;
    cursor: pointer;
    color: hsl(234, 11%, 52%);
} */

/* #dragAndDrop {
    margin-top: 60px;
    margin-bottom: 80px;
    text-align: center;
    color: hsl(234, 11%, 52%);
} */
