$blue: #3a86ff;
$blueSecondary: #e3ecfb;

.courseReview {
    padding: 10px 50px;
    width: 100%;
    .moduleName {
        display: flex;
        justify-content: space-between;
    }
    .bottomButton {
        max-width: 15%;
        font-size: 1.3rem;
    }
    h2 {
        font-family: 'Roboto';
        margin-left: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        /* or 120% */
        display: flex;
        align-items: center;
        letter-spacing: 0.03em;
        /* Blue Secondary */
        color: $blue;
    }
    .instruction {
        // font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #222153;
        text-align: center;
        padding: 0.5rem;
    }
    .courseCard {
        h6 {
            color: $blue;
            font-size: 18px;
            font-weight: 800;
            margin: 0;
        }
        b {
            color: grey;
        }
    }
    .courseDetails {
        background: white;
        border: solid 10px $blueSecondary;
        border-radius: 30px;
        margin: 20px 10px;
        padding: 50px;
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 30px;
        .detail-1,
        .detail-6 {
            grid-column: 1/3;
        }
        .box {
            border: 1px solid #3a86ff;
            box-sizing: border-box;
            border-radius: 15px;
            width: 100px;
            text-align: center;
        }
    }
    h5 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        /* or 121% */
        letter-spacing: 0.02em;
        color: #03023b;
        margin: 10px;
    }
    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* or 165% */
        letter-spacing: 0.06em;
        /* Gray 2 */
        color: #4f4f4f;
        margin: 10px;
    }
    .module-lesson {
        background: white;
        border: solid 10px $blueSecondary;
        border-radius: 30px;
        margin: 20px 10px;
        padding: 50px;
        div {
            margin: 2rem 0;
        }
        .lessonName {
            display: flex;
            justify-content: space-between;
        }
        .resource {
            border: 1px solid rgba(58, 134, 255, 0.1);
            padding: 15px;
            background: rgba(58, 134, 255, 0.1);
            border-radius: 8px;
        }
        .content {
            background: #ffffff;
            border: 1px solid rgba(58, 134, 255, 0.38);
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
                0px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            font-size: 14px;
        }
        .content-box {
            width: 100%;
            display: flex;
            border-bottom: 2px dashed $blueSecondary;
            padding-bottom: 3rem;
            div {
                width: 75%;
            }
            .content {
                overflow: auto;
            }
            .item-2 {
                width: 25%;
                margin: 0;
                padding-top: 2rem;
            }
        }
    }
    Button {
        width: 300px;
        margin: 10px auto;
        // margin-left: 300px;
    }
    .comment {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        justify-content: center;
    }
    #textarea--wrapper{
        // width: 100px;
        position: relative;
        border: 1px solid #d9d9d9; /* Match Ant Design's border color */
        padding: 4px;
        border-radius: 2px; /* Match Ant Design's border radius */

        .ant-form-item{
            width: 70%;
            margin: 0 auto;
        }

        h3 .border--text {
            margin: 0 auto;
            position: absolute;
            top: -8px; /* Adjust based on your design needs */
            left: 10px; /* Adjust based on your design needs */
            background-color: #fff; /* Match your form's or page's background color */
            padding: 0 4px;
            font-size: 42px; /* Adjust based on your design needs */
            color: #999; /* Adjust based on your design needs */
        }
    }

    // .comment-textarea {
    //     height: 200px;
    //     padding: 1.5rem;
    //     width: 80%;
    //     margin: 2rem auto;
    // }

    .submit {
        display: flex;
        width: 60%;
        margin: 0 auto;
    }
    .submitButtons {
        // .reject {
        //     background-color: #eb5757;
        //     width: 240px;
        //     border-color: #eb5757;
        // }
        // .approve {
        //     width: 240px;
        // }
        // .delete {
        //     width: 240px;
        //     background-color: #eb5757;
        //     border-color: #eb5757;
        // }
    }
}

@media screen and (max-width: 768px) {
    .courseReview {
        padding: 10px 0;

        .courseDetails {
            display: flex;
            flex-direction: column;
        }

        .module-lesson {
            padding: 50px 10px;
            .content-box {
                flex-direction: column;
                div {
                    width: 100%;
                }
                .item-2 {
                    display: flex;
                    justify-content: flex-end;
                    padding: 0;
                    width: 100%;
                    h5 {
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
            }
        }

        .comment-textarea {
            width: 95%;
        }
    }
}
// .courseReview {

//     .courseReviewContainer {
//         max-width: 800px;
//         margin: auto;
//     }

//     .profile {
//         display: flex;
//         margin: 50px auto 10px;
//         padding: 0 1rem;
//         .profilePic {
//             border: 1px solid black;
//             border-radius: 5px;
//             width: 70px;
//             height: 60px;
//             margin-right: 10px;
//         }
//         .profileText {
//             height: 2rem;
//             margin-top: 9px;
//         }
//         h6 {
//             font-family: 'Roboto';
//             font-style: normal;
//             font-weight: 700;
//             font-size: 18px;
//             /* identical to box height, or 121% */
//             letter-spacing: 0.02em;
//             color: $blue;
//             margin: 0;
//         }
//         p {
//             // font-family: 'Work Sans';
//             font-style: normal;
//             font-weight: 400;
//             font-size: 16px;
//             line-height: 19px;
//             letter-spacing: -0.02em;
//             color: #222153;
//         }

//         b {
//             color: rgb(117, 117, 117);
//             font-weight: 400;
//         }
//     }

//     .courseDetails {
//         background: white;
//         border: solid 10px $blueSecondary;
//         border-radius: 30px;
//         margin: 20px 10px;
//         padding: 30px;

//         .content h5 {
//             font-size: 20px;
//             font-weight: bold;
//         }
//         .courseSummary,
//         .courseModule {
//             display: flex;
//             margin: 10px 0 50px 10px;
//         }
//         .courseCategory,
//         .coursePrerequisite {
//             display: flex;
//             margin: 10px 0 50px 10px;
//             p {
//                 display: flex;
//                 border: 1px solid #3a86ff;
//                 box-sizing: border-box;
//                 border-radius: 15px;
//                 padding: 5px;
//                 width: fit-content;
//                 flex-wrap: wrap;
//             }

//             .contentBody {
//                 display: flex;
//                 width: 100%;
//                 justify-content: space-between;
//             }

//             .content {
//                 width: 45%;
//             }

//             .level,
//             .language {
//                 width: 45%;
//                 display: flex;
//                 flex-direction: column;
//                 align-items: flex-start;
//             }
//         }

//         @media screen and (max-width: 768px) {
//             .courseDetails {
//                 padding: 5px;
//             }

//             .courseSummary,
//             .courseCategory,
//             .coursePrerequisite {
//                 flex-direction: row;
//                 align-items: flex-start;
//                 justify-content: flex-start;
//             }

//             .courseSummary,
//             .courseModule,
//             .courseCategory,
//             .coursePrerequisite,
//             .level,
//             .language {
//                 justify-content: flex-start;
//                 align-items: flex-start;
//             }

//             .contentBody {
//                 width: 100%;
//                 flex-direction: column;
//                 align-items: flex-start;

//                 .content,
//                 .level,
//                 .language {
//                     width: 100%;
//                 }

//                 .content {
//                     margin-bottom: 2rem;
//                 }
//             }
//         }
//     }

//     .moduleSectionHeader {
//         margin: 5rem 0 1rem 0 !important;
//         color: #3a86ff;
//         margin-top: 2rem;
//         font-size: 24px;
//     }

//     .moduleSection {
//         p {
//             display: flex;
//             flex-wrap: wrap;
//         }
//         .courseContainer {
//             .moduleTitle {
//                 display: flex;
//                 align-items: center;
//                 h5 {
//                     margin-left: 0.5rem;
//                     font-size: 24px;
//                     font-weight: bold;
//                 }
//             }
//             .moduleContent {
//                 margin: 1rem 0;
//             }
//         }

//         .moduleContainer {
//             border-bottom: 2px dashed $blueSecondary;
//             &::after {
//                 border-bottom: 2px dashed $blueSecondary;
//                 content: '';
//                 background: black;
//                 position: absolute;
//                 bottom: 0;
//                 left: 0;
//                 height: 0%;
//                 width: 20px;
//             }
//             div h4 {
//                 font-size: 20px;
//                 font-weight: bold;
//                 margin-top: 2rem;
//             }
//         }
//     }

//     .additionalComments {
//         display: flex;
//         height: 200px;
//         padding: 1rem 0;
//         justify-content: space-between;
//         gap: 1rem;

//         .addtionalCommentsText {
//             width: 100%;
//         }

//         .addtionalCommentsText textarea {
//             width: 100%;
//             padding: 0.5rem;
//             border-radius: 0.5rem;
//         }

//         button {
//             width: 150px;
//             background: #3a86ff;
//             border-radius: 5px;
//             padding: 0.8rem;
//             color: white;
//         }
//     }
//     @media screen and (max-width: 768px) {
//         .additionalComments {
//             flex-direction: column;
//         }
//     }
// }
