@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import '../../../../globalStyles/helpers/variables';

#about-section {
    display: flex;
    justify-content: center;
    background: #e0ecff;
    position: relative;
    font-family: Roboto;
    padding-top: 200px;
    width: 100%;

    .custom-shape-divider-top-1621535573 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1621535573 svg {
        position: relative;
        display: block;
        width: calc(119% + 1.3px);
        height: 136px;
    }

    .custom-shape-divider-top-1621535573 .shape-fill {
        fill: #f5f5f5;
        border: none;
    }

    .container {
        align-items: center;
        width: 100%;
        max-width: 1300px;
        margin: 0;
        // transform: scale(0.9);
    }

    .info-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 60px;

        h1 {
            color: #3a86ff;
        }

        p {
            color: #3a86ff;
            font-size: 22px;
            margin-top: -20px;
        }

        .why-title {
            font-size: 40px;
        }

        .zustech-title {
            font-size: 110px;
            margin-top: -20px;
        }
    }

    .info-cards {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
    }

    .feature-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 0;
        overflow: scroll;
        max-width: 100%;
        padding: 20px;
        height: 293px;
        // width: 269px;
        width: 300px;
        background: $offWhite;
        border-radius: 8px;
        box-shadow: 0 8px 6px -6px rgb(172, 170, 170),
            1px 0 15px -10px rgb(172, 170, 170),
            -1px 0 8px -10px rgb(172, 170, 170);
        &:nth-of-type(1) {
            border-top: 4px solid $tertiaryColor;
        }
        &:nth-of-type(2) {
            border-top: 4px solid $primaryColor;
        }
        &:nth-of-type(3) {
            border-top: 4px solid $secondaryColor;
        }
        &:nth-of-type(4) {
            border-top: 4px solid $successColor;
        }

        .card-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 200px;
            h3 {
                margin-top: 30px;
            }
        }
    }

    .second-container {
        display: flex;
        position: relative;
        margin: 270px 70px 100px 70px;
        align-items: center;

        svg {
            position: relative;
        }
    }

    .woman-img {
        position: absolute;
        top: -110px;
        left: 0;
        width: 320px;
        height: auto;
    }

    .left-text-container {
        transform: scale(1);
        margin-top: -40px;
        margin-left: 70px;
        color: #3a86ff;
        display: flex;
        flex-direction: column;

        h2 {
            color: #3a86ff;
            font-size: 52px;
        }

        p {
            margin-right: auto;
            margin-left: auto;
            font-size: 22px;
        }

        .earning {
            background-color: greenyellow;
        }

        .earning {
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 60px;
            font-size: 22px;
            background-color: #3a86ff;
            color: white;
            font-weight: bold;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: #196bf0;
            }
        }

        button {
            padding: 15px 35px;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;
            background-color: #3a86ff;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: #196bf0;
            }
        }
    }

    .bottom-wave {
        position: absolute;
        top: 1650px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        background-color: #f5f5f5;
    }

    .bottom-wave-fill {
        fill: #e0ecff;
    }
}

@media (max-width: 1350px) {
    #about-section {
        .info-cards {
            transform: scale(0.9);
        }

        .info-text {
            p {
                transform: scale(0.9);
            }
        }
    }
}

@media (max-width: 1160px) {
    #about-section {
        .info-cards {
            grid-template-columns: 300px 300px;
            gap: 50px 50px;
            transform: scale(1);
        }

        .info-text {
            p {
                transform: scale(0.9);
            }
        }

        .second-container {
            transform: scale(0.9);
        }

        .bottom-wave {
            top: 1950px;
        }
    }
}

@media (max-width: 1000px) {
    #about-section {
        .second-container {
            display: flex;
            position: relative;
            margin: 270px 0px 100px 0px;
            align-items: center;
        }

        // .left-text-container {
        //     button {
        //         margin-right: auto;
        //     }
        // }
    }
}

@media (max-width: 825px) {
    #about-section {
        .second-container {
            transform: scale(0.8);
            margin-left: -40px;
            margin-top: 140px;
        }

        .bottom-wave {
            top: 1810px;
        }
    }
}

@media (max-width: 740px) {
    #about-section {
        .second-container {
            transform: scale(0.8);
            margin-left: -60px;
        }
    }
}

@media (max-width: 683px) {
    #about-section {
        .second-container {
            transform: scale(0.7);
            margin-left: -80px;
            margin-top: 120px;
        }

        .bottom-wave {
            top: 1800px;
        }
    }
}

@media (max-width: 700px) {
    #about-section {
        .info-cards {
            grid-template-columns: 300px 300px;
            gap: 50px 50px;
            transform: scale(0.9);
        }

        .info-text {
            p {
                transform: scale(0.9);
            }
        }
    }
}

@media (max-width: 624px) {
    #about-section {
        .second-container {
            flex-direction: column;
            transform: scale(0.6);
            margin-left: -0px;
            margin-top: -0px;
        }

        .info-cards {
            grid-template-columns: 300px;
            gap: 20px 20px;
            transform: scale(1);
        }

        .woman-img {
            position: absolute;
            top: -110px;
            left: 65px;
            width: 320px;
            height: auto;
        }

        .left-text-container {
            transform: scale(1);
            margin-left: 0px;
            width: 600px;

            h2 {
                margin-top: 80px;
                font-size: 48px;
            }

            button {
                transform: scale(1.2);
            }
        }

        .bottom-wave {
            top: 2550px;
        }

        .info-text {
            .why-title {
                font-size: 28px;
            }

            .zustech-title {
                font-size: 78px;
            }
        }
    }
}

@media (max-width: 525px) {
    #about-section {
        .info-cards {
            grid-template-columns: 300px;
            gap: 20px 20px;
            transform: scale(1);
        }

        .woman-img {
            position: absolute;
            top: -110px;
            left: 0px;
            width: 320px;
            height: auto;
        }

        .bottom-wave {
            top: 2550px;
        }

        .second-container {
            flex-direction: column;
            transform: scale(0.6);
            margin-left: -0px;
            margin-top: -20px;
        }
    }
}

@media (max-width: 455px) {
    #about-section {
        .info-cards {
            grid-template-columns: 300px;
            gap: 20px 20px;
            transform: scale(1);
        }

        .left-text-container {
            transform: scale(1);

            p {
                text-align: center;
            }
        }
    }
}

@media (max-width: 425px) {
    #about-section {
        .woman-img {
            position: absolute;
            top: -110px;
            left: 0px;
            width: 320px;
            height: auto;
        }

        .left-text-container {
            transform: scale(1);

            h2 {
                font-size: 38px;
                text-align: center;
                margin-left: 0;
                margin-right: 0;
                width: 100%;
            }
        }

        .info-text {
            .zustech-title {
                font-size: 70px;
            }
        }
    }
}

@media (max-width: 370px) {
    #about-section {
        .left-text-container {
            width: 400px;
        }

        .woman-img {
            position: absolute;
            top: -110px;
            left: -40px;
            width: 320px;
            height: auto;
        }

        .bottom-wave {
            top: 2650px;
        }
    }
}
@media (max-width: 350px) {
    #about-section {
        .bottom-wave {
            top: 2650px;
        }
    }
}

@media (max-width: 320px) {
    #about-section {
        .info-cards {
            grid-template-columns: 1fr;
            transform: scale(0.9);
        }

        .left-text-container {
            transform: scale(1);

            p {
                font-size: 20px;
                margin: 0 50px;
            }
        }

        .bottom-wave {
            top: 2640px;
        }

        .info-text {
            .zustech-title {
                font-size: 60px;
            }
        }

        .woman-img {
            position: absolute;
            top: -110px;
            left: -70px;
            width: 320px;
            height: auto;
        }
    }
}
