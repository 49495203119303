@import "../../../../globalStyles/helpers/variables";

#pricing {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    min-height: 70vh;
    border: 1px solid #828282;
    box-sizing: border-box;
    padding: 20px 0;
    min-width: 50px;
    
    p {
        padding-top: 12px;
        font-weight: normal;
        line-height: 26px;
        letter-spacing: 0.06em;
    }
    h4 {
        text-align: center;
    }

    .container {
        text-align: center;
        margin-top: 15px;
        h2 {
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0.05em;
        }

    }
    .pricing-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        
        .card {
            background: #ffffff;
            width: 30%;
            box-shadow: 0px 0px 0px 0px black, 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            padding: 20px 0 40px 0;
            min-height: 50vh;

            .header {
                margin-bottom: -30px;
                width: 100%;
                .naira, .pounds {
                    width: 15px;
                    height: 18px;
                    margin-top: -4px;
                    margin-right: 2px;
                }
                .wrapper {
                    padding: 3px;
                    margin: 20px 0;
                }
                .features {
                    width: 100%;
                    .left-feature {
                        margin-left: -25px;
                        padding-left: 40px;
                        padding-bottom: 20px;
                        list-style-type: none;
                        background: url(./assets/tick-grey.png) no-repeat;
                    }
                    .center-feature {
                        margin-left: -25px;
                        padding-left: 40px;
                        padding-bottom: 20px;
                        list-style-type: none;
                        background: url(./assets/tick-green.png) no-repeat;
                    }
                    .right-feature {
                        margin-left: -25px;
                        padding-left: 40px;
                        padding-bottom: 20px;
                        list-style-type: none;
                        background: url(./assets/tick-blue.png) no-repeat;
                    }
                }
            }
            .btn {
                width: 100%;
                background-color: #3A86FF;
            }
            .btn:hover{
                box-shadow: 0 0 10px #3A86FF;
              }
        }
        .left-card {
            border-top: 5px solid gray;
        }
        .center-card {
            border-top: 5px solid green;
        }
        .right-card {
            border-top: 5px solid blue;
        }
    }
}