@import "../../../../../globalStyles/typography/typography";

// Mobile
.nav-logo {
  display: flex;
  align-items: center;
  img {
    height: 30px;
    margin-bottom: 3px;
    width: 30px;
    margin-right: 1em;
  }
  span {
    font-size: 18px;
    font-family: $font-nunito;
    text-transform: uppercase;
    letter-spacing: 0.35em;
  }
}

// // Tablet
// @media screen and (min-width: 768px) {
//   .nav-logo {
//     img {
//       height: 45px;
//       margin-right: 1.5em;
//     }
//     span {
//       font-size: 24px;
//     }
//   }
// }

// Desktop
@media screen and (min-width: 1024px) {
  .nav-logo {
    img {
      height: 45px;
      width: 45px;
      margin-right: 1.5em;
    }
    span {
      font-size: 24px;
    }
  }
}

// Large Desktop
