*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#contact-form{
    width: 100%;
    height: 100%;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
    // margin-bottom: 50px;
    display: flex;
}
.section-1{
    width: 380px;
    height: 740px;
    padding: 40px;
    background:#3A86FF;
    color: white;
    border-radius: 10px;

    p.section-1-head{
        font-size: 34px;
        font-weight: 700;
        padding-bottom: 40px;
    }
    p.section-1-content{
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        font-family: "Nunito";
    }
}

.section-2{
    font-size: 27px;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;

    .section-2-head{
        font-size: 34px;
        font-weight: 700;
        color:#0837E5;
    }
    
    form{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        gap: 13px;
        position: relative;
    }

    .name-input[disabled='true'], .email-input[disabled='true'], .contact-input[disabled='true'], .message-input[disabled='true']{
        cursor: not-allowed;
    }
    .name-input, .email-input, .contact-input, .message-input{
        height: 50px;
        border: 2px solid #8C8CA2;
        border-radius: 10px;
        text-indent: 10px;
        font-size: 1rem;
        // margin-bottom: 20px;
    }
    .name-input, .contact-input, .message-input{
        margin-bottom: 20px;
    }
    .message-input{
        height: 200px;
        // display: block;
        // position: relative;
        // top: -20px;
    }
    .message-input::placeholder{
        position: relative;
        top: -60px;
    }
    .btn-submit{
        background:#3A86FF;
        border-radius: 10px;
        color: white;
        font-size: 18px;
        height: 50px;
        cursor: pointer;

        &[disabled='true']{
            cursor: not-allowed;
        }
    }
}

.email-invalid{
    color: red;
}
.email-valid{
    color: green;
}

.contact-form-container .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}
  
.successMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 200px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}
.successMessage--text {
    color: #131626;
    text-align: center;
    font-size: 30px;
    font-family: "Nunito";
    // line-height: 40px;
    font-weight: 700;
    margin-bottom: 10px;
}
.successMessage--subtext {
    color: #131626;
    text-align: center;
    font-size: 20px;
    font-family: "Nunito";
    line-height: 25px;
    font-weight: 700;
    padding-bottom: 20px;
}
.successMessage--btn {
    width: 100px;
    height: 40px;
    background-color: #3A86FF;
    font-size: 16px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    #contact-form{
        display: flex;
        flex-direction: column;
    }
    .section-1{
        width: 100%;
        height: 340px;

        p.section-1-head{
            font-size: 34px;
            padding-bottom: 30px;
            text-align: center;
        }
        p.section-1-content{
            font-size: 26px;
            text-align: center;
        }
    }
    .section-2{
        padding: 40px 0 40px 0;
    }
    .section-2 .section-2-head{
        text-align: center;
    }

    .successMessage {
        width: 400px;
        height: 260px;
    }

}

@media screen and (max-width:400px) {
    .section-1{
        p.section-1-head{
            font-size: 24px;
            padding-bottom: 20px;
        }
        p.section-1-content{
            font-size: 18px;
            line-height: 23px;
        }
    }
    .section-2{
        padding: 10px;
    }

    .successMessage {
        width: 80%;
        height: 250px;
    }
    .successMessage--text, .successMessage--subtext{
        font-size: 20px;
    }
}