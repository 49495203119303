.cart-page{
    font-family: 'Roboto';
    width: 100%;
}
title .h4 {
    color: #0837E5;
    font-size: 24px;
}
.content-group {
    display: flex;
    width: 100%;
    // height: 65vh;
    background: #FFFFFF;
    padding: 20px;
    margin-bottom: 30px;
    font-size: 1rem;
    .left-content {
        width: 100%;
        margin-bottom: 40px;
        .left-heading {
            border-bottom: 1px solid #BDBDBD;
            width: 100%;
            display: flex;
            position: relative;
            h4 {
                color: #0837E5;
            }
            .price-head{
                position: absolute;
                right: 55px;
            }   
        }
        .left-details {
            display: flex;
            // align-items: center;
            position: relative;
            width: 100%;
            .info-container {
                display: flex;
                // justify-content: space-between;
                width: 100%;
                margin: 10px 0 20px 0;
                cursor: pointer;
                // position: relative;
                .section-one{
                    width: 100%;
                    display: flex;
                    img{
                        border-radius: 8px;
                        margin-right: 12px;
                        height: 70px;
                    }
                    .course-desc{
                        // margin-right: 150px;
                        width: 50%;
                        font-weight: 700;
                        p{
                        font-size: 14px;
                        }
                    }
                }
                .section-two{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    right: 0px;
                    .price{
                        justify-self: flex-end;
                    }
                    .remove-btn{
                        // display: none;
                        color: rgb(231, 206, 206);
                        cursor: pointer;
                        background: none;
                        border: none;
                        margin-top: 10px;
                    }
                    .remove-btn:hover{
                        color: red;
                    }
                }
            }
        }
    }
    .right-content {
        border-left: 1px solid #BDBDBD;
        margin-left: 20px;
        padding-left: 20px;
        width: 60%;
        .summary {
            border-bottom: 1px solid #BDBDBD;
            width: 100%;
            h4 {
                color: #0837E5;
            }
        }
        .items-count {
            display: flex;
            margin: 20px 0px;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
        .promo-code{
            width: 70%;
            margin-bottom: 10px;
        }
        .ant-form-item{
            margin: 0;
        }
        .promo-error{ 
            color: red;
            padding-bottom: 15px;
            font-size: 14px;
        }
        .promo-applied{
            color: green;
            padding-bottom: 15px;
            font-size: 14px;
        }
        .apply-btn {
            padding: 4px 24px;
            background: #3A86FF;
            color: white;
            font-size: 13px;
            border-radius: 8px;
            width: 90px auto;
            margin-bottom: 15px;
        }
        .subtotal, .discount{
            // border-top: 1px solid #BDBDBD;
            display: flex;
            padding-top: 15px;
            margin-bottom: 1rem;
            width:100%;
            justify-content: space-between;
            align-items: center;
            p{
                color: #8C8CA1;
            }
        }
        .pay-item {
            border-top: 1px solid #BDBDBD;
            display: flex;
            padding-top: 15px;
            width:100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
        }
        .checkout {
            background: #3A86FF;
            color: white;
            border-radius: 8px;
            padding: 10px;
            gap: 10px;
            width: 100%;
            height: 48px;
            margin: 0 auto;
            // margin-top: 40px;
            margin-bottom: 30px;
            :active{
                background: #99999d;
            }
        }
        // .check-grey{

        // }
        .paystack-btn{
            cursor: pointer;
            // background-color: #5c50d7;
            background-color: #3A86FF;
            font-weight: bold;
            color: #ecf2ff;
            border: none;
            border-radius: 5px;
            width: 100%;
            height: 45px;
            // margin-top: 40px;
        }
    }
}

@media screen and (max-width: 769px) {
.content-group{
    display: flex;
    flex-direction: column;

    .left-content{
        border-bottom: 1px solid #BDBDBD;
        margin-bottom: 30px;
    }
    .right-content{
        border-left: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        // .summary{

        // }
    }
}
}

@media screen and (max-width: 480px) {
.content-group{
    font-size: .75rem;
    // display: flex;
    // flex-direction: column;

    .left-content{
        border-bottom: 1px solid #BDBDBD;
        margin-bottom: 30px;
        .left-details {
            .info-container {
                display: flex;
                // justify-content: space-between;
                width: 100%;
                margin: 10px 0 20px 0;
                cursor: pointer;
                // position: relative;
                .section-one{
                    display: flex;
                    img{
                        border-radius: 8px;
                        margin-right: 12px;
                        max-height: 40px;
                    }
                    .course-desc{
                        p {font-size: 10px;}
                    }
                }
                .section-two{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    right: 0px;
                    .price{
                        justify-self: flex-end;
                    }
                    .remove-btn{
                        // display: none;
                        color: rgb(231, 206, 206);
                    }
                }
            }
        }
    }
    .right-content{
        border-left: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        // .summary{

        // }
        .promo-code{
            width: 60%;
            font-size: 13px;
        }
    }
}
}