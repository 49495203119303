#termsConditionModal{
    // max-height: '300px';
    // max-width: '700px';
    // overflow-y: 'auto';
    // border-radius: '10px';
    // text-align: center;
    // display: block;
    position: relative;

    .main--heading{
        font-size: 18px;
        color: #3A86FF;
        text-transform: none;
    }
    .block--1{
        display: block;
        position: relative;
        left: 10px;
    }
    .heading--2{
        font-size: 18px;
        // font-weight: 300;
        text-transform: none;
    }
    .last--updated{
        font-size: 14px;
    }

    .content--head{
        font-weight: bold;
        font-size: 14px;
        text-align: center;
    }
    p{
        font-size: 12px;
        text-align: center;
    }
}