// Colors variables
$primaryColor: #0837e5;
$secondaryColor: #3a86ff;
$tertiaryColor: #ffcc00;
$contentBgc: #f6f7fb;
$darkColor: #03023b;
$successColor: #46d5b3;
$errorColor: #eb5757;
$lightGrey: #f8f8f8;
$offWhite: #fdfdfd;
$pureWhite: #f2f2f2;
$grey1: #333;
$grey2: #4f4f4f;
$grey3: #828282;
$grey4: #bdbdbd;
$grey5: #e0e0e0;
$grey6: #f2f2f2;

// Font variables
$largeHeadings: 2em;
$mediumHeadings: 1.5em;
$smallHeadings: 1.2em;
$body: 1em;
$phoneFontSize: 12px;

// Break-point variables
$smallPhone: 320px;
$largePhone: 768px;
$tablet: 960px;
$desktop: 1200px;
$largeDesktop: 1400px;

// border radius
$large-radius: 20px;
$semilarge-radius: 14px;
$normal-radius: 8px;
$medium-radius: 6px;
$small-radius: 4px;
$circle-radius: 50%;

//Fonts
$font-robotto: "Roboto", sans-serif;
$font-nunito: "Nunito", sans-serif;
$font-sans: "sans-serif";