.instructorApplications {
    .heading {
        font-size: 40px;
        color: #3a86ff;
        padding-bottom: 20px;
        font-weight: bold;
    }
    .overview {
        font-weight: bold;
        margin-bottom: 20px;
    }
    .search-review-container{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
    .search--bar {
        width: 75%;
        height: 2.5rem;
        // margin: 0 auto;
        border-radius: 14px;
        margin-right: 1rem;
        padding-left: 1rem;
        outline: solid 1px #3a86ff;
        &:focus {
            outline: none !important;
            border: solid 2px #3a86ff;
        }
        &::placeholder {
            font-style: italic;
        }
    }
    .review {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: 550px) {
        .review {
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
        }
    }
    .review-box {
        width: 200px;
        text-align: center;
        background: white;
        border: solid 6px #e3ecfb;
        border-radius: 10px;
        padding: 10px;

        h6, p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            /* or 165% */
            letter-spacing: 0.06em;
            color: #3a86ff;
        }
        p {
            color: #000000;
        }
    }
    .dropdown {
        background-color: #3a86ff;
        border: none;
        color: white;
        padding: 0;
        margin-left: 0.2rem;
    }
    .ant-button {
        color: white;
    }
    .tableContainer {
        background-color: whitesmoke;
        width: 90%;
        padding: 1rem;
        border-radius: 10px;
        border: solid 5px #e3ecfb;
    }

    .historyTable {
        width: 100%;
        padding: 1rem;
        thead {
            background-color: #3a86ff;
            th {
                color: #fff;
                font-size: 20px;
                padding: 10px;
                text-align: center;
                &:hover {
                    background-color: #3171d8;
                }
            }
            .left {
                border-radius: 10px 0px 0px 10px;
            }
            .right {
                border-radius: 0px 10px 10px 0px;
            }
        }
        tbody {
            tr {
                td {
                    padding: 5px;
                    div {
                        justify-content: center;
                    }
                }
                .left {
                    border-radius: 10px 0px 0px 10px;
                }
                .right {
                    border-radius: 0px 10px 10px 0px;
                }
                &:nth-child(even) {
                    background-color: whitesmoke //RGBA(58, 134, 255, 0.15);
                }
            }
            .instructor {
                display: flex;
                font-size: 16px;
                font-weight: 700;
                align-items: center;
                p {
                    padding-left: 5px;
                }
            }
            .approved {
                background-color: #46d5b44c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .processing {
                background-color: #3a86ff4c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .rejected {
                background-color: #eb57574c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .Suspended {
                background-color: #ffcc004c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .date {
                font-size: 16px;
                font-weight: 700;
                display: flex;
                justify-content: center;
            }
        }
    }
}
