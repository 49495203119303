@import "../../../../globalStyles/helpers/variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
// COLOR VARIABLES:
// $lightGrey $ $grey1 $successColor

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
#general_pricingg {
  background: $lightGrey;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;

  .naira, .pounds {
    max-width: 50px;
    max-height: 48px;
    margin-top: -4px;
    margin-right: 2px;
    filter: invert(100%);
  }

  .wave-bg {
    background: url(./assets/wave-1200.svg) no-repeat;
    background-position: bottom;

    .history_link{
      // min-height: 3em;
      position: relative;
      margin-bottom: -2em;
      display: flex;

      .active_sub{
        width: 50%;
        min-width: 450px;
        height: auto;
        margin: 1em auto;
        border-radius: 8px;
        box-shadow: 0px 4px 10px 0px #00000040;     
      }
      
      #paymtHisBtn{
        position: absolute;
        right: 10px;
        top: -1em;
        text-decoration: underline;
        // background: none;
        font-size: 1.2em;
        font-weight: 700;
        color: $secondaryColor;
        cursor: pointer;
        z-index: 10;
      }
    }
  }
  .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    background-color: $lightGrey;

      h2{
        text-align: center;
        color: #3A86FF;
      }
      p{
        text-align: center;
        font-size: 1.2rem;
      }
  }

  // PRICE CARD CONTAINER STYLES
  .pricing-cards{
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: auto;
    display: flex;
    margin: 0 auto;
    margin-top: 3em;
    gap: 1em;
    justify-content: center;
    align-items: center;
    border-radius: 2em;
    flex-wrap: wrap;
    // background-color: red;
    // GENERAL CARD STYLE
    .cards{
      border-radius: 15px;
      border: 2px black;
      box-shadow: 0 10px 10px grey;

      .active{
        height: 100%;
        position: relative;
        border-radius: 16px;

        &::before{
          content: url('../../../../assets/icons/waterMark.svg');
          width: 40px;
          height: 40px;
          display: flex;
          padding-top: .4em;
          justify-content: center;
          align-items: center;
          object-fit: contain;
          position: absolute;
          background-color:$lightGrey;
          opacity: 100;
          border-radius: 50%;
          right: -.3em;
          top: -.3em;
          z-index: 1000;
        }

        &::after{
          content: url('../../../../assets/icons/waterMark.svg');
          width: 100%;
          height: 100%;
          background-color: $grey4;
          opacity: 50%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 16px;
          cursor:not-allowed;
        }
      }

      .feature{
        margin: .8em;
        text-align: left;
        list-style-type: none;
        padding-left: 0;
        line-height: 20px;
  
        li{
          padding: 0;
          margin-bottom: .5em;
        }
      }
    }

    // GENERAL STYLES FOR TOP OF PRICING CARDS
    .top{
      padding: 12px;
      font-weight: 700;
      color: white;
    }
    // GENERAL STYLES FOR LISTED FEATURES
    
    // GENERAL BUTTON HOVER
    .btn-subscription:hover{
      box-shadow: 0 0 10px #3A86FF;
    }
    // FIRST CARD
    .first-card{
      max-width: 305px;
      height: 600px;
      position: relative;
      .top{
        background: $grey3;     
        height: 100px;
        border-radius: 15px 15px 0 0;
        padding-top: 30px;
        line-height: 29px;
        font-weight: 700;
        .frequency-long{
          font-size: 24px;
        }
        #currency-price{
          font-size: 24px;
          color: white;
        }
      }
      .first-feature-item{
        display: flex;
        align-items: flex-start;

        div{
          margin-right: 4px;
        }
        
        p{
          font-size: 14px;
        }
      }
      .btn-first{
        width: 176px; 
        border: 1px solid $grey3;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 700;
        color: $grey3;
        position: absolute;
        bottom: 30px;
        left: calc((100% - 176px)/2);
        text-align: center;
        padding-block: .3em;
      }
      
    }
    // SECOND CARD
    .second-card{
      max-width: 320px;
      height: 650px;
      position: relative;

      &.simple{
        height: 650px;
        max-width: 305px;

        .top{
          background: #46D5B3;
          height: 100px;
          border-radius: 15px 15px 0 0;
          #statement{
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.355em;
            color: #E0E0E0;
          }
          .frequency-long{
            font-size: 24px;
            padding-top: 10px;
            line-height: 29px;
          }
          #currency-price{
            font-size: 24px;
            color: white;
          }
          #span{
            font-size: 20px;
            color: #E0E0E0;
          }
        }
      }
      .top{
        background: #46D5B3;
        height: 110px;
        border-radius: 15px 15px 0 0;
        #statement{
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.355em;
          color: #E0E0E0;
        }
        .frequency-long{
          font-size: 40px;
          padding-top: 10px;
          line-height: 30px;
        }
        #currency-price{
          font-size: 30px;
          color: white;
        }
        #span{
          font-size: 20px;
          color: #E0E0E0;
        }
      }
      .second-feature-item{
        display: flex;
        align-items: flex-start;

        div{
          margin-right: 4px;
        }
        
        p{
          font-size: 14px;
        }
      }
      .btn-second{
        width: 176px; 
        border: 1px solid #46D5B3;
        background-color: #46D5B3;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 700;
        color: white;
        position: absolute;
        bottom: 30px;
        left: calc((100% - 176px)/2);
        text-align: center;
        padding-block: .3em;
      }
    }
    // THIRD CARD
    .last-card{
      max-width: 305px;
      height: 600px;
      position: relative;

      &.simple{
        height: 650px;
        max-width: 305px;

        .top{
          background: #3A86FF;
          height: 100px;
          border-radius: 15px 15px 0 0;
          #statement{
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.355em;
            color: #E0E0E0;
          }
          .frequency-long{
            font-size: 24px;
            padding-top: 10px;
            line-height: 29px;
          }
          #currency-price{
            font-size: 24px;
            color: white;
          }
          #span{
            font-size: 20px;
            color: #E0E0E0;
          }
        }
      }

      .top{
        background: #3A86FF;
        height: 100px;
        border-radius: 15px 15px 0 0;
        #statement{
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.355em;
          color: #E0E0E0;
        }
        .frequency-long{
          font-size: 24px;
          padding-top: 10px;
          line-height: 29px;
        }
        #currency-price{
          font-size: 24px;
          color: white;
        }
        #span{
          font-size: 20px;
          color: #E0E0E0;
        }
      }
      .third-feature-item{
        display: flex;
        align-items: flex-start;

        div{
          margin-right: 4px;
        }
        
        p{
          font-size: 14px;
        }
      }
      .btn-last{
        width: 176px; 
        border: 1px solid #3A86FF;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 700;
        color: #3A86FF;
        position: absolute;
        bottom: 30px;
        left: calc((100% - 176px)/2);
        text-align: center;
        padding-block: .3em;
      }
    }    
  }
}

// MEDIA SCREEN SIZES
@media screen and (max-width: 1259px) {
  #pricingg .pricing-cards{
    flex-wrap: wrap;
    justify-content: center;

    .cards{
      height: 600px;
      width: 300px;

      .top{
        height: 100px;
        border-radius: 15px 15px 0 0;
        #statement{
          font-size: 12px;
        }
        .frequency-long{
          font-size: 24px;
          padding-top: 10px;
          line-height: 29px;
        }
        #currency-price{
          font-size: 24px;
        }
        #span{
          font-size: 20px;
        }
      }
    }

    // .second-card{
    //   height: 552.5px;
    //   width: 305px;

    //   .top{
    //     height: 120px;
    //     .frequency-long{
    //       font-size: 24px;
    //       padding-top: 15px;
    //       line-height: 29px;
    //     }
    //     #currency-price{
    //       font-size: 24px;
    //     }
    //     #span{
    //       font-size: 20px;
    //       color: #E0E0E0;
    //     }        
    //   }
    //   .btn-second{
    //     width: 176px;
    //     position: absolute;
    //     bottom: 30px;
    //     right: 62px;
    //   }
    // } 
  }

}

@media screen and (max-width: 864px) {
  #pricingg{

    .wave-bg{
      .history_link{
        .active_sub{
          // margin-left: 0;
          max-width: 60%;
        }

      }
    }
  }
}

@media screen and (max-width: 781px) {
  #pricingg {
    .pricing-cards{
      justify-content: center;
  
      .cards{
        min-width: 70%;
      }
    }

    .wave-bg{
      background: url(./assets/wave-mobile.svg) no-repeat;
      background-position: bottom;
      color: $grey2;

      .history_link{
        .active_sub{
          margin-left: 0;
          max-width: 80%;
  
          h3{
            font-size: .9em;
          }
  
          .card_cont{
            font-size: .7em;
          }
  
          .cancel_btn{
            font-size: 0.9em;
          }
        }
  
        #paymtHisBtn{
          font-size: 0.8em;
        }
      }
    }
  }
}


@media only screen and (max-width: 450px) {
  .container{
    margin-left: 0;
    margin-right: 0;
  }

  #pricingg {
    .pricing-cards{
      height: auto;
      .cards{
        min-width: 100%;
        height: auto;
        padding-bottom: 75px;
  
        .btn-last, .btn-second, .btn-first{
          width: 90%;
          left: calc((100% - 90%)/2);
        }
      }
      .first-card .top{
        padding-top: 0;
      }
    }

    .wave-bg {
      background: none;
  
      .history_link{
        .active_sub{
          max-width: 100%;
        }
      }
    }
  }
  .title{
    font-size: 16px;
  }
  .subtext{
    font-size: 12px;
  }
}