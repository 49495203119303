.UserDashboard__Notfound {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .container {
        text-align: center;
        max-width: 600px;
        width: 100%;
        h1 {
            margin-bottom: 3rem;
        }
        h3 {
            margin-top: 3rem;
        }
        img {
            width: 100%;
        }
    }
}
