@import 'src/globalStyles/helpers/variables';
@import 'swiper/swiper.min.css';
@import 'swiper/components/pagination/pagination.scss';
@import 'swiper/components/navigation/navigation.scss';
@import 'swiper/swiper.scss';

.container-hero {
    display: flex;
    max-width: 1450px;
    margin: 0 auto;
    margin-bottom: 1em;
}
#premiumPage.content {
    display: flex;
    width: 100%;
    height: 60vh;
    position: relative;
    text-align: center;

    .header-img-con {
        width: 100%;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content-header {
        width: 100%;
        padding-inline: 1em;
        font-size: 2em;
        font-weight: 600;
        position: absolute;
        max-height: 15vh;
        top: 1em;
        color: white;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-about {
        width: 90%;
        margin: 0 5%;
        color: white;
        height: 15vh;
        font-size: 1em;
        font-weight: 500;
        position: absolute;
        top: calc(2.5em + 15vh);
        text-align: center;
    }

    .watch-container {
        position: absolute;
        top: calc(4em + 30vh);
        width: 100%;

        .watch {
            color: white;
            background-color: $secondaryColor;
            cursor: pointer;
            height: 54px;
            width: 144px;
            border-radius: 9px;
            font-size: 16px;
            font-weight: 600;
            transition: 0.2s ease-in-out;
        }
    }
}

.watch:hover {
    background-color: rgb(0, 60, 224);
}

.swiper-wrapper {
    width: 100%;
}

.chev-btn-right {
    background-color: white;
    border-radius: 25px;
    padding: 5px 5px 5px 8px;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
}

.chev-btn-left {
    background-color: white;
    border-radius: 25px;
    padding: 5px 8px 5px 5px;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
}

.prev-featured {
    color: #828282;
    font-size: 1.25rem;
    background: transparent;
    cursor: pointer;
    padding-right: 20px;
}

.next-featured {
    fill: #828282;
    font-size: 1.25rem;
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    padding-left: 20px;
}

.swiper-pagination {
    position: absolute;
    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        background: $grey5;
        opacity: 100%;
        z-index: 5;
    }
    .swiper-pagination-bullet-active {
        background: $secondaryColor;
    }
}

.swiper-slide {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
}

@media (max-width: 1800px) {
    .header-img-con {
        width: 1250px;
    }

    .container-hero {
        max-width: 1250px;
    }

    .content-header {
        top: 150px;
    }

    .text-about {
        top: 245px;
    }

    .watch-container {
        top: 325px;
    }
}

@media (max-width: 1600px) {
    .header-img-con {
        width: 1050px;
    }

    .container-hero {
        max-width: 1050px;
    }

    .content-header {
        top: 120px;
        font-size: 60px;
    }

    .text-about {
        top: 215px;
    }

    .watch-container {
        top: 295px;
    }

    .watch {
        height: 48px;
        width: 137px;
        font-size: 16px;
    }

    .swiper-pagination {
        position: absolute;
        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
        }
    }
}

@media (max-width: 1400px) {
    .header-img-con {
        width: 850px;
    }

    .container-hero {
        max-width: 850px;
    }

    .content-header {
        top: 95px;
        font-size: 48px;
    }

    .text-about {
        top: 165px;
        font-size: 14px;
    }

    .watch-container {
        top: 230px;
    }

    .watch {
        height: 44px;
        width: 125px;
        font-size: 14px;
        font-weight: 500;
    }

    .swiper-pagination {
        position: absolute;
        .swiper-pagination-bullet {
            width: 13px;
            height: 13px;
        }
    }
}

@media (max-width: 1200px) {
    .header-img-con {
        width: 800px;
    }

    .container-hero {
        max-width: 800px;
    }

    .content-header {
        top: 90px;
        font-size: 48px;
    }

    .text-about {
        top: 160px;
        font-size: 14px;
    }

    .watch-container {
        top: 215px;
    }

    .watch {
        height: 40px;
        width: 120px;
        font-size: 14px;
        font-weight: 500;
    }
}

@media (max-width: 1150px) {
    .header-img-con {
        width: 700px;
    }

    .container-hero {
        max-width: 700px;
    }

    .content-header {
        top: 80px;
        font-size: 48px;
    }

    .text-about {
        top: 140px;
        font-size: 14px;
    }

    .watch-container {
        top: 195px;
    }

    .watch {
        height: 36px;
        width: 115px;
        font-size: 14px;
        font-weight: 500;
    }
}

@media (max-width: 1045px) {
    .header-img-con {
        width: 650px;
    }

    .container-hero {
        max-width: 650px;
    }

    .content-header {
        top: 75px;
        font-size: 38px;
    }

    .text-about {
        top: 130px;
        font-size: 14px;
    }

    .watch-container {
        top: 175px;
    }

    .watch {
        height: 35px;
        width: 110px;
        font-size: 12px;
        font-weight: 400;
    }

    .swiper-pagination {
        position: absolute;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
        }
    }
    .prev-featured {
        padding-right: 10px;
    }

    .next-featured {
        padding-left: 10px;
    }
}

@media (max-width: 992px) {
    .header-img-con {
        width: 1050px;
    }

    .container-hero {
        max-width: 1050px;
    }

    .content-header {
        top: 115px;
        font-size: 40px;
        text-align: center;
        // z-index: 10;
    }

    .text-about {
        top: 200px;
    }

    .watch-container {
        top: 275px;
    }

    .watch {
        height: 48px;
        width: 137px;
        font-size: 16px;
        font-weight: 500;
    }

    .swiper-pagination {
        position: absolute;
        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
        }
    }
}

@media (max-width: 550px) {
    .prev-featured {
        display: none;
    }

    .next-featured {
        display: none;
    }
}

@media (max-width: 425px) {
    .header-img-con {
        width: 1050px;
    }

    .container-hero {
        max-width: 1050px;
    }

    .content-header {
        top: 150px;
        font-size: 20px;
    }

    .text-about {
        top: 200px;
        font-size: 12px;
    }

    .watch-container {
        top: 275px;
    }

    .watch {
        height: 38px;
        width: 110px;
        font-size: 16px;
        font-weight: 500;
    }

    .swiper-pagination {
        position: absolute;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
        }
    }

    .prev-featured {
        display: none;
    }

    .next-featured {
        display: none;
    }
}
