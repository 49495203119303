.vid-container {
    width: 100%;
    .vid {
        // margin: 10px auto;
        width: 100%;
    }
    .not-complete {
        color: white;
        border: 1px solid red;
        background-color: red;
        opacity: 0.4;
    }
    .complete {
        color: blue;
    }
}

// <div className="courseHeading">
// <div className="courseHeadingInfo">
//     <h2>{course?.courseModel?.courseInfor?.name}</h2>
//     <div className="ratingPreview">
//         <p className="ratingCount">(14)</p>
//         <img src={stars} alt="" />
//         <p className="ratingScore">4.2</p>
//     </div>
//     <p>
//         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur gravida ligula quis ipsum lacinia volutpat id convallis mauris.
//     </p>
//     <button className="btn btn-enroll">Enroll</button>
// </div>
// <div className="courseHeadingImage">
//     <img src={headerImage} alt="" />
// </div>
// </div>