@import '../../../../globalStyles/helpers/variables';

.blankPage{
    width: 100%;
    height: auto;
    text-align: center;

    h2{
        font-size: 1.3em;
        width: 48%;
        margin: 1em auto;
        text-transform: none;
    }

    p{
        font-size: 1.3em;
        color: $secondaryColor;
    }

    button{
        margin-top: 1em;
        width: 150px;
        padding: .7em .3em;
        border-radius: 8px;
        color: white;
        background-color: $secondaryColor;
        cursor: pointer;
    }
}