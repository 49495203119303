.add-cart-btn {
    width: 40%;
    padding: 6px;
    color: black;
    font-size: 14px;
    background-color: #FFCC00;
    cursor: pointer;
    border-radius: 5px;
    position:sticky;
    bottom: 1em;
    left: 30%;
}