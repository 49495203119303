.studentReviews {
        background: #ffffff;
        padding: 2.5rem 1.5rem;
        .ratingOverview {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: .5rem auto;
            gap: 3rem;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                    font-size: 30px;
                    font-weight: 600;
                    color: #0837e5;
                }
                img {
                    width: 100px;
                }
            }
            img {
                width: 250px;
            }
        }
        .ratingSort {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            b {
                font-size: 14px;
            }
            select {
                padding: .5rem .75rem;
                color: #828282;
                font-weight: 500;
                border: 2px solid #828282;
                border-radius: .5rem;
            }
        }
        .reviewContainer {
            padding-left: 0;
            li {
                text-decoration: none;
                list-style: none;
                background: #f2f2f2;
                display: flex;
                border-radius: .5rem;
                margin: 1rem 0;
                .profileImageContainer {
                    width: 150px;
                    padding: 1.5rem;
                    padding-right: .5rem;
                    img {
                        width: 100%;
                    }
                }
                .reviewerContent {
                    padding: 1.5rem;
                    padding-left: .5rem;
                    .reviewerName {
                        font-weight: 600;
                    }
                }
                .reviewerReview {
                    margin: 1.5rem 0;
                }
                .reviewContentButtons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 1rem;
                    .reviewRating {
                        display: flex;
                        align-items: center;
                        gap: 2rem;
                        div {
                            display: flex;
                            align-items: center;
                            gap: .5rem;
                        }
                        p {
                            font-size: 14px;
                            font-weight: 500;
                            color: #4f4f4f;
                        }
                    }
                    .reviewReport p {
                        font-size: 14px;
                        color: #4f4f4f;
                        font-weight: 500;
                    }
                }
            }
        }
        &> * {
            max-width: 800px;
            margin: auto;
        }
    }