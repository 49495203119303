$primary: #3a86ff;

#overview {
    &>div {
        border-bottom: 1px solid #edeef0;
        padding: 2rem 0;
    }

    .overviewDetails p {
        padding: 0 1rem;
    }

    .overviewStats {
        display: flex;
        justify-content: space-evenly;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                font-size: 14px;
            }
        }
    }

    .overviewObjectives {
        ul {
            display: inline-block;
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
            gap: 3rem;
        }
        ul.custom-bullet {
            list-style: none;
        }
        
        ul.custom-bullet>li {
            clear: left;
        }
        ul.custom-bullet>li:before {
            content: "";
            height: 1em;
            width: 1em;
            display: block;
            float: left;
            margin-left: -1.5em;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
        ul.heart>li:before {
            background-image: url("../assets/checkSquare.svg");
            background-size: 90%;
            background-position: center;
            transition: background-size 0.3s;
            -webkit-transition: background-size 0.3s;
        }
        ul.heart>li:hover:before {
            background-size: 100%;
        }
    }

    .OverviewPrerequisites ul {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        li::marker {
            color: $primary;
            font-size: 1.5rem;
        }
    }

    .overviewTeacherInformation {
        .teacherDetailsContainer {
            width: 100%;
            display: flex;
            align-items: center;
            .teacherProfileImg {
                width: 60px;
                transform: translateY(-20px);
            }
            .teacherDetails {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-top: 1rem;
            
                .teacherName {
                    &>* {
                        margin-right: 1rem;
                    }
                }
            
                .teacherPosition {
                    margin: .5rem 0;
                    color: #4f4f4f;
                }
            
                .teacherStats {
                    color: #4f4f4f;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 2.5rem;
                    padding-right: 1rem;
            
                    div {
                        display: flex;
                        align-items: center;
                        gap: .25rem;
            
                        div {
                            p {
                                font-size: 14px;
                                color: #4f4f4f;
                            }
                        }
            
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .overviewStats {
        flex-direction: column;
        height: 400px;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-size: 14px;
            }
        }
    }

    .teacherProfileImg {
        width: 120px !important;
        transform: translateY(-40px) !important;
        display: flex;
        justify-content: center;
        img {
            width: 80px;
        }
    }

    .teacherStats {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;
        padding-right: 0;

        div {
            margin: .5rem 0;
        }
    }
}