.instructorApplications {
    .review-dropdown {
        margin-left: 3rem;
    }
    .comment-textarea {
        width: 79%;
        margin-left: 20px;
    }
    .subheading {
        font-weight: 700;
        font-size: 24px;
        color: #3a86ff;
        padding: 20px 20px 20px 0;
    }
    .instructorOverview {
        display: flex;
        flex-wrap: wrap;
        .instructor {
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            border-width: 5px;
            border-style: solid;
            border-color: #3a86ff0f;
            min-width: 300px;
            width: 45%;
            margin-right: 2rem;
            .instructorName {
                background-color: #3a86ff;
                color: #fff;
                padding: 10px;
                border-radius: 5px;
                font-weight: 700;
                display: flex;
                align-items: center;
                p {
                    padding-left: 5px;
                }
            }
            .heading {
                font-weight: 700;
                padding: 10px;
            }
            .approved {
                background-color: #46d5b44c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .processing {
                background-color: #3a86ff4c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .rejected {
                background-color: #eb57574c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .Suspended {
                background-color: #ffcc004c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
        }

        .permissions {
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            border-width: 5px;
            border-style: solid;
            border-color: #3a86ff0f;
            min-width: 300px;
            width: 45%;
            h5 {
                background-color: #3a86ff;
                color: #fff;
                padding: 10px;
                border-radius: 5px;
                font-weight: 700;
            }
            .permissionContainer {
                display: flex;
                flex-wrap: wrap;
                .permission {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: 10px;
                    p {
                        padding-left: 5px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .questionaire {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        .questions {
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            border-width: 5px;
            border-style: solid;
            border-color: #3a86ff0f;
            width: 80%;
            min-width: 280px;
            .question {
                font-weight: 700;
                padding: 10px;
            }
            .awnser {
                padding-left: 30px;
            }
        }
    }
    .files {
        a {
            border: 2px dashed #bdbdbd;
            padding: 10px;
            width: 80%;
            min-width: 280px;
            background-color: #fff;
        }
    }
    .cv {
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
    .pStatement {
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
    .qualifications {
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
    .submitButtons {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .deny {
            background-color: #eb5757;
            width: 240px;
            border-color: #eb5757;
        }
        .approve {
            width: 240px;
        }
        .dropDown {
            background-color: #fff;
            color: #3a86ff;
            border-color: #3a86ff;
            width: 240px;
        }
    }
}
