@import "../../../../globalStyles/helpers/variables";

.hide {
    display: none;
}

.currency {
    //width: 115px;
    //height: 18px;
    max-width: 20px;
    max-height: 18px;
    margin-top: -4px;
    margin-right: 4px;
}

.email-text {
    padding: 0px 20px 20px 20px;
    text-align: center;
}
 
.email-header {
    display: flex;
    justify-content: center;
    font-size: 35px;
}

.email-button-con {
    display: flex;
    justify-content: center;

    .email-button {
        width: 200px;
        padding: 12px 30px;
        border-radius: 10px;
        background-color: $secondaryColor;
        color: white;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: #206eec;
        }
    }
}

#payment {
    margin: 7rem auto 3em;
    width: 90%;
    background-color: $grey5;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding: 1em;
    padding-bottom: 1.4em;
    border-radius: 10px;
    box-shadow: 0px 2px .5em 3px rgba(0, 0, 0, 0.25);
    &.max_width{
        width: 100%;
    }
    .payment-container {
        h3{
            color: $secondaryColor;
        }
    }
    .billing-container {
        border: 2px solid #6ba4ff !important;
        border-left: 0px !important;
        border-right: 0px !important;
        padding: 1.5rem 0;
        width: 100%;
        .billing-summary-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FFFFFF;
            // border: 1px solid #0837E5;
            box-shadow: 0px 2px .5em 3px rgba(0, 0, 0, 0.25);
            padding: .5rem 1.6em;
            border-radius: 10px;
            width: 100%;
            img {
                width: 30px;
                height: 30px;
            }
            .box-plan {
                color: $secondaryColor;
            }
            .box-amount {
                font-weight: 400;
                font-size: 40px;
                text-align: center;
                letter-spacing: 0.02em;
            }
            .box-desc {
                letter-spacing: 0.03em;
                color: #4E93FF;
            }
        }
        .payment-details {
            padding: 2rem .5rem 1rem;
            color: $secondaryColor;
        }
        .card-details {
            .form-group {
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                input {
                    width: 45%;
                    padding: 1rem;
                    background: #FFFFFF;
                    // border: 1px solid #0837E5;
                    box-shadow: 0px 2px .5em 3px rgba(0, 0, 0, 0.25);
                    margin: .5rem;
                }
                .card-number {
                    width: 100%;
                }
            }
        }
        .user-details {
            margin: 2rem 0;
            width: 100%;
            button {
                display: block;
                width: 40%;
                margin: 0 auto;
                padding-block: .3rem;
                font-size: 1.2rem;
                font-weight: 500;
                color: #fff;
                border-radius: 10px;
                background-color: $secondaryColor;
                cursor: pointer;
            }
            .user-box {
                display: flex;
                align-items: center;
                background: #FFFFFF;
                // border: 1px solid #0837E5;
                box-shadow: 0px 2px .5em 3px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                padding: .5rem 1em;
                cursor: pointer;
                margin: 1.3em 0;
                div {
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
                div:nth-child(2) {
                    flex: 1;
                }
            }
            .account-details {
                margin: auto;
                .form-group {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    Input {
                            width: 100%;
                            padding: 1rem;
                            background: #FFFFFF;
                            border: 1px solid #0837E5;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            margin: .5rem;
                        }
                }
                .account-details-submit {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 4rem;
                    & > div {
                        margin: 2rem 0;
                    }
                    button {
                        width: 100%;
                        padding: .5rem;
                        font-size: 1.5rem;
                        font-weight: 500;
                        color: #fff;
                        background-color: $secondaryColor;
                    }
                }
                .account-details-name {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    input {
                        width: 45%;
                        margin: 1rem 0;
                    }
                }
            }
        }
    }
    
    .eye-icon {
        width: 20px !important;
        height: 20px !important;
        background: transparent !important;
        svg {
            position: absolute;
            transform: translate(-50%, -50%);
        }
    }
    
    @media screen and (max-width: 500px) {
        .box-plan {
            display: none;
        }
    }
}

.ant-modal-body{
    #payment{
        margin: 0;
    }
}