#account-info {
    width: 100%;
    min-height: 60vh;
    background: #ffffff;
    border: 1px solid #828282;
    border-radius: 8px;

    .contents {
        width: 100%;
        margin: auto;
        padding: 30px;

        h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #03023b;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            line-height: 26px;
            /* or 165% */
            // letter-spacing: 0.06em;

            color: #000000;
        }

        h5 {
            color: #40a9ff;
            font-size: 16px;
        }
    }
}
