#profile {
    width: 100%;
    min-height: 60vh;
    background: #ffffff;
    border: 1px solid #828282;
    border-radius: 8px;
    padding: 30px;
    .profile-content {
        width: 100%;
        margin: 10px auto;

        h4 {
            font-weight: bold;
            font-size: 24px;
            color: #000000;
            margin-bottom: 30px;
        }
    }
    label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 140%;
        color: #bdbdbd;
    }
    input,
    TextArea {
        border-radius: 5px;
    }
    .left-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        .profile_uploadImage {
            height: 80px;
            width: 80px;
            cursor: pointer;
            &:focus {
                outline: none;
            }
            img {
                background-color: #c4c4c4;
                height: 80px;
                width: 80px;
                border: 1px scroll;
                border-radius: 50%;
                object-fit: 'cover';
            }
        }
        .profile_loadingImage {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            background-color: #808080;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                margin: 0px;
            }
        }
        span {
            display: flex;
            justify-content: center;
        }
    }
    .profile-image {
        display: flex;
        justify-content: center;
    }
    .profile-image img {
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        background-color: #828282;
        width: 100%;
        max-width: 12rem;
    }

    .btn-upload {
        background-color: #3a86ff;
        color: white;
        border-radius: 8px;
        span {
            color: white;
            font-size: 1em;
        }
    }
    .btn-remove {
        font-size: 0.95em;
        background-color: white;
        border: none;
        color: #3a86ff;
    }
}
