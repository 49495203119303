.onboard--success--container{
    width: 700px;
    margin: 0px auto;
    padding: 50px;
    border-radius: 70px 0px 70px 0px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .celebration--logo{
        width: 35%;
        margin-bottom: 30px;
    }
    .header--text{
        color: #3A86FF;
        font-size: 2rem;
    }
    .text--1{
        font-size: 1.55rem;
        color: #3A86FF;
        text-align: center;
        font-weight: 600;
        padding-bottom: 30px;
    }
    .text--2{
        font-size: 1.4rem;
        font-weight: 600;
        text-align: center;
        padding-bottom: 30px;
    }
    .btn--create--course{
        padding: 8px 10px;
        background: #3A86FF;
        border-radius: 8px;
        color: white;
        font-weight: 600;
    }
}

@media screen and (max-width: 500px) {
    .onboard--success--container{
        margin: 30px;
        padding: 20px;

        .celebration--logo{
            width: 50%;
        }
        .header--text{
            font-size: medium;
        }
        .text--1{
            font-size: small;
        }
        .text--2{
            font-size: small;
        }
        .btn--create--course{
            padding: 5px 7px;
            border-radius: 5px;
            font-size: 12px;
        }
    }
}