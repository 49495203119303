#notification-settings {
    background: #ffffff;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 30px;

    .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        color: #000000;
    }

    .box {
        background: #fdfdfd;
        border: 1px solid #828282;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        margin: 40px 0px;
        width: 100%;
    }

    .unsubscribe {
        /* unsubscribve checkbox section*/

        padding-right: 125px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.06em;
        padding-left: 20px;
        color: #333333;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .field {
        /* Blue box containing image / title and description */

        background: #ccdffe;
        border: 1px solid #0837e5;
        border-radius: 8px 8px 0px 0px;
        color: white;
        display: grid;
        height: 100%;
        grid-template-columns: 80px 1fr;
        width: 100%;
        padding: 10px 20px;
        align-items: center;
    }

    .image {
        width: 40px;
        height: 40px;
    }

    .header {
        font-weight: 500;
        font-size: 22px;
        color: #03023b;
    }

    .description {
        font-size: 14px;
        color: #03023b;
    }

    .list {
        padding: 10px 20px 30px 30px;
    }

    .hide {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        top: -10px;
        left: 45px;
        text-decoration-line: underline;
        color: #828282;
    }

    .hidden-list {
        background: #f8f8f8;
        border: 1px solid #828282;
        border-radius: 8px;
        padding: 16px;
    }

    .courseInstructors {
        /* instructors displayed on hidden list*/
        position: relative;
        bottom: 30px;
        left: 50px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #828282;
    }

    form {
        margin-top: 20px;
    }

    @media only screen and (max-width: 770px) {
        padding: 3%;
        .hidden-list {
            padding-left: 30px;
            width: 100%;
        }
        .list {
            padding: 3%;
        }
        .title {
            /* Title for page - Notification*/

            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
            letter-spacing: 0.02em;
            color: #000000;
            text-align: center;
        }
    }
}
