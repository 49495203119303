#studentReviews {
    width: 100%;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    grid-template-rows: 2fr;
    margin: 20px 0px;

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #03023b;
        text-align: center;
        margin-bottom: 30px;

        grid-column: 2;
    }

    #section1 {
        grid-column: 2;
        margin-bottom: 10px;

        display: grid;
        grid-template-columns: 1fr 2fr;

        #averageRating {
            grid-column: 1;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            justify-content: center;

            .rating-value {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 34px;
                line-height: 40px;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                letter-spacing: 0.02em;
                color: #0837e5;
            }

            .stars {
                display: flex;
                justify-content: center;
            }
        }

        #ratingBars {
            .row {
                display: grid;
                grid-template-rows: repeat(5, 20%);
                grid-template-columns: 20% 80%;

                .side {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #000000;
                }
                .middle {
                    display: flex;
                    align-items: center;
                }
            }

            .bar-container {
                background-color: #e5e5e5;
                width: 100%;
                text-align: center;
                color: white;
                border-radius: 5px;

                .bar {
                    height: 8px;
                    background-color: #3a86ff;
                    border-radius: 5px;
                }
                #bar-5 {
                    width: 60%;
                }
                #bar-4 {
                    width: 30%;
                }
                #bar-3 {
                    width: 10%;
                }
                #bar-2 {
                    width: 4%;
                }
                #bar-1 {
                    width: 15%;
                }
            }
        }
    }

    #section2 {
        grid-column: 2;
        #reviewControls {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            margin: 10px 0;
            .rating-count {
                grid-row: 1;
                font-weight: bold;
                font-size: 12.701px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.06em;
                color: #000000;
                display: flex;
                align-items: flex-end;
            }
            #cardRatings {
                grid-row: 1;
                display: flex;
                border: 2px solid #828282;
                box-sizing: border-box;
                border-radius: 9px;
                font-weight: bold;
                font-size: 16px;
                line-height: 17px;
                align-items: center;
                text-align: right;
                letter-spacing: 0.04em;
                color: #828282;
                padding: 10px;
                width: min-content;
                margin-left: auto;
            }
        }
    }
}

//min-width: 481px:
@media only screen and (min-width: 481px) {
    #studentReviews {
        h1 {
            text-align: start;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
        }
        #section1 {
            width: 50%;
            margin: auto;
            #averageRating {
                .rating-value {
                    font-weight: bold;
                    font-size: 40px;
                    line-height: 60px;
                    letter-spacing: 0.05em;
                }
            }
        }
        #section2 {
            #reviewControls {
                .rating-count {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    letter-spacing: 0.06em;
                }
            }
        }
    }
}

//min-width: 768px:
@media only screen and (min-width: 768px) {
    #studentReviews {
        grid-template-columns: 15vw 2fr 15vw;
        h1 {
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.02em;
        }
    }
}

//min-width: 1024px:
@media only screen and (min-width: 1024px) {
    #studentReviews {
        grid-template-columns: 15vw 2fr 15vw;
    }
}
