#relatedCourses {

  
      // This is needed because arrows are hidden by default using Ant Design :
      .ant-carousel .slick-prev,
      .ant-carousel .slick-next {
        color: unset;
        font-size: 20px;
        
      }
      .ant-carousel .slick-prev:hover,
      .ant-carousel .slick-next:hover,
      .ant-carousel .slick-prev:focus,
      .ant-carousel .slick-next:focus {
        color: unset;
      }
      // .ant-carousel .slick-prev{
      //   left: -40px;
      // }

    width: 100%;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    grid-template-rows: 2fr;
    margin: 40px 0px;
    padding: 40px 0px;
    background-color: #E5E5E5;

        #content {
            grid-column: 2;
            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #03023B;
                grid-column: 2;
            }
            // Make sure to set container width:
            #carouselContainer {
              grid-column: 2;
              display: block;
              
              width: calc(100vw - 60px);

              .slick-slide.slick-cloned{
                min-width: 230px;
                max-width: calc(100vw - 60px) !important;
              }
              .slick-slide {
                min-width: 230px;
                max-width: calc(100vw - 60px) !important;
              }

              .ant-carousel .slick-dots-bottom {
                height: 20px;
              }
              
              .ant-carousel .slick-dots li button{
                height: 5px;
                width: 20px;
                background-color: black;
              }
            }
            .course-item {
                margin: 10px 0px;
                display: block;
            }
            .course-card {
              background-color: white;
              margin: auto;
            }
        }

}


  //min-width: 768px:
  @media only screen and (min-width: 768px) {
    #relatedCourses {
      grid-template-columns: 15vw 2fr 15vw;

      #content {
        #carouselContainer {
          .slick-slide.slick-cloned{
            // width: 365px !important;
            min-width: 230px;
            max-width: 70vw !important;
          }
          .slick-slide {
            min-width: 230px;
            // max-width: calc(70vw/2) !important;
          }

          
          width: 70vw;
        }
      }
    }
  }


  //min-width: 1024px:
  @media only screen and (min-width: 1024px) {
    #relatedCourses {
      grid-template-columns: 15vw 2fr 15vw;

      #content {
        h2{
          font-size: 24px;
          line-height: 29px;
        }
        }
        #carouselContainer {
          .slick-slide.slick-cloned{
            // width: 365px !important;
            min-width: 230px;
            max-width: 70vw !important;
          }
          .slick-slide {
            min-width: 230px;
            // max-width: calc(70vw/3) !important;
          }

          
          width: 70vw;
        }
      }
    }
  