@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import '../../../../globalStyles/helpers/variables';

#cta-section {
    background: $secondaryColor;
    height: 393px;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        position: relative;
        height: 60%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .heading {
            margin: 0;
            color: $offWhite;
            font-size: 4em;
            text-align: center;
            text-transform: none;
        }

        .info {
            color: $offWhite;
            text-align: center;
            font-size: 1.5em;
        }
        .cta-trial-btn {
            width: 200px;
            height: 50px;
            box-sizing: border-box;
            border-radius: 10px;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.045em;
            cursor: pointer;
            color: $lightGrey;
            background-color: $secondaryColor;
            border: 2px solid $lightGrey;
            transition: 0.3s ease;
        }

        .cta-trial-btn:hover {
            color: #3a86ff;
            box-shadow: inset 301px 0 0 0 white;
        }
    }
}

@media (max-width: 685px) {
    #cta-section {
        .container {
            width: 70%;
        }
    }
}

@media (max-width: 520px) {
    #cta-section {
        .container {
            width: 85%;
        }
    }
}

@media (max-width: 445px) {
    #cta-section {
        .container {
            width: 100%;
            height: 75%;
        }
    }
}

@media only screen and (max-width: 340px) {
    #cta-section {
        .container {
            height: 60%;
            .heading {
                font-size: 3em;
            }
            .info {
                font-size: 1.4em;
            }
        }
    }
}

// @media (max-width: 425px) {
//     #cta-section {
//         .container {
//             .heading {
//                 font-size: 32px;
//             }

//             .info {
//                 padding: 0 0 20px 0;
//             }
//         }
//     }
// }

// @media only screen and (max-width: 884px) {
//     #cta-section {
//         .container {
//             .heading {
//                 padding-bottom: 20px;
//             }
//             .info {
//                 padding-bottom: 5px;
//                 padding-left: 70px;
//                 padding-right: 70px;
//             }
//             .btn-wrapper {
//                 padding-bottom: 20px;
//                 .cta-trial-btn {
//                     width: 180px;
//                     height: 60px;
//                     margin-top: 25px;
//                     line-height: 20px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width: 460px) {
//     #cta-section {
//         .container {
//             .heading {
//                 padding-top: 20px;
//                 padding-right: 0;
//                 padding-left: 0;
//             }
//             .info {
//                 padding-bottom: 5px;
//                 padding-left: 0px;
//                 padding-right: 0px;
//             }
//             .btn-wrapper {
//                 padding-bottom: 20px;
//                 .cta-trial-btn {
//                     width: 130px;
//                     height: 50px;
//                     margin-top: 15px;
//                     line-height: 20px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width: 370px) {
//     #cta-section {
//         .container {
//             .heading {
//                 font-size: 30px;
//                 padding-bottom: 20px;
//                 padding-top: 20px;
//             }
//             .info {
//                 padding-bottom: 5px;
//                 padding-left: 0px;
//                 padding-right: 0px;
//             }
//             .btn-wrapper {
//                 padding-bottom: 20px;
//                 .cta-trial-btn {
//                     width: 130px;
//                     height: 50px;
//                     margin-top: 15px;
//                     line-height: 20px;
//                 }
//             }
//         }
//     }
// }

