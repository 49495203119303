@import '../../../../globalStyles/helpers/variables';

nav.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
#top-nav {
    .banner {
        position: fixed;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #3a86ff;
        padding: 10px;
        z-index: 30;
        animation: fadeIn 0.3s forwaxrds;

        p {
            color: white;
            font-weight: 500;
            font-size: 18px;
        }
        button {
            padding: 8px 25px;
            margin-left: 40px;
            background-color: #3a86ff;
            color: white;
            font-weight: 500;
            border: 2px solid white;
            border-radius: 10px;
            transition: 0.3s ease;

            &:hover {
                color: #3a86ff;
                box-shadow: inset 301px 0 0 0 white;
            }
        }
    }
}

nav {
    margin-top: 67px;
    margin-bottom: -79px;
    width: 100%;
    animation: smoothScroll 1s forwards;
    background: white;
    box-shadow: none;
    z-index: 3;
    .nav-center {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 70px;
        transition: height 0.25s ease;
        background: white;
        .nav-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 90px;
            padding: 0 30px;
        }
        .links-container {
            height: 0;
            overflow: hidden;
            .links:first-child {
                margin-top: 2em;
            }
            .links a {
                opacity: 0;
                color: $darkColor;
                font-size: 24px;
                text-decoration: none;
                // display: block;
                padding: 14px;
                &:hover {
                    color: $primaryColor;
                }
            }
        }
        .nav-btns {
            display: flex;
            visibility: hidden;
            opacity: 0;
            padding: 70px;

            .sign-in {
                background-color: 19#6bf0;
                color: white;
                margin-right: 15px;
                transition: all 0.3s ease-in-out;
            }

            .sign-in:hover {
                color: white;
                background-color:#196bf0;
            }

            .sign-up {
                background-color: white;
                color: #196bf0;
                transition: all 0.3s ease-in-out;
            }

            .sign-up:hover {
                color: #6299f1;
            }

            .to-dashboard {
                transition: all 0.3s ease-in-out;
            }

            .to-dashboard:hover {
                color: white;
                background-color: #196bf0;
            }
        }
    }
    .nav-center.menu-open {
        height: 100vh;
        .links-container {
            // height: 70%;
            height: auto;
            .links a {
                opacity: 1;
                transition: opacity 1s ease;
            }
        }
        .nav-btns {
            visibility: visible;
            opacity: 1;
            justify-content: space-between;
            transition: opacity 1.1s ease;
        }
    }
}

// Mobile Landscape
@media screen and (max-height: 568px) {
    nav .nav-center .links-container .links:first-child {
        margin-top: 0;
    }
}

@media screen and (max-height: 414px) {
    nav .nav-center .links-container .links {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;
        height: 100%;
    }
}

// @media screen and (min-width: 500px) {
    
// }

// Tablet/Desktop Menu
@media screen and (min-width: 886px) {
    nav {
        .nav-center {
            flex-direction: row;
            max-width: 1220px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            box-shadow: none;
            .nav-header {
                box-shadow: none;
                padding: 0;
                .hamburger-react {
                    display: none;
                }
            }
            .links-container {
                flex: 1;
                margin: 0 1rem;
                height: auto !important;
                .links:first-child {
                    margin-top: 0;
                }
                .links {
                    display: flex;
                    justify-content: space-evenly;
                    list-style-type: none;
                    gap: 10px;
                    a {
                        opacity: 1;
                        font-size: 16px;
                        padding: 0;
                        &:hover {
                            padding: 0;
                            background: transparent;
                        }
                    }
                }
            }
            .nav-btns {
                visibility: visible;
                opacity: 1;
                display: flex;
                margin-bottom: 0;
                padding: 0;
                button {
                    font-size: 16px;
                    padding: 8px 16px;
                    &:first-child {
                        margin-right: 16px;
                    }
                }
            }
        }
        .nav-center.menu-open {
            height: auto;
        }
    }
}

// Increase font size for desktop & large tablets
@media screen and (min-width: 1024px) {
    nav {
        .nav-center {
            .links-container .links a {
                font-size: 18px;
            }
            .nav-btns {
                button {
                    font-size: 18px;
                    padding: 12px 24px;
                }
            }
        }
    }
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-142px);
    }

    100% {
        transform: translateY(0px);
    }
}

@media screen and (max-width: 1380px) {
    #top-nav {
        .banner {
            p {
                font-size: 16px;
            }

            button {
                margin-left: 20px;
            }
        }
    }
}

@media screen and (max-width: 1215px) {
    #top-nav {
        .banner {
            p {
                font-size: 14px;
            }

            button {
                margin-left: 20px;
                transform: scale(0.9);
            }
        }
    }
}

@media screen and (max-width: 1085px) {
    #top-nav {
        nav {
            margin-top: 87px;
        }
        .banner {
            height: 85px;

            p {
                text-align: center;
                font-size: 18px;
            }
            button {
                margin-left: 20px;
                transform: scale(0.9);
                width: 180px;
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }
    }
}

@media screen and (max-width: 815px) {
    #top-nav {
        .banner {
            height: 85px;

            p {
                text-align: center;
                font-size: 15px;
            }
            button {
                margin-left: 20px;
                transform: scale(0.9);
                width: 180px;
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }
    }
}
@media screen and (max-width: 770px) {
    #top-nav {
        nav {
            margin-top: 0;
            padding-bottom: 15px;
        }
        .banner {
            display: none;
            height: 85px;

            p {
                text-align: center;
                font-size: 18px;
            }
            button {
                margin-left: 20px;
                transform: scale(0.9);
                width: 180px;
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }
    }
}
