@import '../../../../../globalStyles/helpers/variables';

.quizCont{
    width: 100%;
    height: 100%;
    border-inline: 1px solid $secondaryColor;
    border-bottom: 1px solid $secondaryColor;

    .quiz_header{
        height: 8vh;
        display: flex;
        border-block: 1px solid $secondaryColor;

        .back_btn{
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;

            p{
                margin-left: .5em;
                color: $secondaryColor;
                font-weight: 600;
            }
        }

        .quizInfo{
            width: 60%;
            height: 100%;

            .top{
                font-weight: 500;
                font-size: 12px;
                height: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .bottom{
                display: flex;
                align-items: center;
                height: 50%;

                .questionNum_indicator{
                    width: 17%;
                    height: 80%;
                    margin-right: 1em;
                    border: 1px solid $secondaryColor;
                    color: $secondaryColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .questionPoint{
                    color: $secondaryColor;
                    font-weight: 600;
                }
            }
        }
        .quizScore_header{
            height: 100%;
            width: 100%;

            h3{
                display: flex;
                align-items: center;
                height: 100%;
                font-size: 1.4em;
                margin-bottom: 0;
            }
        }
    }

    main.quizInfo{
        padding: 1em;
        position: relative;

        h1{
            width: 80%;
            font-size: 2em;
            font-weight: 500;
            color: $secondaryColor;
        }

        .duration_et_num{
            width: 30%;
            display: flex;
            justify-content: space-between;
            margin-bottom: .3em;

            p{
                font-weight: 500;
                font-size: 1em;
            }
        }

        p.info{
            color: $secondaryColor;
            font-weight: 400;
            font-size: 1.2em;
            margin-bottom: .3em;
        }

        .CTA, .nav_btn_cont{

            .ant-btn{
                border-radius: 8px;
                width: 150px;
                font-size: 1em;
                font-weight: 500;
                cursor: pointer;
            }
        }
        .nav_btn_cont{
            margin-left: 2em;
            display: flex;
            width: 53%;
            justify-content: space-between;
        }

        .questionCont{
            margin-left: 2em;
            margin-bottom: .3em;

            .selAns{
                font-size: 13px;
                font-weight: 600;
                color: $errorColor;
                position: absolute;
                left: 30%;
                top: 1px;
            }

            .questionCont_top{
                display: flex;

                .questionNum{
                    font-weight: 600;
                    margin-right: 1.5em;
                }
            }

            .question_options{
                margin-left: 2em;
            }
        }

        .scoreInfo{
            width: 70%;
            margin: 0 auto;
            text-align: center;

            .img_cont{
                background-color: $grey5;
                padding-block: 1em;
            }
            .mainText{
                text-align: center;
                font-weight: 400;

                h1{
                    width: 100%;
                    font-size: 2em;
                    text-align: center;
                    margin-bottom: .1em;
                }

                .subText{
                    font-size: .8em;
                    color: $secondaryColor;
                }
            }

            .gradeInfo{
                margin-block: .5em;
                display: flex;
                align-items: center;
                justify-content: center;

                .score{
                    margin-left: .5em;
                    font-size: 2em;
                    color: $secondaryColor;
                }
            }

            .CTA{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .retryLink{
                    font-size: 1.2em;
                    color: $secondaryColor;
                    margin-right: 2em;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .ant-btn{
                    padding: .2em;
                }
            }
        }
    }
}