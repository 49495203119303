@import '../../../globalStyles/helpers/_variables';
#subscription_cont{
    width: 100%;
    min-height: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 1em;
    position: relative;
    
    .content_cont{
        width: 100%;
        height: 90%;
        display: flex;

        .left_Hside, .right_Hside{
            width: 49%;
            height: 100%;
            padding-top: 2em;
        }

        .right_Hside{
            border-left: 2px solid $grey4;
            padding-inline: 2em;

            h2{
                font-size: $smallHeadings;
                color: $darkColor;
                text-align: center;
            }

            
            .topper{
                // padding-inline: 2em;
                text-align: center;
                margin-block: 2em;
                
                p{
                    font-size: $body;
                    font-weight: 400;
                }

                .userInfo{
                    padding-bottom: 2em;

                    b{
                        color: $darkColor;
                    }
                }
                
            }
            .not_topper{
                color: $grey4;
                
                p{
                    font-size: .8em;
                    font-weight: 400;
                }
            }

            h4{
                color: $darkColor;
                margin-top: 1em;
                font-size: $body;
            }
        }

        .left_Hside{
            text-align: center;

            h3{
                font-size: $body;
                color: $darkColor;
            }

            .subs_info{
                width: 90%;
                margin: 0 auto;
                margin-block: 1em;
                height: auto;
                border: 1px solid black;
                border-radius: 8px;
                padding-block: .5em;
                background: rgb(200,196,196);
                background: linear-gradient(93deg, rgba(200,196,196,1) 0%, rgba(255,255,255,1) 74%);

                span{
                    color: $primaryColor
                }

                div{
                    margin-block: 1.5em 3em;
                }

            }
            .renewal_date{
                margin-bottom: 2em;
            }
        }
    }

    div.btn_cont{
        padding-top: 1em;
        text-align: center;
        
        button{
            width: 208px;
            text-align: center;
            padding-block: .6em;
            background-color: $secondaryColor;
            color: white;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}

.subscription_cont.loader_cont{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 890px) {
    #subscription_cont .content_cont{
        flex-direction: column;
        justify-content: center;

        .right_Hside, .left_Hside{
            border: none;
            width: 100%;
        }
    }
}