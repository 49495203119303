#premium-page {
    .premium-heading{
        font-family: 'Roboto';
        font-style: normal;
        height: 5vh;
        p {
            font-weight: 700;
            font-size: 18px;
            /* or 66% */
            text-align: center;
            letter-spacing: 0.06em;
            color: #000000;
        }
    }
}

@media screen and (max-width: 500px) {
    #premium-page {
        .heading{
            // margin: 15px 15px;
            p {
                font-size: 12px;
            }
        }
    }
}