#start-career {
    position: relative;
    background-color: #f5f5f5;
    width: 100%;
    height: 100vh;

    .top-bar {
        width: 100%;
        height: 20%;
        background-color: #3a86ff;
        margin: 0;
        
        .title-container {
            text-align: center;
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            .start-title {
                font-size: 30px;
                margin: 0;
            }
        
            .zustech-title {
                font-size: 56px;
                color: white;
                margin: 0;
            }
        }
    }

    .mid-paragraph-container {
        width: 100%;
        height: 30%;
        
        .mid-paragraph {
            height: 100%;
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;

            .paragraph{
                min-width: 70%;
                max-width: 90%;
                color: black;
                font-size: 30px;
                font-weight: 600;
                text-align: center;
                padding-block: 1em;
            }
        }
    }

    .third_part{
        width: 100%;
        height: 10%;
        background-color: #3a86ff;

        .start-title-bottom {
            @extend .start-title;
            margin: 0;
            width: 60%;
            height: 100%;
            color: black;
            font-size: 30px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .bottom-bar {
        width: 100%;
        height: 30%;

        .form-container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            width: 60%;
            margin: 0;
            padding-block: 1em;

            .start-form {
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(1.2);
                margin: 0 auto;
            }
        
            .start-submit-btn {
                background-color: #3a86ff;
                color: white;
                border-radius: 0px 5px 5px 0px;
                padding: 10px 10px;
                border: 2px solid #3a86ff;
                margin-left: -10px;
            }
        
            input[type='text'] {
                width: 68%;
                padding: 10px 10px;
                border: 2px solid #3a86ff;
                border-radius: 5px 0px 0px 5px;
        
                &:focus {
                    outline: none;
                }
            }

            .under-email-info {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                margin: 0;
        
                .info-section {
                    display: flex;
                    align-items: center;
                }
                
                .info-tick {
                    width: 45px;
                    height: 45px;
                    border-radius: 25px;
                    background-color: rgb(26, 238, 157);

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                
                .info-text {
                    font-weight: 500;
                    font-size: 22px;
                    margin-left: 20px;
                    letter-spacing: 3px;
                }
            }
        }

    }

    .img_cont{
        position: absolute;
        width: 35%;
        height: 80%;
        top: 6%;
        right: 3%;
        border-radius: 10px;

        .graduate-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
            border-radius: 10px;
        }
    }
}

// @media (max-width: 1530px) {
//     #start-career {
//         .graduate-img {
//             right: 80px;
//         }
//     }
// }

// @media (max-width: 1410px) {
//     #start-career {
//         .graduate-img {
//             right: 40px;
//         }
//     }
// }

// @media (max-width: 1380px) {
//     #start-career {
//         .mid-paragraph {
//             font-size: 26px;
//         }

//         .graduate-img {
//             right: 10px;
//             transform: scale(0.9);
//         }
//     }
// }

// @media (max-width: 1265px) {
//     #start-career {
//         .graduate-img {
//             right: 10px;
//             transform: scale(0.8);
//         }
//     }
// }

// @media (max-width: 1225px) {
//     #start-career {
//         .graduate-img {
//             top: -55px;
//             right: 10px;
//             transform: scale(0.76);
//         }

//         .under-email-info {
//             width: 100%;
//             margin-top: -70px;
//         }

//         .start-form {
//             width: 100%;
//             transform: scale(1);
//         }

//         .start-submit-btn {
//             transform: scale(1.1);
//         }

//         input[type='text'] {
//             transform: scale(1.1);
//         }

//         .start-title-bottom {
//             width: 100%;
//         }
//     }
// }

@media (max-width: 1100px) {
    #start-career {
        .bottom-bar{
            .form-container{
                .under-email-info{
                    .info-text {
                        letter-spacing: 0;
                    }
                }
            }
        }
    }
}

// @media (max-width: 1010px) {
//     #start-career {
//         .graduate-img {
//             top: -55px;
//             right: -30px;
//             transform: scale(0.76);
//         }
//     }
// }

// @media (max-width: 920px) {
//     #start-career {
//         .mid-paragraph {
//             padding: 40px 40px 40px 40px;
//         }

//         .graduate-img {
//             top: -90px;
//             right: -60px;
//             transform: scale(0.65);
//         }
//     }
// }

// @media (max-width: 920px) {
//     #start-career {
//         .mid-paragraph {
//             padding: 80px 40px 80px 40px;
//         }

//         .graduate-img {
//             top: 8px;
//             right: -60px;
//             transform: scale(0.48);
//         }

//         .title-container {
//             width: 100%;
//         }
//     }
// }

@media (max-width: 795px) {
    #start-career {
        .mid-paragraph-container {
            .mid-paragraph{
                .paragraph{
                    font-size: 2em;
                }
            }
        }

        .bottom-bar{
            .form-container{
                .under-email-info{
                    flex-direction: column;
                    align-items: start;

                    .info-section{
                        padding-inline: 1.4em;
                        
                        .info-text {
                            letter-spacing: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 602px) {
    #start-career {
        .top-bar .title-container, .mid-paragraph-container .mid-paragraph, .third_part .start-title-bottom, .bottom-bar .form-container{
            width: 100%;
        }

        .img_cont{
            display: none;
        }

        .bottom-bar{
            .form-container{
                .under-email-info{
                    flex-direction: row;
                }
            }
        }
    }
}


@media (max-width: 432px) {
    #start-career {
        .bottom-bar{
            .form-container{
                .under-email-info{
                    flex-direction: column;
                    align-items: start;

                    .info-section{
                        margin-bottom: 1em;
                    }
                }
            }
        }
    }
}

@media (max-width: 360px) {
    #start-career {
        .top-bar{
            .title-container{
                .start-title, .zustech-title{
                    font-size: 2em;
                }
            }
        }

        .mid-paragraph-container{
            .mid-paragraph .paragraph{
                font-size: 1.5em;
            }
        }

        .third_part{
            .start-title-bottom{
                font-size: 1.3em;
            }
        }
        .bottom-bar{
            .form-container {
                .start-form {
                    width: 75%;
                    transform: scale(1);
                }
            
                .start-submit-btn {
                    padding: .1em;
                    margin-left: 0px;
                }
            
                input[type='text'] {
                    width: 68%;
                    padding: 10px 10px;
                }
            }

            .under-email-info{                
                .info-tick {
                    width: 15px;
                    height: 15px;
                }
                
                .info-text {
                    font-size: 16px;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

// .cta-input {
//     display: none;
//     width: 0;
// }
// .cta-form {
//     display: none;
//     width: 100%;
//     justify-content: center;
//     text-align: center;
//     padding-top: 50px;
// }
// .cta-form-2 {
//     display: flex;
//     width: 100%;
//     justify-content: center;
//     text-align: center;
// }
// .cta-submit-btn {
//     width: 100%;
//     height: 45px;
//     font-weight: 700;
//     text-align: center;
//     letter-spacing: 0.04em;
//     margin: 0;
//     color: #ffffff;
//     border-radius: 8px;
// }

// .cta-text {
//     width: auto;
//     padding-top: 30px;
//     line-height: 26px;
//     font-weight: normal;
//     letter-spacing: 0.06em;
// }

// .feature-points {
//     padding-top: 20px;
//     width: 90%;
//     display: none;
//     justify-content: space-between;
// }
// .feature-points-2 {
//     padding-top: 20px;
//     width: 90%;
//     display: flex;
//     justify-content: space-between;
// }
// .bullet {
//     list-style: none;
//     padding-left: 20px;
// }
// .hero-left {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     background-color: green;
// }
