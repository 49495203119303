.active-enrolled-cont{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;    

    .enrolled-card-cont{
        width: 90%;
        // max-width: 700px;
        // height: 25vh;
        padding: 8px 10px 10px 10px;
        margin-top: 20px;
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        // border: 1px solid grey;
        box-shadow: 0px 5px 10px grey;
        .enrolled-thumbnail{
            width: 16%;
            height: 100px;
            border-radius: 8px;
        }
        .desc-content{
            width: 60%;
            h3{
                color: #3A86FF;
                font-weight: 600;
                font-size: 1.5rem;
            }
            p{
                font-weight: 500;
                font-size: 1.2rem;
                padding-bottom: 15px;
            }
            .prog-detail{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .complete-course-text{
                font-weight: 500;
            }
        }
        .sect-progress-mode{
            // gap: 7px;
            .progress-mode-btn {
                width: 150px;
                padding: 10px 10px 10px 10px;
                background: #3A86FF;
                border-radius: 8px;
                color: white;
                font-size: .85rem;
                cursor: pointer;
            }
            .rate-review{
                text-decoration: underline;
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .active-enrolled-cont{  
        .enrolled-card-cont{
            position: relative;
            display: flex;
            justify-content: space-around;
            gap: 7px;
            flex-wrap: wrap;

            .enrolled-thumbnail{
                width: 130px;
                border-radius: 8px;
            }
            .desc-content{
                width: 60%;
                h3{
                    font-weight: 600;
                }
                p{
                    font-weight: 500;
                    // padding-bottom: 15px;
                }
            }
            .sect-progress-mode{
                display: flex;
                flex-direction: column-reverse;
                .progress-mode-btn {
                    width: 150px;
                    margin-top: 9px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .active-enrolled-cont{  
        .enrolled-card-cont{
            position: relative;
            .enrolled-thumbnail{
                width: 130px;
                border-radius: 8px;
                // position: relative;
                // top: 0px;
                // left: 0px;
            }
            .desc-content{
                width: 60%;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                h3{
                    font-size: .8rem;
                }
                p{
                    font-size: .6rem;
                }
                .complete-course-text{
                    font-size: .6rem;
                }
            }
            .progress-mode-btn {
                font-size: .6rem;
            }
        }
    }
}