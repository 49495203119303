@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&family=Pinyon+Script&family=Square+Peg&display=swap');

#cert-container{
    background: url("./assets/Texture 1.png") no-repeat center;
    background-size: cover;
    width: 800px;
    margin: 0 auto;
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;

    /* background overlay  */
    &::before{
        content: "";
        position: absolute;
        background-color: rgba(56, 126, 240, 0.2); /* Blue color with 20% opacity */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .section--1{
        width: 25%;
        position: relative;
    
        .logo_border_cont{
            width: 100%;
            height: 100%;
    
            .logo--border--img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    
        .sec_cont{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
            .logo__cont, .seal__cont{
                width: 80%;
    
                .zustech--logo, .zustech--seal{
                    width: 100%;
                    object-fit: cover;
                    padding-top: 10px;
                }
            }
    
            .cert--number{
                font-weight: 600;
                font-size: .75em;
                // margin: 0 auto;
                padding:  0 0 16px 0;
            }
        }
    }

    .section--2 {
        width: 75%;
        // height: 100%;
        // position: relative;
        // top: 0;
        // right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    
      .top_layer{
        width: 100%;
        // height: 35%;
        .cert--title {
            width: 100%;
            text-transform: uppercase;
            text-align: center;
            font-size: 200%;

            .one {
                font-family: 'EB Garamond', sans-serif;
                font-size: 2em;
                letter-spacing: 5px;
            }

            .two{
                font-size: 1.6rem;
                letter-spacing: 3px;
            }
        }

        .imgLine__cont{
            height: 20px;
            .image--line{
                width: 40%;
                height: 80%;
                margin-bottom: 10px;
            }
        }

        .we--hereby{
            font-size: .9em;
            font-style: italic;
            font-weight: 700;
        }
    }
    
    .lower_layer{
        width: 100%;
        // height: 53%;
        .assignee--name{
            font-size: 4em;
            font-weight: 500;
            font-family: "Pinyon Script", sans-serif;
            text-align: center;
            letter-spacing: 6px;
        }
        .text-- {
            font-size: .9em;
            font-style: italic;
            font-weight: 500;
        }
        .text--course{
            font-size: 1em;
            text-align: center;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-block: .8em 2.5em;
        }
    
        .bottom--signature{
            display: flex;
            justify-content: space-around;
            width: 100%;
            padding-top: 2rem;
        
            .date--section {
                width: 50%;
                font-weight: 700;
                font-size: .9em;
                padding-top: 10px;
                p{
                    font-weight: 500;
                    font-size: 1em;
                    font-weight: bold;
                }
                .cert--date {
                    text-decoration: underline;
                }
            }
            
            .assigner--section {
                width: 50%;
                p{
                    font-weight: 700;
                    font-size: .9em;
                }
                .signature {
                    width: 30%;
                }
            }
        }
    }
    }
}

// @media screen and (max-width: 1024px) {
//     .cert--number{
//         font-size: medium;
//     }
//     .section--2 {
//         width: 75%;
//     }
//     .cert--title .one {
//         font-size: 4rem;
//     }
//     .cert--title .two{
//         font-size: 2.6rem;
//     }
//     .we--hereby{
//         font-size: 1rem;
//         margin-bottom: 30px;
//     }
//     .assignee--name{
//         font-size: 4rem;
//         letter-spacing: 15px;
//         margin-bottom: 20px;
//     }
//     .text-- {
//         font-size: 1.4rem;
//         margin-bottom: 20px;
//     }
//     .text--course{
//         font-size: 1.4rem;
//         line-height: 30px;
//         margin-bottom: 20px;
//     }
//     .bottom--signature{
//         margin-top: 2rem;
//     }
//     .date--section {
//         font-size: 1rem;
//     }
//     .cert--date {
//         margin-bottom: 5px;
//     }
//     .assigner--section {
//         font-size: 1rem;
//     }
// }

// @media screen and (max-width: 860px) {
//     .assignee--name {
//         font-size: 3rem;
//     }
// }

// @media screen and (max-width: 750px) {
//     .cert--number{
//         font-size: small;
//     }

//     .cert--title .one {
//         font-size: 3rem;
//     }
//     .cert--title .two {
//         font-size: 2rem;
//     }
//     .assignee--name {
//         font-size: 2rem;
//         font-weight: bold;
//     }
//     .text--{
//         font-size: 1rem;
//     }
//     .text--course{
//         font-size: 1.2rem;
//     }
// }

// @media screen and (max-width: 600px) {
//     .cert--title .one{
//         font-size: 2.6rem;
//     }
//     .cert--title .two{
//         font-size: 1.8rem;
//     }
//     .date--section, .assigner--section{
//         font-size: .7rem;
//     }
// }

// @media screen and (max-width: 550px) {
//     .cert--number{
//         font-size: .65rem;
//     }
//     .cert--title .one{
//         font-size: 2rem;
//     }
//     .cert--title .two{
//         font-size: 1.6rem;
//     }
//     .text--course{
//         font-size: 1rem;
//     }
// }

// @media screen and (max-width: 400px) {
//     .cert--title .one{
//         font-size: 1.4rem;
//     }
//     .cert--title .two {
//         font-size: 1rem;
//     }
// }