@import '../../../../globalStyles/helpers/variables';

.course__card {
    background-color: $offWhite;
    border-radius: 20px;
    height: 178px;
    box-shadow: 0px 4.320000648498535px 4.320000648498535px 0px #0000001A;
    padding-inline: .5em;
    padding-block: 1em;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 1em;

    h3{
        color: $darkColor;
        font-size: 24px;
        font-weight: 700;
    }

    p{
        color: $grey4;
        font-size: 14px;
        font-weight: 500;
    }


    .course__thumbnail{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        width: 40%;
        border-radius: 10px;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
    
    .course__details{
        width: 80%;
        text-align: left;
        padding-inline: .5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3{
            height: 55px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p{
            font-size: $body;
            padding-bottom: 1em;
            font-weight: 400;
        }

        button{
            width: 100%;
            font-size: $body;
            font-weight: 500;
            background: none;
            color: $secondaryColor;
            border: 3px solid $secondaryColor;
            border-radius: $normal-radius;
            cursor: default;
        }
    }

    .lesson.detail_cont{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .detail_cont{
        display: flex;
        width: 40%;
        .course__numericsI, .course__numericsII{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .cc_progrs{
                font-size: $smallHeadings;
                color: $secondaryColor;
            }
            
            .icon_et_info{
                display: flex;
                align-items: center;
                justify-content: center;

                span{
                    margin-inline: .5em;
                }
            }
        }
        
        .course__numericsI{
            border-inline: 2px solid $grey4;
            
            .file__cont{
                width: 90%;
                font-size: 16px;
                font-weight: 500;
                background: none;
                display: flex;
                padding: 3px 5px;
                color: $secondaryColor;
                justify-content: space-between;
                align-items: center;
                border: 3px solid $secondaryColor;
                border-radius: $normal-radius;
                cursor:default;

                .file__types{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 60%;
                }
            }

        }
    }

    .module.course__status{
        width: 20%;
        padding-left: 6em;
    }

    .course__status{
        width: 15%;
        display: flex;
        justify-content: space-evenly;
        align-items: space-evenly;
        flex-direction: column;
        
        button{
            width: 100%;
        }
    }
    
    .course__numericsI{
        border-inline: 2px solid $grey4;

    }
}

@media screen and (max-width: 1075px){
    .course__card{
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        grid-template-rows: 180px 50px;
        gap: 5px;
        height: 260px;

        .course__thumbnail {
            grid-column: 1/2;
            grid-row: 1/2;
            width: 100%;
        }
        .course__details {
            display: grid;
            grid-column: 2/3;
            grid-row: 1/2;
            width: 100%;
            padding: 0;
        }
        .detail_cont{
            grid-column: 3/-1;
            grid-row: 1/2;
            width: 100%;

            .course__numericsI{
                width: 100%;
                border-right: none;

                .file__cont{
                    width: 100%;
                }
            }
        }
        .course__status{
            grid-column: 3/-1;
            grid-row: 2/-1;
            width: 100%;
            height: 80%;
            flex-direction: row;
            justify-content: space-between;

            a{
                width: 49%;
                height: 100%;
                button{
                    width: 100%;
                    height: 100%;
                }
            }
            button{
                width: 49%;
                height: 100%;
            }
        }

        .module.course__status, .lesson.course__status{
            width: 100%;
            justify-content: center;
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 850px) {
    .course__card{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 2fr 50px;
        height: 350px;
        width: 48%;

        .course__thumbnail{
            grid-column: 1/2;
            grid-row: 1/2;
            width: 100%;
        }

        .course__details {
            display: grid;
            grid-column: 2/-1;
            grid-row: 1/2;
            width: 100%;
            h3{
                font-size: 1.3em;
            }
        }

        .detail_cont{
            grid-column: 1/-1;
            grid-row: 2/3;

            .course__numericsI .icon_et_info span, .course__numericsII .icon_et_info span{
                margin-inline: .2em;
                font-size: .8em;
            }

            .course__numericsI{
                border-left: none;
            }
        }

        .course__status{
            grid-column: 1/-1;
            grid-row: 3/-1;
            width: 100%;
        }
    }
}

@media screen and (max-width: 720px) {
    .course__card{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 160px 140px 50px;
        width: 80%;
        min-height: 380px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 500px) {
    .course__card{
        width: 100%;

        .detail_cont .course__numericsI p, .detail_cont .course__numericsII p{
            font-size: .9em;
        }
    }
}