@import '../../../../globalStyles/helpers/variables';

.Dashboard__content {
    padding-inline: .5em;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #F6F7FB;
}

.Dashboard__content::-webkit-scrollbar {
    width: 10px;
}

.Dashboard__content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(65, 65, 65, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
