.noEnrolled-- {
    width: 100%;
    // margin: 0 auto;
    margin-bottom: 35px;
    section{
        padding-bottom: 25px;
    }

    .sec1--, .sec2-- {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
            font-size: 1.7rem;
            font-weight: 500;
            text-align: center;
        }
        .btn-- {
            // padding: 10px 20px 10px 20px;
            padding: .7rem;
            margin-top: 20px;
            background: #3A86FF;
            border-radius: 8px;
            color: whitesmoke;
            font-size: .85rem;
            // margin: 0 auto;
        }
    }
}