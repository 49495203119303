$blue: #3a86ff;

#ReviewPage {
    .header {
        font-size: 40px;
        padding-bottom: 20px;
        h1 {
            color: #3a86ff;
        }
    }
    .courseName {
        font-weight: bold;
        color: #3a86ff;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        span {
            font-size: 14px;
            color:#838383;
            font-weight: 300;
        }
    }
    .courseDetails {
        border: solid 20px #e3ecfb;
        background-color: #fdfdfd;
        border-radius: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'courseSummary courseSummary' 'categories difficulty' 'prerequisites language';
        min-height: 70vh;
        padding: 4rem 2rem 2rem 2rem;

        .info {
            h3 {
                color: black;
            }
            &:nth-child(1) {
                grid-area: courseSummary;
            }
            &:nth-child(2) {
                grid-area: difficulty;
            }
            &:nth-child(3) {
                grid-area: categories;
            }
            &:nth-child(4) {
                grid-area: prerequisites;
            }
            &:nth-child(5) {
                grid-area: language;
            }

            .categories {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;

                button {
                    border: 1px solid $blue;
                    padding: 0 .5rem;
                    background: none;
                    border-radius: 2rem;
                    cursor: default;
                }
            }
        }
    }
    .modulesTitle {
        margin: 3rem 0 2rem 0;
        color: #3a86ff;
    }

    .moduleAndLessonContainer {
        border: solid 20px #e3ecfb;
        background-color: #fdfdfd;
        border-radius: 30px;
        padding: 3rem 2rem 2rem 2rem;
        margin: 20px;
        .moduleName {
            margin-bottom: 4rem;
            h3 {
                margin-bottom: 1rem;
                color: #000;
            }
        }
        .lessonContainer {
            // margin-bottom: 3rem;
            border-top: 2px solid #e5e5e5;

            .lesson {
                margin: 1rem 0 1rem 0;

                padding-right: 6rem;
                &.content {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .lessonName {
                    color: #000;
                }
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: space-evenly;
        .editButton {
            width: 150px;
        }
        .submitButton {
            width: 150px;
        }
    }
    .lessonCardModalText {
        text-align: center;
    }
    .lessonCardModalButtons {
        display: flex;
        justify-content: space-between;
        .lessonCardModalButton {
            padding-top: 10px;
            width: 40%;
            margin-bottom: -20px;
        }
    }
}
