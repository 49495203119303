@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact{
    width: 100%;
    margin: auto;
    font-family: "Roboto", sans-serif;
}
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
}

.top-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-container{
        position: relative;
        width: 95%;
        margin-bottom: 2em;

        .image-top{
            width: 100%;
            margin: auto;
        }
        h3.top-text{
            position: absolute;
            color: white !important; 
            font-size: 3rem;
            width: 100%;
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.06em;
            text-transform:none;
            bottom: 20px;
            text-align: center;
        }
    }

    .search-box{
        display: flex;
        align-items: center;
        width: 100%;
        height: 5vh;
        margin-bottom: 1.5em;
    }
    // .search-article{
    //     width: 471px;
    //     height: 48px;
    //     margin-top: 30px;
    //     margin-bottom: 40px;
    //     border: 1px solid #0837E5;
    //     border-radius: 15px;
    //     text-indent: 10px;
    //     font-size: 1.2rem;
    // }
    .btn-faq{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 1em;
    }
    .btn-student, .btn-instructor{
        border: 1px solid #0837E5;
        width: 17%;
        font-size: 20px;
        padding: 32px, 78px, 32px, 78px;
        background: #3A86FF;
        border-radius: 10px;
        color: white;
        box-shadow: 15px 15px 15px grey;
    }

    .btn:active{
        background-color: #0837E5;
        color: white;
    }
    .btn-student{
        margin-right: 40px;
        .btn:active{
            background: #0837E5;
            color: white;
        }
    }
} 

// MIDDLE SECTION (FAQ)
.faq-container{
    width: 100%;

    .faq-head{
        color: #0837E5;
        font-size: 2rem;
        padding: 1em;
    }
    .questions-container{
        position: relative;
        width: 80%;
        margin: 0 auto;
        border-radius: 1em;
        
        .accordion__button{
            font-size: 1.2rem;
        }

        .accordion__panel{
            font-size: .8rem;
        }

    }

    .article-faq{
        padding-bottom: 12px;
        cursor: pointer;
        // .btn-question{
        //      width:50px; 
        // }
        .title-faq{
            display: flex;
            justify-content: space-between;
            // ::before{
            //     content: "•";
            //     color: #0837E5;
            //     font-size: 7rem;
            //     margin: 0;
            //     padding: 0;
            // }
        }
    }
}

//BOTTOM SECTION (EXPLORE ICONS)
#explore.bottom-section{
    width: 100%;
    padding-left: 15px;
    padding-right: 10px;

    &>h3{
        margin-top: 20px;
        text-align: center;
        font-size: 2.5em;
        // background-color: red;e
        // color: black!important;
    }

    .topics-explore{
        color: black !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 1.2em;
        height: 100%;
        padding: 10px;
        margin-block: 40px;
        // cursor: pointer;
        // margin-right: 40px;
        // margin-left: 120px;
        // flex-wrap: wrap;
        // width: auto;
        // box-shadow: -0px 4px 2px grey;

        a:hover{
            transform: scale(1.1);
            box-shadow: .1em .1em 1em .1em grey;
            cursor: default;
        }
    }
    .zustech-icon, .profile-icon, .subscription-icon,
    .takeCourse-icon,.createCourse-icon,.paymentWithdrawal-icon,
    .troubleshoot-icon{
        max-width: 100px;
        max-height: 100px;
    }
    .explore-links{
        width: 210px;
        height: 210px;
        border-radius: 8px;
        color: black;
        padding: 4px;
        font-size: 24px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.06em;
        text-align: center;
        background-color: white;
        overflow-y: scroll;
        // cursor: pointer;
        // margin: 0 auto;
        // box-shadow: 0px 0px 10px grey;
        // background-color: #0837E5;
        
        // .explore-links:hover{
        //     box-shadow: 0px 0px 10px #3A86FF!important;
        //     cursor: pointer;
        // }

        // a{
        //     text-decoration: none;
        //     text-transform: none;
        // }
    }
    .here{
        text-align: center;
        font-size: 18px;
        padding: 20px;

        a{color: #0837E5;}
    }

}


@media screen and (max-width:800px) {
    .top-section{
        .img-container{
            h3.top-text{
                font-size: 2rem;
            }
        }
        .btn-student, .btn-instructor{
            padding: 18px, 48px, 18px, 48px;
            font-size: 26px;
        }
    }

    .questions-container{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

@media screen and (max-width: 660px){
    .top-section{
        .img-container{
            h3.top-text{
                font-size: 1.2rem;
            }
        }
        .search-article{
            width: 250px;
            font-size: 15px;
        }
        .button.btn{
            width: 270px;
            font-size: 15px;
        }
    }
    .faq-container{
        .faq-head{
            color: #0837E5;
            font-size: 1.3rem;
        }
    }
}

@media screen and (max-width: 580px) {
    .top-section{
        .btn-student, .btn-instructor{
            font-size: 8px;
        }
    }
}
