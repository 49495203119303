@import '../../../../../globalStyles/helpers/variables';

.course_overview{
    background-color: #F6F7FB;

    .heading_cont{
        display: flex;
        height: 50px;
        margin-block: .8em 1em;
        justify-content: space-between;

        .control_btn{
            display: flex;
            width: 26%;
            justify-content: space-between;
            align-items: center;
            justify-self: right;
            margin-right: 1em;

            .edit_btn, .submit_btn{
                // font-size: 20px;
                padding: .3em;
                font-weight: 700;
                width: 43%;
                height: 80%;
                border-radius: 10px;
                cursor: pointer
            }
        }

        h2 {
            font-size: $mediumHeadings;
            font-weight: 700;
            height: 100%;
            width: auto;
            margin-bottom: 0;
            color: $secondaryColor;
            display: flex;
            align-items: center;
        }

        .img_cont{
            width: 50px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                cursor: pointer;
            }
        }
    }
    
    .progress_cont{
        display: flex;
        justify-content: flex-end;
        margin-bottom: -.8em;
        
        .top_bar{
            font-family: $font-robotto;
            font-size: 12px;
            font-weight: 700;
            width: 33%;
            padding: 0;
            letter-spacing: 0.355em;
            
            p{
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;
                margin-bottom: -2em;
            }
        }
    }

    .course_content{
        width: 100%;
        height: auto;
        display: flex;

        div.content{
            width: 67%;
            height: 100%;
            position: relative;

            .video_cont{
                width: 100%;
                height: 70vh;
                margin-top: 0;
                position: relative;

                .react-player__preview{
                    width: 100%;
                    height: 100%;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .tab_cont{
                width: 100%;
                min-height: 10vh;
                padding-right: .5em;

                .overview_section{
                    h2{
                        font-size: $mediumHeadings;
                        font-weight: 700;
                        padding-inline: .5em ;
                    }
                    
                    p{
                        font-size: $body;
                        font-weight: 400;
                        width: 100%;
                        padding-inline: 1.4em ;
                    }

                    .course_info{
                        width: 100%;
                        padding-inline: 1.4em;
                        min-height: 15vh;
                        border-block: 2px solid rgba(224, 224, 224, 1);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-block: 1em;
                        flex-wrap: wrap;
                        gap: 1em;
                        
                        .info_item{
                            max-height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;
                            text-align: center;

                            .info_header{
                                font-size: $smallHeadings;
                                font-weight: 700;
                                text-align: center;
                            }
                        }
                    }

                    .objectives{
                        min-height: 15vh;
                        padding-bottom: 1.4em;
                        // background-color: blue;
                        
                        .objectives_items ul{
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-template-rows: repeat(auto, 5vh);
                            gap: 1em;
                            list-style-type: none;
                            list-style-image: url('../../../../../assets/icons/obj_icon.svg');
                            
                            li{
                                width: 100%;
                                padding-inline: .3em;
                            }
                        }
                    }
                    
                    .prerequisite{
                        min-height: 15vh;
                        padding-block: 1.4em;
                        // background-color: red;
                        border-block: 2px solid rgba(224, 224, 224, 1);

                        .prerequisite_items ul{
                            list-style-type: none;
                            list-style-image: url('../../../../../assets/icons/prep_icon.svg');
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-template-rows: repeat(auto, 5vh);
                            gap: 1.3em;

                            li{
                                width: 100%;
                                padding-inline: .3em;
                            }
                        }
                    }

                    .instructor_into{
                        padding: 1em;
                        width: 100%;

                        h3{
                            color: black !important;
                        }

                        .instructor_details{
                            width: 100%;
                            height: auto;
                            display: flex;
                            font-size: $body;
                            margin-bottom: 1em;
                            
                            .img_cont{
                                margin-right: 2em;
                            }

                            .instructor_info{
                                width: 100%;

                                .first_layer{
                                    width: 80%;
                                    display: flex;
                                    justify-content: space-between;
                                    // flex-wrap: wrap;

                                    div{
                                        width: 50%;
                                        display: flex;
                                        justify-content: space-between;

                                        .follow{
                                            color: $secondaryColor;
                                            font-weight: 400;
                                            cursor: pointer;
                                            padding-inline: .2em;
                                        }
                                    }

                                    &>span{
                                        min-width: 40%;
                                        font-weight: 700;
                                    }
                                }
                                
                                .next_layer{
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    flex-wrap: wrap;
                                    
                                    img{
                                        margin-right: .4em;
                                    }
                                }
                            }
                        }
                    }
                }
                
                .review_section{
                    padding-bottom: 2em;
                    .review_summary{
                        width: 100%;
                        height: 20vh;
                        display: flex;
                        justify-content: center;
                        background-color: $offWhite;
                        margin-bottom: 1em;
                        border-radius: 8px;
                        box-shadow: 0px 4.320000648498535px 4.320000648498535px 0px #0000001A;

                        .star_rating{
                            width: 30%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            h1{
                                font-size: 3em;
                                margin: 0;
                                color: $secondaryColor;
                            }
                            .rate{
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 0;
                            }
                        }
                        .progress_cont{
                            width: 38%;
                            font-weight: 500;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            .progress_item{
                                display: flex;
                                width: 95%;

                                span{
                                    padding-right: .3em;
                                }
                            }
                        }
                    }
                    .review_form{
                        background-color: $offWhite;
                        width: 100%;
                        min-height: 20vh;
                        border-radius: 8px;
                    }
                    .blur {
                        position: relative;
                    }

                    .blur::after{
                        content: 'To leave a review, you have to start/continue learning the course.';
                        width: 100%;
                        height: 100%;
                        font-size: 1.5em;
                        font-weight: 500;
                        // color: $contentBgc;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 8px;
                        background-color: rgba(255, 255, 255, 0.8);
                        cursor: help;
                    }
                    .review_comment{
                        .user_comment_cont{
                            background-color: $offWhite;
                            width: 100%;
                            min-height: 20vh;
                            border-radius: 8px;
                            box-shadow: 0px 4.320000648498535px 4.320000648498535px 0px #0000001A;
                            padding: .5em 1.5em;
                            margin-bottom: 1em;
                            
                            .comment_header{
                                width: 100%;
                                height: auto;
                                display: flex;
                                align-items: center;
                                
                                h3{
                                    font-size: $body;
                                    font-weight: 700;
                                    margin-inline: .5em;
                                    text-transform: capitalize;
                                    height: 100%;
                                }
                            }
                            
                            .rate{
                                display: flex;
                                align-items: center;
                            }

                            &>p{
                                width: 90%;
                                font-size: $body;
                                font-weight: 400;
                                margin-block: .3em;
                            }
                            
                            .report{
                                display: flex;
                                justify-content: space-between;
                                
                                .review{
                                    display: flex;
                                    align-items: center;
                                    p{
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: $grey2;
                                        margin-right: 1.5em;
                                    }

                                    .thumbs{
                                        display: flex;

                                        .thumb_up, .thumb_down {
                                            display: flex;
                                            margin-right: .6em;

                                            .img_cont{
                                                margin-right: .3em;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }

                                p{
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: $grey2;
                                    cursor: pointer;
                                }
                            }
                        }

                        .no_review{
                            background-color: $offWhite;
                            width: 100%;
                            min-height: 10vh;
                            border-radius: 8px;
                            box-shadow: 0px 4.320000648498535px 4.320000648498535px 0px #0000001A;
                            padding: .5em 1.5em;
                            
                            h4{
                                font-size: 1.3em;
                                font-weight: 500;
                            }

                            h5{
                                font-size: $body;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }
        
        aside{
            width: 33%;
            background-color: white;
            min-height: 60vh;
            
            h2{
                font-size: 24px;
                padding: 1em;
                font-weight: 700;
                color: $secondaryColor;
            }

            .introLessons_cont, .module_cont, .quiz_cont{
                width: 100%;

                .ant-collapse-header{
                    padding: 0;
                    padding-inline: .7em;
                    border: none;
                    border-bottom: 2px solid rgba(58, 134, 255, 0.1);
                    background-color: #fff;
                }

                .module_item{
                    width: 100%;
                    height: 6vh;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-transform: capitalize;
                    font-weight: 400;
                    overflow: hidden;
                    
                    p{
                        width: 60%;
                        // white-space: nowrap;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 1em;
                        font-weight: 600;
                    }

                    div{
                        width: 35%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span{
                            width: 70%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .ant-collapse-content-box{
                    padding: 0;
                    padding-inline: .6em;
                }

                .lesson_item, .quizControl_cont{
                    width: 100%;
                    height: 5vh;
                    display: flex;
                    padding-inline: 1em .6em;
                    font-size: .8em;
                    font-weight: 400;
                    justify-content: space-between;
                    align-items: center;

                    &>p{
                        width: 50%;
                        font-size: 14px;
                        font-weight: 400;
                        cursor: pointer;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .quizScore{
                        text-align: right;
                    }

                    .previewQuiz{
                        background-color: $secondaryColor;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        padding-inline: .8em .6em;
                    }

                    .quizCTA{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-inline: .8em 1.6em;
                        border-bottom: 1px solid $grey5;

                        p{
                            font-weight: 600;
                            font-size: 1.1em;
                        }
                    }

                    div{
                        width: 25%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        p{
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }

                .quizControl_cont{
                    padding-inline: 0;
                }
            }            
        }
    }
}

.ant-modal-content{
    border-radius: 20px;
    text-align: center;

    .ant-modal-body{
        padding: 10px;

        .review_cont{
            text-align: left;
    
            .title{
                width: 100%;
                font-size: $mediumHeadings;
                text-align: center;
            }

            .review_form_cont {
                margin-bottom: 0;
                box-shadow: none;

                .host_two{
                    height: 80px;

                    .formm{
                        width: 60%;
                    }
                }
            }
        }

        .celeb_cont{
            .content{
                text-align: center;

                .img_cont{
                    margin-block: .3em;
                }

                p{
                    font-weight: 700;
                    font-size: 16px;
                    margin-block: .5em;
                }

                .cert_info{
                    width: 100%;
                    margin-block: .3em;
                    font-weight: 700;
                    font-size: $mediumHeadings;
                }

                .styled_text{
                    width: 80%;
                    font-weight: 500;
                    font-size: $smallHeadings;
                    margin: 0 auto;
                    color: $secondaryColor;
                }

                .ctrl_btn{
                    width: 70%;
                    height: 3em;

                    .ant-btn{
                        width: 40%;
                        border-radius: 8px;
                        font-weight: 700;
                        // padding-inline: 1em;
                        font-size: 1em;
                    }
                }
            }
        }
    }

    .title{
        width: 100%;
        font-size: $mediumHeadings;
    }

    .subInfo{
        width: 90%;
        margin: 0 auto;
    }

    .histoy_detail{
        margin-top: 2em;
    }

    .ctrl_btn{
        width: 80%;
        margin: 0 auto;
        height: 30px;
        margin-top: 2em;
        display: flex;
        justify-content: space-between;

        button{
            width: 30%;
            height: 100%;
            border-radius: 8px;
            font-size: $body;
            font-weight: 700;
        }
        .positive{
            width: 50%;
            background-color: $secondaryColor;
            color: white;
            transition: all 0.5s ease-in-out;

            &:hover{
                background-color: #236bde;
            }
        }
    }

    .courseOptionCont{

        &>p{
            font-size: 18px;
            font-weight: 500;
        }

        ul{
            list-style-type: none;
            text-align: left;
            padding: 0.3em;

            div{
                font-weight: 500;
                font-size: 16px;
                width: 100%;
                padding: .3em;
                background-color: $grey5;
                margin-block: .2em;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .course_overview{

        .heading_cont .control_btn{
            width: 32%;
            margin-right: 0;
        }

        .progress_cont{
            margin-bottom: 0;            

            .top_bar{
                width: 100%;
            }
        }

        .course_content{
            flex-direction: column;

            div.content{
                width: 100%;
                // order: 2;
                .video_cont{
                    .player__preview{
                        img{
                            object-fit: contain;
                        }
                    }
                }
            }
            aside {
                width: 100%;
                border-block: 2px solid $secondaryColor;
                margin-bottom: 3em;
                // order: 1;
            }
        }
    }
}

@media screen and (max-width: 690px) {
    .course_overview{
        .course_info{
            padding: 1em;
        }
    }    
}

@media screen and (max-width: 650px) {
    .course_overview{
        .course_content{
            div.content{
                .tab_cont{
                    .overview_section{
                        .instructor_into{
                            .instructor_details{
                                .instructor_info{
                                    .first_layer{
                                        min-width: 60%;
                                        flex-direction: column;
                
                                        div{
                                            width: 100%;
                                            justify-content: space-evenly;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .course_overview{
        .heading_cont{
            flex-direction: column;

            .control_btn{
                margin: 0 auto;
                width: 80%;

                .edit_btn, .submit_btn{
                    padding: 0;
                    height: 100%;
                }
            }
        }
        .course_info{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .course_content{
            div.content{
                .tab_cont{
                    .overview_section{
                        .instructor_into{
                            .instructor_details{
                                flex-direction: column;
                                .instructor_info{
                                    .first_layer{
                                        min-width: 100%;                
                                        div{
                                            width: 100%;
                                            justify-content: space-evenly;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
}