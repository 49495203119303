#StudentReviewCards {

  background-color: #F2F2F2;
  border-radius: 25px;
  padding: 20px;
  margin-top: 0px;

  #cardTitle {
      display: grid;
      grid-template-columns:65px 1fr;
      grid-template-rows: repeat(2, 50%);

      #reviewerImage {
          grid-column: 1;
          grid-row:  span 2;
          width: 60px;
      }
      #reviewerName {
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          display: flex;
          align-items: center;
          letter-spacing: 0.06em;
          color: #000000;
          margin-bottom: 0px;
      }
  }

  #reviewText {
      margin-top: 5px;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.06em;
      color: #000000;
  }

  #cardFooter {
      margin-top: 5px;
      display: grid;
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: 1;

      #helpful {
          grid-column: 1;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: #4F4F4F;
      }
      #likesAndDislikes {
          grid-column: 2;
          display: grid;
          align-items: center;
          justify-content: space-evenly;
          #thumbsUp {
              grid-row: 1;
          }
          #thumbsUpCount {
              grid-row: 1;
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
              text-align: center;
              color: #4F4F4F;
          }
          #thumbsDown {
              grid-row: 1;
              
          }
          #thumbsDownCount {
              grid-row: 1;
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
              text-align: center;
              color: #4F4F4F;
          }
      }
      #reportLink {
          grid-column: 3;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          text-align: right;
          color: #4F4F4F;
      }
  }
}

//min-width: 481px:
@media only screen and (min-width: 481px) {
  #StudentReviewCards {
      display: grid;
      grid-template-columns: 80px 1fr;

      #cardTitle {
          grid-template-columns: 80px 1fr;
          grid-column: span 2;
          grid-row: 1;
      }
      #reviewText {
          grid-column: 2;
          grid-row: 2;
      }
      #cardFooter {
          grid-column: 2;
          grid-row: 3;
      }
  }
}