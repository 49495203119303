.Dashboard__MenuItem {
    display: flex;
    padding: 16px 0px 16px 14px;
    justify-content: flex-start;
    align-items: center;
    // margin-left: 2px;
    // padding-left: 14px;
    .Dashboard__MenuItem__title {
        margin: 0;
        padding: 0;
        padding-left: 1.2rem;
        font-weight: 400;
        font-size: 16px;
        color: black;
    }

    // @media (min-width: 992px) {
    //     // .Dashboard__MenuItem__title {
    //     //     display: none;
    //     // }
    //     .Dashboard__MenuItem__title__small {
    //         display: none;
    //     }
    // }
    // @media (max-width: 992px) {
    //     .Dashboard__MenuItem__title {
    //         display: none;
    //     }
    // }
}
.Dashboard__routeList {
    overflow-y: scroll;
    height: 70vh;
    // padding: 0;
    &::-webkit-scrollbar {
        width: 2px;
    }
}
.Dashboard__Logout {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    outline: none;
    background: transparent;
}
// .menu-close{
//     display: none;
// }

.current {
    background: linear-gradient(
        90deg,
        rgba(78, 147, 255, 0.1) 29.14%,
        rgba(78, 147, 255, 0) 100%
    );

    border-left: 4px solid #4e93ff;
    h4 {
        font-weight: 500 !important;
    }
}
