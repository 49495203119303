@import '../../../globalStyles/helpers/_variables';

#terms_page_layout{
    width: 100%;
    min-height: 100vh;
    background-color: #fff;

    .hero_cont{
        width: 100%;
        height: 37vh;
        border-radius: 10px;
        margin-bottom: 3em;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .content_cont{
        width: 85%;
        // height: 163vh;
        margin: 0 auto;
        display: flex;
        gap: 1em;
        margin-bottom: 1em;

        .sidebar{
            width: 20%;
            // height: 100vh;
            background-color: #fff;

            a{
                display: flex;
                width: 100%;
                height: 3em;
                text-decoration: none;
                font-size: 1.3em;
                color: black;
                background-color: #fff;
                font-weight: 600;
                border-radius: 10px;
                margin-bottom: 1em;
                align-items: center;
                padding-inline: 1em;
                box-shadow: .1em .1em 1em .1em rgba(128, 128, 128, 0.275);
                cursor: pointer;

                &.Active{
                    color: #fff;
                    background-color: $secondaryColor;

                    &:hover{
                        background-color: $secondaryColor;
                    }
                }

                &:hover{
                    background-color: #3a85ff2f;
                }
            }
        }

        .main_cont{
            width: 80%;
            height: 120vh;
            overflow-y: scroll;
            position: sticky;
            top: 2em;
        }
    }
}

@media (max-width: 1100px) {
    #terms_page_layout{
        .content_cont {
            width: 95%;
        }
    }
}

@media (max-width: 695px) {
    #terms_page_layout{

        .hero_cont{
            height: 20vh;
            margin-bottom: 1em;

            img{
                object-fit: cover;
            }
        }
        .content_cont {
            flex-direction: column;

            .sidebar{
                height: 10vh;
                display: flex;
                // background-color: red;
                width: 90%;
                gap: 1em;
                margin: 0 auto;
                align-items: center;

                a{
                    height: 80%;
                    padding: .1em;
                    text-align: center;
                    justify-content: center;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 2;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                }
            }

            .main_cont{
                width: 90%
            }
        }
    }
}