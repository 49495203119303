@import "../../../globalStyles/helpers/variables";

#instructor-dashboard {
    width: 100%;
    font-family: 'Roboto';

    #header {
        grid-area: head;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
            
    .hero{
       width: 100%;
        height: 300px;
        position: relative;
        
        .img__container{
            background: $grey5 url("./assets/instructor_dash.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            height: 100%;
            border-radius: $semilarge-radius;
            
            .hero__overlay{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: $semilarge-radius;
            }
            img{
                width: 100%;
                height: 100%;
                border-radius: inherit;
                object-fit: cover;
            }
            
            h3{
                position: absolute;
                top: 60%;
                color: $grey5 !important;
            }
        }
    }

    .course__cont{
        margin-top: 1em;

        .loader_cont{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .section__heading{
            display: flex;
            justify-content: space-between;
        }

        // .filter__section{
        //     width: 200px;
        //     height: 3em;
        //     padding-inline: 2em;
        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;
        //     box-shadow: .1em .1em .1em .1em $grey5;
        //     border-radius: 10px;
        //     margin-block: 1em;
        //     position: relative;

        //     // .ant-dropdown-menu{
        //     //     position: absolute;
        //     //     top: 0;
        //     //     left: 0;
        //     // }
        // }

        .card__cont{
            width: 100%;
            padding-inline: 3em;
            margin-block: 2em 1em;

            .empty_data{
                height: 10vh;
                font-size: $mediumHeadings;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .course_regulation .content__to__come{
        width: 100%;
        // height: 80vh;
        margin-bottom: 10vh;
        padding: 40px;
        background-color: $offWhite;
        border-radius: 10px;
        box-shadow: 0px 4.32px 4.32px 0px #0000001A;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;

        img{
            padding-bottom: 20px;
            width: 100%;
            cursor: pointer;
            transition: box-shadow 0.3s ease, transform 0.3s ease;
            &:hover {
                transform: scale(1.05); // Optional: Adds a slight zoom effect
            }
        }
    }
    @media screen and (max-width: 850px) {
        .course__cont .card__cont{
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            padding-inline: 1em;
            margin-block: 0;
        }
    }

    // remove these when readding right col
    // @media (min-width: 1000px) {
    //     #welcome-image img {
    //         max-width: 680px;
    //     }

    //     #courses-banner {
    //         max-width: 680px;
    //     }
    // }
    
    // @media (min-width: 1200px) {
    //     #welcome-image img {
    //         max-width: 900px;
    //     }

    //     #courses-banner {
    //         max-width: 880px;
    //     }
    // }

    // @media (min-width: 1400px) {
    //     #welcome-image img {
    //         max-width: 1100px;
    //     }

    //     #courses-banner {
    //         max-width: 1050px;
    //     }
    // }

    // @media (min-width: 1900px) {
    //     #welcome-image img {
    //         max-width: 1550px;
    //     }

    //     #courses-banner {
    //         max-width: 1550px;
    //     }
    // }

    // @media (min-width: 2500px) {
    //     #welcome-image img {
    //         max-width: 2200px;
    //     }

    //     #courses-banner {
    //         max-width: 2200px;
    //     }
    // }

    .ant-row {
        justify-content: center;
        .colLeft {
            min-width: 280px;

            #course-marketplace {
                #welcome-image {
                    display: flex;
                    img {
                        // uncomment when readding right col
                        // max-width: 1500px;
                        width: 100%;
                        opacity: 0.7;
                    }

                    #imageText {
                        position: absolute;
                        align-self: center;

                        p {
                            font-size: 28px;
                            color: #fff;
                            font-weight: 700;
                            margin-left: 5%;
                            white-space: nowrap;
                        }
                    }
                }
            }

            #instructorDash {
                border-radius: 25px;
                margin-bottom: 15px;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }

            #courses-banner {
                // uncomment when readdong right col
                // max-width: 1500px;
                width: 100%;

                #courses-title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .title {
                        font-size: 24px;
                        color: #3a86ff;
                        font-weight: 700;
                    }

                    #create-course-btn {
                        font-weight: 500;
                        color: $primaryColor;
                        border-radius: 8px;
                        background-color: #f0f0f0;
                        border: 2.5px solid $primaryColor;
                        padding: 5px 10px;
                    }
                }
                .course-slot {
                    // display: flex;
                    // flex-direction: row;
                    // flex-wrap: wrap;
                    margin: 0 20px;

                    .course-item {
                        // max-width: 50%;
                        // min-width: 280px;
                        .course-card {
                            margin: 10px 0px;
                            margin-left: 10%;
                            height: auto;
                            width: auto;
                            background-color: white;
                            .course-info {
                                display: flex;
                                margin-left: 1.25rem;
                                padding: 0.5rem 0;
                                height: 150px;
                                hr {
                                    margin: 5px 0;
                                }
                            }
                        }
                    }
                }

                .ant-carousel .slick-prev,
                .ant-carousel .slick-next {
                    color: unset;
                    font-size: 20px;
                }
                .ant-carousel .slick-prev:hover,
                .ant-carousel .slick-next:hover,
                .ant-carousel .slick-prev:focus,
                .ant-carousel .slick-next:focus {
                    color: unset;
                }
            }

            #analytics-banner {
                .title {
                    font-size: 24px;
                    color: #3a86ff;
                    font-weight: 700;
                }
                .card-cont {
                    display: flex !important;
                    flex-direction: row;
                    flex-wrap: wrap;
                    flex: 1 1 0px;
                    // uncomment when readdding right col
                    // max-width: 1500px;
                    width: 100%;
                    .stat-img {
                        display: flex;
                        max-width: 47%;
                        width: 100%;
                        min-width: 280px;
                        background-color: white;
                        border-radius: 20px;
                        margin: 10px 5px;
                        .card-image {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .colRight {
            flex: 1 0 auto;
            min-width: 280px;
            max-width: 700px;
            width: 100%;

            // #student-db-message {
            //     Link {
            //         font-size: 16px;
            //         font-weight: 700;
            //         background-color: none;
            //     }
            // }
            // #instructor-calendar {
            //     background-color: white;
            //     border-radius: 15px;
            //     padding: 10px;
            //     min-width: 300px;
            //     margin-bottom: 20px;
            // }
            #date {
                display: flex;
                justify-content: space-around;
                font-size: 32px;
                font-weight: 700;
                padding: 15px;
            }

            #instructor-tasks {
                background-color: white;
                border-radius: 15px;

                #tasksTitle {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    align-content: center;
                    padding: 15px;

                    #title {
                        font-size: 24px;
                        color: #3a86ff;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
