* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Handles basic responsiveness */
@media screen and (max-width: 1200px) {
    .container {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.container {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
}

section:last-of-type {
    margin: 0 auto;
}

// section:first-of-type {
//   margin-top: 90px;
// }

ol,
ul,
dl {
    margin-bottom: 0 !important;
}

p {
    margin-bottom: 0 !important;
}

button {
    border: none;
}

.btn {
    outline: none !important;
    box-shadow: none !important;
}

// section {
//   margin: 4rem 0;
// }

.ant-notification {
    display: flex;
    width: 50vh;
    align-items: center;
    justify-content: center;
    left: 40%;
    .ant-notification-notice {
        // box-shadow: -2px 0px 97px -26px rgba(0, 0, 0, 0.37) !important;
        padding: 12px 20px;
        border-radius: 6px !important;
    }
    .ant-notification-notice-close {
        visibility: hidden;
    }
}

::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e9e9e9b6;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8080809d;
    width: 8px;
}
