#search {
    width: 60%;
    margin: 0 auto;
    // height: 70%;
    .search-input {
        position: relative;
        width: 100%;
        input {
            width: 100%;
            // margin-top: 20px;
            padding: 0.5rem 0 0.5rem 0.5rem;
            border-radius: 5px;
            border: solid 2px rgb(126, 126, 255);
            &:focus {
                border: solid 2px rgb(178, 178, 253);
                outline: none;
            }
        }
    }
    .search-result {
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.089);
        width: 100%;
        max-height: 260px;
        overflow-y: auto;
        position: absolute;
        background-color: white;
        z-index: 10;
        border-radius: 10px;
         /* Custom scrollbar styles */
        // ::-webkit-scrollbar {
        //     width: 12px; /* Width of the vertical scrollbar */
        // }
        // ::-webkit-scrollbar-track {
        //     background: #f1f1f1; /* Track color */
        // }
        // ::-webkit-scrollbar-thumb {
        //     background: #888; /* Handle color */
        //     border-radius: 10px; /* Rounded corners */
        // }
        // ::-webkit-scrollbar-thumb:hover {
        //     background: #555; /* Handle color on hover */
        // }
        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1; /* Handle and track color */
        scroll-behavior: smooth;

        .search-course-div {
            border-bottom: solid 1px rgba(128, 128, 128, 0.418);
            border-left: solid 1px rgba(128, 128, 128, 0.418);
            border-right: solid 1px rgba(128, 128, 128, 0.418);
            padding: 0.5rem 1rem;
            &:hover {
                background-color: rgb(240, 240, 240);
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #search {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 425px) {
    #search {
        width: 100%;
        margin: 0 auto;
    }
}
