@import "../../../../../globalStyles/helpers/variables";

.activePlan_cont{
    padding: .5em 0;
    border-radius: 8px;

    &>h2{
        font-size: 2rem;
        font-weight: 500;
        color: $grey3;

        span{
          font-size: 1rem;
        }
    }

    .main_view{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-block: 1.8em;

        .start_date_cont, .renewal_date_cont{
            width: 30%;
            min-width: 60px;
            padding: .5em 1em;
            position: relative;
            border-radius: .6em;

            .date_label{
                display: block;
                background: $lightGrey;
                width: 45%;
                padding-inline: .3em;
                font-size: .9em;
                font-weight: 600;
                position: absolute;
                top: -11px;
                left: 7px;
            }
        }

        .start_date_cont{
            border: 1px solid $secondaryColor;
            
            .date_label{
                color: $secondaryColor;
            }
        }

        .renewal_date_cont{
            border: 1px solid $errorColor;

            .date_label{
                color: $errorColor;
            }
        }
    }

    .option_info{
      font-weight: 600;
    }

    #cancel_planBtn{
      width: 150px;
      margin: 0 auto;
      background-color: $secondaryColor;
      color: white;
      border: none;
      border-radius: 8px;
      padding: .5em 1em;
      cursor: pointer;
    }

    #cancelled_sub_info{
      font-weight: 600;
      font-size: 0.9em;

      span{
        color: $secondaryColor;
      }
    }
}
.ant-modal.activePlanCardModal{
  .ant-modal-content{
    .ant-modal-body{
      .card_cont{
        width: 100%;
        height: 100%;
        border-radius: 8px;
  
        h3{
          padding-top: .2em;
          font-size: 1.3em;
          font-weight: 500;
          color: $secondaryColor;
        }
  
        .main_view{
          border: 2px solid $secondaryColor;
          border-radius: 8px;
          padding: 1em;
  
          .main_info, .upgrade, .renewal{
            display: flex;
            justify-content: space-between;
  
            h2{
              font-weight: 700;
              font-size: 1.6em;
              color: $secondaryColor;
              text-decoration: underline;
  
              span{
                color: black;
                text-decoration: underline;
              }
            }
  
            .dates{
              width: 50%;
              text-align: right;
  
              p{
                font-weight: 500;
                font-size: 1em;
  
                .expire{
                  color: $errorColor;
                }
  
                .start{
                  color: $secondaryColor
                }
              }
            }
  
            &>p{
              width: 50%;
              font-size: 1em;
              text-align: left;
            }
          }
  
          .upgrade{
            margin-block: .6em;
          }
        }
  
        .cancel_btn{
          text-decoration: underline;
          background: none;
          font-size: 18px;
          font-weight: 700;
          color: $grey4;
          cursor: pointer;
          text-align: left;
        }
      }
    }
  }
}

.ChangeOfPlanFirstModal{
  .ant-modal-content{
    border-radius: 2em;
  
    .ant-modal-body{
  
      .title{
        font-size: 1.2em;
      }
  
      .product_cont{
        border: 2px solid $secondaryColor;
        text-align: left;
        position: relative;
        padding: .3em .2em;
        border-radius: 1.2em;
        margin: 1em 1.2em;
  
        h3{
          font-size: 1.2em;
          color: $secondaryColor;
          font-weight: 700;
          padding-left: .6em;
        }
  
        &>p{
          width: 40%;
          height: 2em;
          background-color: $secondaryColor;
          color: white;
          position: absolute;
          top: 0;
          right: 0;
          padding-top: .3em;
          border-top-right-radius: 1em;
          text-align: center;
          font-size: .8em;
          font-weight: 700;
        }
  
        ul{
          margin: .8em;
          text-align: left;
          list-style-type: none;
          padding-left: 0;
          line-height: 20px;
    
          li{
            padding: 0;
            margin-bottom: .5em;
            display: flex;
            align-items: flex-start;
  
            div{
              margin-right: 3px;
              width: 15px;
              height: 15px;
              align-items: center;
              
              img{
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            
            p{
              font-size: 14px;
            }
          }
        }
  
        .ant-btn {
          display: block;
          margin: 0 auto;
          width: 100px;
          padding: .2em .4em;
          border-radius: 8px;
          margin: 0 auto;
          font-size: 1em;
          font-weight: 500;
        }
      }
    }
  }
}

.CancelPlanSecondModal{
  .ant-modal-content{
    text-align: left;

    .ant-modal-body{
      padding: 1.3em;

      h2{
        text-align: center;
      }
    }
  }
}
