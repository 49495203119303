    .heading--{
        color: #0837E5;
        font-size: 1.3rem;
    }
    .filter-cont{
        width: 30%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
        font-weight: 500;
        cursor: pointer;
        p{font-size: .84rem;}
    }