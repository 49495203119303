@import '../../../../globalStyles/helpers/variables';
#Testimonials {
    padding-top: 6em;
    background-color: #f5f5f5;
    padding-bottom: 4em;

    // .Testimonial-wrap {
    //     height: 810px;
    //     width: 100%;
    //     background: white;
    //     padding-bottom: 100px;
    //     padding: 0 auto;
    // }

    .testimonial-section {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.02em;
        color: $secondaryColor;
        position: relative;
        // margin-top: 120px;

        .container-btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
            padding-block: 1em;
            z-index: 2;
    
            .prev-featured, .next-featured {
                position: absolute;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                top: calc((100% - 50px)/2);
                z-index: 20;
                padding: 3px 10px;
                border: 2px solid #3a86ff;
                border-radius: 25px;
            }
            
            .next-featured {
                right: 6%;
            }

            .prev-featured{
                left: 6%;
            }
    
            // .previous {
            //     position: relative;
            //     margin: -3px 2px 0px 0px;
            // }
    
            // .next {
            //     position: relative;
            //     margin: -3px 0px 0px 2px;
            // }

            .slide-content {
                width: 65%;
                height: 398px;
                border-radius: 20px;
                background-color: #f5f5f5;
                overflow-y: scroll;
                box-shadow: 1px 0.2px 5px 1px rgba(0, 0, 0, 0.2);

                .box-section {
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    // padding: 20px 5px;
                    // box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
                    border-radius: 20px;

                    .user-testimonial {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.3rem;
                        text-align: center;
                        letter-spacing: 0.06em;
                        color: #000000;
                        padding: 18px 50px;
                        margin-block: 30px;
                    }

                     h5 {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1rem;
                        line-height: 26px;
                        letter-spacing: 0.06em;
                        color: #4f4f4f;
                        margin-top: 2em;
                    }
                }
            }
        }

        .title {
            font-size: 40px;
            margin-bottom: 2em;
        }

        .img_cont{
            width: 200px;
            height: 200px;
            position: absolute;
            z-index: 1;

            &.top {
                top: 4em;
                left: 8em;
            }

            &.bottom{
                right: 8em;
                bottom: -4em;
            }

            div.img-cont{
                width: 200px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    // .user-testimonial::before {
    //     content: open-quote;
    //     font-style: normal;
    //     font-weight: bold;
    //     font-size: 45.7184px;
    //     line-height: 54px;
    //     letter-spacing: 0.05em;
    //     color: #ffcc00;
    // }
    // .user-testimonial::after {
    //     content: close-quote;
    //     font-style: normal;
    //     font-weight: bold;
    //     font-size: 45.7184px;
    //     // line-height: 54px;
    //     letter-spacing: 0.05em;
    //     color: #ffcc00;
    //     position: relative;
    //     top: 30px;
    //     left: 10px;
    // }

    .user-image-box {
        width: 266px;
        height: 60px;
        box-shadow: 3px 4.32px 4.32px rgba(0, 0, 0, 0.1);
        display: flex;
        background-color: rgb(255, 255, 255);
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        border-radius: 0 0 15px 15px;
    }
    .user-photo {
        margin-top: 80px;
        width: 71px;
        height: 71px;
    }
   

    @media (max-width: 377px) {
        .title {
            font-size: 36px;
        }

        .user-photo {
            margin-top: 40px;
            width: 45px;
            height: 45px;
        }

        .user-testimonial {
            font-size: 16px;

            padding: 18px 20px;
            margin-top: 30px;
        }
    }

    /*for mobile phone devices*/
    @media screen and (max-width: 480px) and (min-width: 376px) {
        .Testimonial-wrap {
            width: 100%;
            padding: 0 auto;
        }

        // .box-section {
        //     width: 100%;
        //     height: 360px;
        //     background: #ffffff;
        //     margin: 0 auto;
        //     padding: 20px 5px;
        //     box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
        //     border-radius: 20px;
        // }

        .text-header-section {
            height: 285px;
        }

        .title {
            font-size: 42px;
        }

        .slide-content {
            width: 80%;
            height: 388px;
            margin: 0 auto;
        }

        .text-header-section {
            width: 100%;
        }
        .user-image-box {
            width: 100%;
        }
        .user-testimonial {
            font-size: 15px;
            padding: 10px;
        }
        .user-photo {
            margin-top: 80px;
            width: 55px;
            height: 55px;
        }
    }

    /*for ipad and tablet devices*/
    @media screen and (max-width: 768px) and (min-width: 481px) {
        .testimonial-section {
            .img_cont{    
                &.top {
                    left: 0;
                }
                &.bottom{
                    right: 0;
                }
            }
        }
        .slide-content {
            width: 100%;
            margin: 0px auto;
        }
        .text-header-section {
            width: 80%;
            height: 250px;
            padding: 20px;
        }
        .user-image-box {
            width: 80%;
            height: 80px;
        }
        .title {
            margin-bottom: 0px;
        }
        .user-testimonial {
            font-size: 15px;
            padding: 10px;
        }
        .container-btn {
            .prev-featured {
                left: 3%;
            }
            .next-featured {
                right: 3%;
            }
        }
    }

    /*for small screen laptops*/
    @media screen and (max-width: 1024px) and (min-width: 769px) {
        .testimonial-section {
            .img_cont{    
                &.top {
                    left: 0;
                }
                &.bottom{
                    right: 0;
                }
            }
        }
        
        .slide-content {
            width: 100%;
            margin: 0px auto;
        }
        .text-header-section {
            width: 80%;
            height: 250px;
            padding: 20px;
        }
        .user-image-box {
            width: 80%;
            height: 90px;
        }
        .title {
            margin-bottom: 0px;
        }
        .user-testimonial {
            font-size: 16px;
            padding: 20px;
        }
        .container-btn {
            .prev-featured {
                left: 3%;
            }
            .next-featured {
                right: 3%;
            }
        }
    }

    /*for desktop and large screen laptops*/
    @media screen and (max-width: 1200px) and (min-width: 1025px) {
        .slide-content {
            // width: 90%;
            margin: 0px auto;
        }
        .text-header-section {
            width: 70%;
            height: 250px;
            padding: 20px;
        }

        .user-image-box {
            width: 70%;
            height: 90px;
        }
        .title {
            margin-bottom: 0px;
        }
        .user-testimonial {
            font-size: 19px;
            padding: 10px 50px;
        }
        .container-btn {
            .prev-featured {
                left: 7%;
            }
            .next-featured {
                right: 7%;
            }
        }
    }

    /*for large screen desktop and above*/
    @media screen and (min-width: 1201px) {
        .Testimonial-wrap {
            padding: 0 auto;
        }
        .slide-content {
            // width: 90%;
            margin: 0px auto;
        }
        .text-header-section {
            width: 78%;
            height: 250px;
            padding: 20px;
        }

        .user-image-box {
            width: 78%;
            height: 90px;
        }
        .title {
            margin-bottom: 0px;
        }
        .user-testimonial {
            font-size: 19px;
            padding: 10px 50px;
        }
    }
}
