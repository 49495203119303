@import '../../../../globalStyles/helpers/variables';


.terms_cont, .privacy_content_cont, .instructorTU_content_cont{
    padding-inline: 1em;
    h1{
        text-align: center;
        font-size: 2.5em;
        font-weight: 600;
    }

    .content{
        .data_modify{
            p{
                font-size: 1.3em;
                font-weight: 400;
                margin-bottom: 1em;
                
                &.date{
                    padding-bottom: .6em;
                }
            }
        }

        .readable{
            // margin-top: 2em;
            p{
                font-size: 1.3em;
                font-weight: 400;
                margin-block: 1em;
            }

            #content_cont{

                div{
                    .docs_heading{
                        font-size: 1.7em;
                        font-weight: 600;
                        margin-top: 1em;
                    }

                    &>p{
                        font-size: 1.3em;
                        font-weight: 400;
                    }

                    .subcontent{
                        h3{
                            font-size: 1.3em;
                            font-weight: 500;
                            word-spacing: .2em;
                            margin-block: 1em;
                        }

                        ul > li{
                            font-size: 1.2em;
                        }
                    }
                }

            }
        }
    }
}