.cart-btn {
  position: relative;
  display: inline-block;
}

/* Styling for the cart icon image */
.cart-btn img {
  width: 34px; /* Adjust as needed */
//   height: auto;
}

/* Styling for the cart count */
.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #FFCC00; /* Count background color */
  color: #fff; /* Count text color */
  border-radius: 50%;
  padding: 0px 4px;
  font-size: 12px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cart-btn img {
    width: 30px;
  }
  
  .cart-count {
    // font-size: 12px;
    // padding: 0px 4px;
  }
}