$green: rgba(70, 213, 179, 0.5);
$yellow: rgba(255, 204, 0, 0.3);
$red: rgba(235, 87, 87, 0.3);

#set-tasks {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    grid-area: right;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 90%;
    margin-left: 5%;

    .frontEndBtn,
    .uxBtn,
    .userTestingBtn {
        color: #000000;
        margin-right: 2%;
    }

    .frontEndBtn {
        width: 15%;
        background-color: $green;
        border-color: $green;
    }

    .uxBtn {
        width: 10%;
        background-color: $yellow;
        border-color: $yellow;
    }

    .userTestingBtn {
        width: 10%;
        background-color: $red;
        border-color: $red;
    }
}
