@import "../../../../globalStyles/helpers/variables";

#why {
  .container {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-bottom: 2em;
    h2 {
      color: $secondaryColor;
      text-align: center;
      font-size: 40px;
    }
    .content {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1em;
      align-items: center;
      justify-content: center;
      // height: 400px;
      .item {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 350px;
        overflow-y: scroll;
        // padding: 1em;
        // &:not(:first-of-type):not(:last-of-type) {
        //   margin: 0 2rem;
        // }
        p {
          text-align: center;
          max-width: 350px;
          color: black;
          font-size: 1em;
        }
        .img_cont{
          width: 70%;
          height: 50%;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #why {
    .container {
      padding-top: 1rem;
      padding-bottom: 1rem;
      h2 {
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      // .content {
      //   height: 100%;
      //   flex-direction: column;
      //   .item {
      //     height: 300px;
      //     &:not(:first-of-type):not(:last-of-type) {
      //       margin: 2rem 0;
      //     }
      //     p {
      //       // max-width: max-content;
      //     }
      //   }
      // }
    }
  }
}

@media screen and (max-width: 767px) {
  #why {
    .container {
      .content {
        .item {
          height: auto;
        }
      }
    }
  }
}