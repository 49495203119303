*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
main{
    width: 100%;
    // padding: 20px 60px;
    // font-family:'Courier New', Courier, monospace
}
.success-container {
    width: 100%;
    // margin: auto 0;
    padding: 30px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
    // box-shadow: 10px 10px;
    background: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    font-family:'Courier New', Courier, monospace
}
section.top--section {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 50px;
}
.left--content{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 50%;
    .left-info{
        width: 80%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 29.14%, rgba(78, 147, 255, 0) 100%);
        border-radius: 8px;
        padding: 15px;
        margin-top: 20px;
        text-align: center;
        // gap: 40px;
        // line-height: 120px;
        h4 {
            color: #3A86FF;
            margin-bottom: 20px;
            font-size: 20px;
        }
        p{
            padding-bottom: 40px;
        }

    }
}
.right--content{
    text-align: center;
    width: 50%;
    margin-top: 30px;
    h4{
        padding-bottom: 15px;
        font-size: 1.4rem;
    }
    p{
        padding-top: 30px;
    }
    span{
        font-weight: small;
        color: #9D9D9D;
        font-size: small;
        padding-top: 40px;
        display: block;
        text-align: start;
    }
    .course--list{
        font-size: large;
        text-transform: capitalize;
        font-style: italic;
        font-weight: 700;
    }
    .salutation{
        font-weight: 500;
        // display: block;
        text-align: start;
    }
}

section.bottom--section {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.bottom--main{
    display: flex;
    justify-content: space-between;
    // gap: 20px;
    padding-bottom: 18px;
    width: 60%;
}
.bottom-course-info{
    margin-right: 30px;
}
.start-learning-btn{
    background: #3A86FF;
    padding: 8px 25px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
}