@import "../../../../globalStyles/helpers/variables";

#cta-ins {
  background-color: $secondaryColor;
  height: 45vh;
  .container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      padding-bottom: 30px;
    }
    .info-text {
      color: white;
      text-align: center;
      padding-bottom: 30px;
      padding-left: 120px;
      padding-right: 120px;
    }
    .buttons {
      display: flex;
      justify-content: center;
      gap: 15px;
      width: 100%;
      margin-top: 2em;

      .faqs {
        width: 230px;
        height: 50px;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.045em;
        cursor: pointer;
        color: $lightGrey;
        background-color: $secondaryColor;
        border: 2px solid $lightGrey;
      }
      .instructor-handbook {
        width: 230px;
        height: 50px;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.045em;
        cursor: pointer;
        color: $lightGrey;
        background-color: $secondaryColor;
        border: 2px solid $lightGrey;
      }
    }
  }
}

@media only screen and (max-width: 884px) {
  #cta-ins {
    height: 45vh;
    .container {
      .title {
        padding-bottom: 20px;
      }
      .info-text {
        padding-bottom: 5px;
        padding-left: 70px;
        padding-right: 70px;
      }
      .buttons {
        .faqs {
          width: 180px;
        }
        .instructor-handbook {
          width: 180px;
        }
      }
    }
  }
}

@media only screen and (max-width: 601px) {
  #cta-ins {
    height: 55vh;
    padding-bottom: 20px;
    .container {
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      padding-top: 20px;
      padding-bottom: 20px;
      .title {
        padding-top: 20px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
      }
      .info-text {
        padding-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
      }
      .buttons {
        .faqs {
          width: 180px;
        }
        .instructor-handbook {
          width: 180px;
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  #cta-ins {
    height: 75vh;
    .container {
      padding-top: 20px;
      padding-bottom: 20px;
      .title {
        font-size: 25px;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  #cta-ins {
    height: 90vh;
    .container {
      .title {
        font-size: 20px;
      }

      .buttons {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
