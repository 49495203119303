@import "src/globalStyles/helpers/variables";

#heroInstructor {
  height: 65vh;
  .background {
    background-image: url(./assets/heroInstructorPhoto.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
  }
  .container-hero {
    height: 100%;
    // background-color: red;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    // padding-left: 400px;
    // padding-right: 400px;
    .title {
      color: white;
      font-size: 2em;
      padding-bottom: 10px;
      letter-spacing: 0.05em;
    }
    .info-text {
      color: white;
      // line-height: 28.74px;
      font-size: 1.3em;
      width: 70%;
      text-align: center;
    }
    .buttons {
      width: 100%;
      // background-color: yellow;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      gap: 20px;
      // flex-direction: row;
      // flex-wrap: wrap;
      // padding-top: 30px;
    }
    .get-started {
      color: white;
      background-color: $secondaryColor;
      cursor: pointer;
      height: 54px;
      width: 200px;
      border-radius: 9px;
      font-size: 16px;
      font-weight: 700;

      &:hover{
        color: #3A86FF;
        background-color: white;
        border: 2px solid #3A86FF;
      }
    }
    .instructor-handbook {
      color: $secondaryColor;
      background-color: white;
      cursor: pointer;
      height: 54px;
      width: 200px;
      border-radius: 9px;
      font-size: 16px;
      font-weight: 700;
      border: 2px solid #3A86FF;

      &:hover{
        background-color: #3A86FF;
        color: white;
      }
    }
  }
}

// @media only screen and (max-width: 1660px) {
//   #heroInstructor {
//     .container-hero {
//       padding-left: 300px;
//       padding-right: 300px;
//     }
//   }
// }

// @media only screen and (max-width: 1204px) {
//   #heroInstructor {
//     .container-hero {
//       padding-left: 250px;
//       padding-right: 250px;
//     }
//   }
// }

// @media only screen and (max-width: 943px) {
//   #heroInstructor {
//     .container-hero {
//       padding-left: 150px;
//       padding-right: 150px;
//     }
//   }
// }

// @media only screen and (max-width: 739px) {
//   #heroInstructor {
//     .container-hero {
//       padding-left: 80px;
//       padding-right: 80px;
//     }
//   }
// }

@media only screen and (max-width: 601px) {
  #heroInstructor {
    height: calc(100vh - 90px);
    .container-hero {
      padding-left: 40px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 40px;
      // .buttons {
      //   display: flex;
      //   // flex-direction: column;
      //   justify-content: center;
      //   align-items: center;
      // }
    }
  }
}

@media only screen and (max-width: 450px) {
  #heroInstructor{
    .container-hero {
      .buttons{
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
