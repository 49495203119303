.instruct--onboard--container{
    width: 100%;
    // margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon--steps{
        width: 65%;
        margin-top:18px;
    }
    .main--form--wrapper{
        width: 100%;

        .step1--container, .step2--container{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .header--text{
                font-size: 1.3rem;
                margin-top: 20px;
                text-align: center;
            }
        }

        .step1--container{
            .step1--form{
                background: white;
                width: 75%;
                border-radius: 8px;
                padding: 30px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .field-container{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    margin-top: 30px;
                }
                .label--field{
                    width: 35%;
                    font-weight: 700;
                }
                .label-icon{
                    margin-left: 8px;
                    .bio-icon{
                        color: #3A86FF;
                    }
                }
                input, textarea, select{
                    outline: none;
                    width: 60%;
                    border: 1px solid #d6d9e6;
                    height: 40px;
                    border-radius: 8px;
                    text-indent: 10px;
                    cursor: pointer;
                }
                textarea{
                    height: 120px;
                    &::placeholder{
                        text-indent: 15px;
                        padding-top: 20px;
                    }
                }
                .continue--btn {
                    background-color: #3A86FF;
                    margin-top: 30px;
                    padding: 8px 13px;
                    border-radius: 8px;
                    color: white;
                }
                // For REACT-TEL-Input library
                .react-tel-input{
                    width: 60%;
                    .special-label{
                        display: flex;
                    }
                }
            }
        }

        .step2--container{
            .step2--form{
                background: white;
                width: 75%;
                border-radius: 8px;
                padding: 30px;
                margin-top: 20px;
                position: relative;
                
                #termsCheckbox:checked::before{
                    content: '\2713'; /* Unicode character for a checkmark */
                }
                .checkbox--text{
                    cursor: pointer;
                }
                .back--btn, .submit--btn {
                    background-color: #3A86FF;
                    margin-top: 30px;
                    padding: 8px 13px;
                    border-radius: 8px;
                    color: white;
                }
            }
            .space{
                width: 100%;
                display: flex;
                margin-bottom: 30px;
                h4{
                    margin-right: 10px;
                    width: 35%;
                }
                p{
                    width: 60%;
                    word-break: break-word;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .instruct--onboard--container{
        .main--form--wrapper{

            .step1--container{
                .step1--form{

                    .label--field{
                        font-size: .74rem;
                    }
                    input, textarea{
                        font-size: .65rem;
                    }
                    textarea{
                        height: 3rem;
                    }
                    .continue--btn {
                        padding: 6px 10px;
                        font-size: .7rem;
                    }
                }
            }

        .step2--container{
            .step2--form{

                .checkbox--text{
                    font-size: .7rem;
                }
                .back--btn, .submit--btn {
                    margin-top: 30px;
                    padding: 6px 10px;
                    font-size: .7rem;
                }
            }
            .space{
                display: flex;
                flex-direction: column;
                h4{
                    width: 100%;
                    font-size: .75rem;
                }
                p{
                    width: 100%;
                    font-size: .64rem;
                }
            }
        }
        }
    }
}

@media screen and (max-width: 500px) {
    .instruct--onboard--container{
        .main--form--wrapper{
            .step1--container, .step2--container{
                .header--text{
                    font-size: .8rem;
                    margin-top: 17px;
                }
            }

            .step1--container{
                .step1--form{

                    .field-container{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    }
                    .label--field{
                        width: 100%;
                        font-size: 1rem;
                    }
                    input, textarea, select{
                        width: 100%;
                        font-size: 1rem;
                    }
                    // For REACT-TEL-Input library (mobile styling)
                    .react-tel-input{
                        width: 100%;
                    }

                }
            }
        }
    }
}