@import 'react-datepicker/dist/react-datepicker.css';

$primary: #3a86ff;

// h3 {
//     color: $primary !important;
// }

#courseDetails {
    margin: auto;
    .learn-content-container {
        width: 100%;
        display: flex;

        .learn-content {
            width: 80%;

            .learn-video {
                width: 100%;
                min-height: 360px;

                .learn-video-thumbnail {
                    width: 100%;
                    height: 100%;
                    background: url('./shared/assets/thumbnail.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    .learn-play-btn {
                        min-height: 360px;
                        display: flex;
                        align-items: flex-end;
                        img {
                            cursor: pointer;
                            padding: 2rem;
                            border-radius: 50%;
                        }
                    }
                }
            }

            .learn-tabs {
                padding-right: 1rem;
            }
        }

        .learn-sidebar {
            width: 20%;
            min-width: 300px;
            background-color: #fff;

            .learn-progress {
                padding: 1rem 1rem 0;
            }

            h4 {
                color: $primary;
                padding: 1rem 0;
                padding-left: 1rem;
                margin: 0;
            }

            .learn-collapse-container {
                height: 100vh;
                position: -webkit-sticky;
                position: sticky;
                overflow-y: auto;
                top: 0;
                position: relative;

                .padlock-icon {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }

                .learn-collapse-panel {
                    background-color: #fff;
                    border-top: 1px solid lightgrey;

                        .ant-collapse-extra {
                            padding-right: 15px;
                        }                    
                
                    .learn-collapse-lesson {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        padding: 0.5rem 3px;
                        background-color: white;
                        cursor: pointer;
                        &:hover {
                            background-color: #3a85ff10;
                        }
                        .learn-collapse-lesson-info {
                            display: flex;

                            p {
                                font-size: 14px;
                                color: #505050;
                                margin-right: 7px;
                                margin-top: 1px;
                            }

                            .lesson-bg-not-ticked {
                                position: relative;
                                width: 20px;
                                height: 20px;
                                margin-left: 7px;
                                border-radius: 25px;
                                border: 1px solid rgb(201, 201, 201);
                                cursor: pointer;
                                transition: 0.2s ease;
                                z-index: 5;
                            }

                            .lesson-bg-ticked {
                                position: relative;
                                width: 20px;
                                height: 20px;
                                margin-left: 7px;
                                border-radius: 25px;
                                border: 1px solid rgb(201, 201, 201);
                                cursor: pointer;
                                background-color: rgb(74, 88, 207);
                                transition: 0.2s ease;
                                z-index: 5;
                            }

                            .ticked-icon-lesson {
                                position: absolute;
                                width: 16px;
                                height: 16px;
                                // background-color: black;
                                margin-top: 2px;
                                right: 18px;
                                z-index: 9;
                            }

                            // .lesson-checkbox {
                            //     opacity: 100%;
                            //     transition: 0.2s ease;
                            //     right: 2px;
                            //     z-index: 10;
                            // }

                            .check-container {
                                padding-left: -20px;
                            }

                            input[type='checkbox'] {
                                opacity: 0%;
                                transition: unset;
                                right: 0px;
                                z-index: 10;
                            }

                            //   input[type="checkbox"]:checked {
                            //     background-color: #0042ac;
                            //     transition: 0.2 ease;
                            //   }

                            //   input[type="checkbox"]:hover {
                            //     background-color: rgb(157, 165, 241);
                            //     transition: unset;
                            //   }
                              
                            // .lesson-checkbox {
                            //     display: flex;
                            //     justify-content: flex-start;
                            //     align-items: center;
                            //     user-select: none;
                            //     flex-direction: row-reverse;
                            //     width: 60px;
                            // }

                            // .lesson-checkbox label {
                            //     // font-size: 26px;
                            //     color: #4D4D4D;
                            //     position: absolute;
                            //     z-index: 10;
                            //     padding-right: 25px;
                            //     cursor: pointer;
                            // }

                            // .lesson-checkbox input {
                            //     opacity: 0;
                            //     visibility: hidden;
                            //     position: absolute;
                            // }

                            // .lesson-checkbox input:checked~.check {
                            //     border-color: $primary;
                            //     box-shadow: 0px 0px 0px 15px $primary inset;
                            // }

                            // .lesson-checkbox input:checked~.check::after {
                            //     opacity: 1;
                            //     transform: scale(1);
                            // }

                            // .lesson-checkbox .check {
                            //     width: 20px;
                            //     height: 20px;
                            //     display: flex;
                            //     justify-content: center;
                            //     align-items: center;
                            //     position: relative;
                            //     border-radius: 100px;
                            //     background-color: #FFF;
                            //     border: 2px solid #828282;
                            //     box-shadow: 0px 0px 0px 0px #828282 inset;
                            //     transition: all 0.15s cubic-bezier(0, 1.05, 0.72, 1.07);
                            // }

                            // .lesson-checkbox .check::after {
                            //     content: '';
                            //     width: 100%;
                            //     height: 100%;
                            //     opacity: 0;
                            //     z-index: 4;
                            //     position: absolute;
                            //     transform: scale(0);
                            //     background-size: 50%;
                            //     background-image: url("./shared/assets/checkmark.svg");
                            //     background-repeat: no-repeat;
                            //     background-position: center;
                            //     transition-delay: 0.2s !important;
                            //     transition: all 0.25s cubic-bezier(0, 1.05, 0.72, 1.07);
                            // }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .learn-content-container {
        flex-direction: column;
        padding: 0;
    }

    .learn-content {
        width: 100% !important;
        margin: auto;
    }

    .learn-tabs {
        padding-right: 0 !important;
    }

    .learn-sidebar {
        width: 100% !important;
        margin: auto;
    }

    .learn-collapse-container {
        height: 100% !important;
    }
}

// Tasks Tab - Start
.task-card-container {
    background-color: white;
    width: 100%;
    max-width: 94%;
    margin: 15px 15px 25px 15px;
    border-radius: 0.5rem;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 35px;
}

.task-checkmark {
    position: relative;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border: 0.2px solid rgb(192, 192, 192);
    max-width: 30px;
    max-height: 30px;
    width: 30px;
    height: 30px;
    margin: -40px 0 30px auto;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}

.ticked {
    background-color: rgb(5, 212, 126);
    transition: all 0.3s ease-in-out;
}

.task-card-title {
    display: flex;
    font-size: 24px;
    line-height: 15px;
    color: $primary;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
}

.task-card-body {
    margin-right: 40px;
    margin-bottom: 25px;
}

.task-card-btn {
    background-color: white;
    color: $primary;
    border: 2px solid $primary;
    border-radius: 0.5rem;
    margin-right: 10px;
    margin-top: 5px;
    font-weight: 600;
    font-size: 16px;
    max-width: 160px;
    max-height: 55px;
    width: 100%;
    height: 55px;
    cursor: pointer;
}

.toggle-display {
    display: none;
}

.task-tick-icon {
    position: absolute;
    width: 25px;
    height: auto;
    top: 5%;
    right: 5%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 10;
}

.toggle-display-tick {
    display: none;
}

.task-mobile-btn {
    display: none;
}

//modal
body.active-modal {
    overflow-y: hidden;
}

.no-sub {
    display: flex;
    justify-content: center;
    font-size: 22px;
}

.modal-text {
    padding: 20px 50px 40px 50px;
    text-align: center;
}

.modal-btns {
    display: flex;
    justify-content: center;
    gap: 0 18px;
    margin: 0 auto;
}

.modal-btn {
    width: 200px;
    padding: 12px 30px;
    border-radius: 10px;
}

.btn-ok-modal {
    background-color: #3A86FF;
    color: white;
    font-weight: 500;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #206eec;
    }
}

.btn-no-thanks {
    background-color: transparent;
}

.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(49, 49, 49, 0.8);
    z-index: 999;
}

.modal-content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 0px 0px;
    border-radius: 20px;
    max-width: 600px;
    min-width: 300px;
}

.modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.close-modal {
    margin-left: auto;
    padding: 5px 10px;
    background-color: #d10000;
    border-radius: 25px;
    color: white;
}

.close-modal:hover {
    background-color: rgb(233, 0, 0);
}

.chevron {
    width: 10px;
    height: auto;
    cursor: pointer;
}

.go-tasks {
    padding-left: 5px;
    font-weight: 500;
    cursor: pointer;
}

.modal-body {
    display: flex;
    margin-top: 50px;
    margin-bottom: 30px;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.modal-new-task {
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
}

.modal-input-task {
    border-bottom: 1px solid black;
    background: white;
    width: 400px;
    margin-top: 15px;
    outline: none;
    margin-bottom: 70px;
}

.modal-new-task {
    display: flex;
}

.enter-date-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.date-picker {
    width: 400px;
    border-bottom: 1px solid black;
    outline: none;
    margin-top: 15px;
}

.calendarIcon {
    width: 20px;
    height: auto;
    position: absolute;
    top: 30%;
    right: 0;
    cursor: pointer;
}

.modal-colour {
    font-weight: 500;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
}

.colours-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 20px;
}

.ball-colour {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.light-blue {
    background-color: #3a86ff;
    color: #3a86ff;
    cursor: pointer;
}

.dark-blue {
    background-color: #0042ac;
    color: #0042ac;
    cursor: pointer;
}

.green {
    background-color: #00ee9f;
    color: #00ee9f;
    cursor: pointer;
}

.yellow {
    background-color: #ffee00;
    color: #ffee00;
    cursor: pointer;
}

.red {
    background-color: #d32000;
    color: #d32000;
    cursor: pointer;
}

.pick-colour {
    position: relative;
    border: 1px solid $primary;
    color: white;
    cursor: pointer;
}

.pick-color-plus {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 4px;
    left: 5px;
}

.modal-create-task {
    width: 150px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding: 15px 10px 15px 10px;
    border: 3px solid $primary;
    background-color: white;
    font-weight: 500;
    color: $primary;
    border-radius: 15px;
    transition: 0.2s ease;
}

.modal-create-task:hover {
    background-color: $primary;
    color: white;
}

// Tablet
@media (max-width: 768px) {
    .task-card-title {
        display: inline;
        font-size: 20px;
        padding-right: 5px;
    }

    .task-checkmark {
        margin-top: -30px;
    }

    .task-card-btn {
        width: 46%;
        height: 50px;
        font-size: 14px;
    }

    .toggle-display {
        display: inline;
    }
}

// Mobile
@media (max-width: 480px) {
    .task-card-body {
        text-align: center;
        margin: 90px 0px 25px 0px;
    }

    .task-card-container {
        width: 100%;
        max-width: 94%;
        margin: 15px 25px 25px 12px;
    }

    .task-btn-group {
        display: flex;
        justify-content: center;
    }

    .task-mobile-btn {
        display: block;
        background-color: white;
        color: $primary;
        border: 2px solid $primary;
        border-radius: 0.5rem;
        max-height: 55px;
        height: 45px;
        width: 46%;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
    }

    .task-card-btn {
        display: none;
    }

    .task-card-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
        margin-bottom: 10px;
    }

    .task-checkmark {
        margin: -125px auto 110px auto;
    }

    .task-card-btn {
        margin: 5px;
    }

    .toggle-display {
        display: inline;
    }

    .modal-content {
        max-width: 90%;
    }

    .modal-body {
        max-width: 90%;
    }

    .modal-input-task {
        max-width: 100%;
    }

    .enter-date-container {
        max-width: 100%;
    }

    .date-picker {
        max-width: 200px;
    }
}

@media (max-width: 440px) { 
    .modal-text {
        padding: 20px 30px 40px 30px;
    }
    
    .modal-btn {
        padding: 12px 12px;
    }
}

@media (max-width: 365px) { 
    .modal-text {
        padding: 20px 20px 40px 20px;
    }
    
    .modal-btn {
        padding: 12px 8px;
        font-size: 12px;
    }
}

// Small Mobile
@media (max-width: 370px) {
    .task-card-title {
        font-size: 16px;
        text-align: center;
    }

    .task-card-body p {
        font-size: 12px;
        transition: all 0.3s ease-in-out;
    }

    .task-mobile-btn {
        max-height: 55px;
        height: 35px;
        width: 46%;
        font-size: 11px;
        font-weight: 400;
    }

    .task-checkmark {
        max-width: 27px;
        max-height: 27px;
        width: 27px;
        height: 27px;
    }

    .task-tick-icon {
        transform: scale(0.8);
        top: 4%;
        right: 0%;
    }

    .colours-container {
        margin-left: -13px;
    }
}
// Tasks Tab - End

// Announcements Tab Start
.announcements-card {
    background-color: white;
    width: 100%;
    max-width: 94%;
    margin: 15px 50px 25px 25px;
    border-radius: 0.5rem;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
}

.announcement-card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.announcements-date {
    margin-left: auto;
    padding-right: 45px;
    color: #828282;
    font-size: 15px;
    margin-top: 20px;
}

.announcement-body {
    padding: 20px 65px 50px 65px;
}

.announcements-instructor-name {
    font-weight: 700;
    font-size: 15px;
    margin-left: 10px;
    margin-top: 20px;
}

.announcements-card-avatar {
    width: 40px;
    height: auto;
    cursor: pointer;
    margin-left: 20px;
    margin-top: 20px;
}

//Tablet / to avoid collisions
@media (max-width: 670px) {
    .announcement-card-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .announcements-card {
        width: 100%;
        max-width: 90%;
    }

    .announcements-card-avatar {
        width: 50px;
        height: auto;
    }

    .announcements-date {
        padding-left: 60px;
        margin-left: auto;
        margin-right: auto;
        color: #828282;
        font-size: 15px;
        margin-top: 10px;
    }

    .announcement-body {
        text-align: center;
    }

    .announcement-body {
        padding: 25px 35px 50px 35px;
    }
}

@media (max-width: 450px) {
    .announcements-card {
        width: 100%;
        max-width: 83%;
    }
}
// Announcements Tab End

// Transcript Tab Start
.transcript-container {
    width: 100%;
    max-width: 99%;
    transition: all 0.3s ease-in-out;
}

.transcript-section {
    background-color: rgb(231, 231, 231);
    color: black;
    padding: 20px;
}

.transcript-intro {
    font-weight: 700;
    padding-bottom: 20px;
}

.transcript-paragraph {
    padding-bottom: 20px;
}

.blue {
    background-color: #deebff;
}

.transcript-chapter {
    font-weight: 500;
    padding-bottom: 20px;
}
// Transcript Tab End
