@import '../../../../globalStyles/helpers/variables';
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

#adminheader, #learnerHeader {
    background-color: $contentBgc;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 2em;
    font-weight: 700;
    color: $grey2;
    font-family: $font-robotto;
    // box-shadow: 0 2px 0 1px $lightGrey;
    .first_Section{
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }

    .detail{
        display: flex;
        align-items: center;
        // min-width: 150px;

        .Header__detail{
            margin-inline: 1em;

            #header{
                margin: 0;
                color: $secondaryColor;
                font-size: 2.3rem;
            }

            p{
                margin: 0;
            }
        }

        .Header__CTA p{
            color: $secondaryColor;
        }
    }

    .Header__CTA{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 300px;
        // height: 100%;
        justify-content: space-between;
        position: relative;

        .icon__cont{
            width: 55%;
            padding: 1em;
            display: flex;
            justify-content: space-around;
            position: relative;
            cursor: pointer;
        }

        .main__CTA{
            display: flex;
            justify-content: center;

            a{
                color: $secondaryColor;
                text-decoration: none;

                .arrow{
                    padding-left: .6em;
                }
            }
        }
    }

   .logo {
        height: 32px;
        margin-left: 1rem;
    }

    @media (min-width: 992px) {
        .menu {
            display: none;
        }
    }
    @media (max-width: 992px) {
        .menu_collapse {
            display: none;
        }
    }

}

#adminheader{
    padding-top: 1.2em;

    .Header__CTA{
        padding-top: 1.2em;

        .icon__cont{
            width: 30px;
            height: 30px;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.ant-drawer-body {
    padding: 0px !important;
}

@media (min-width: 992px) {
    .menu_drawer {
        display: none !important;
    }
}
@media screen and (max-width: 650px) {
    #adminheader{
        font-size: 8px;
        // height: 5vh;
        // .first_Section{
        //     .hamburger-react{

        //     }
        // }
        .Profile__cont img{
            width: 30px;
        }
        .detail{
            .Header__detail p{
                font-size: 8px;
            }
        }

        .Header__CTA{
            .icon__cont img{
                width: 18px;
            }
        }
    } 
}