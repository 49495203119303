#whatYouWillLearn-section {
  margin: 20px 0px;
  padding: 40px 0px;

  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 2fr;

  background: #F8F8F8;


  #content {
    grid-column: 2;
    border-radius: 10px;

    box-shadow: 0px 2.65929px 2.65929px rgba(0, 0, 0, 0.1);


      h1 {
        color: #03023B;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.02em;
        background-color: white;
        margin: 0px;
        text-align: center;
        padding: 20px 0px 5px 0px ;
        border-radius: 10px 10px 0px 0px;
    }
    

    
    .features-list {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 2fr;
      background: #ffffff;
      margin: 0; 
      padding: 10px 0px; 
      border-radius: 0px 0px 10px 10px;
    }
    #column1 {
      grid-row: 1;

      #readMore {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        /* or 165% */

        text-align: center;
        letter-spacing: 0.06em;

        /* Blue Primary */

        color: #0837E5;
        cursor: pointer;
      }
    }
    #column2Toggle {
      grid-row: 2;
    }
    #column2 {
      grid-row: 2;
      display: none;
    }
        
    ul li {
      font-family: Roboto;
      color: #000000;
      font-style: normal;
      font-weight: normal;

      margin: 0px 3em;
      position: relative;

      font-size: 16px;
      line-height: 26px;
      /* or 165% */
      letter-spacing: 0.06em;
 
    }
        
    ul li::before { 
      width: 20px;
      height: 20px;
    }
      
    .features-list li::marker {
      color: #46D5B3; 
      font-size: 2em;
    }
    

  }
  
}
//min-width: 481px:
@media only screen and (min-width: 481px) {
  #whatYouWillLearn-section {
    #content {
      h1 {
        text-align: start;
        padding-left: 2em;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        /* or 131% */

        letter-spacing: 0.02em;
      }

      ul li {
  
        margin: 0px 4em;
        position: relative;
  
        font-size: 16px;
        line-height: 26px;
        /* or 165% */
        letter-spacing: 0.06em;
   
      }
      .features-list {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 1fr;
      }

      #readMore {
        display: none;
      }
      #column1 {
        grid-column: 1;
        grid-row: 1;
      }
      #column2Toggle {
        grid-row: 2;
        display: none;
      }
      #column2 {
        grid-column: 2;
        grid-row: 1;
        display: block;
      }
    }

  }
}
//min-width: 769px:
@media only screen and (min-width: 769px) {
  #whatYouWillLearn-section {
    #content {
      h1 {
        font-size: 24px;
        line-height: 29px;
        /* or 121% */
        letter-spacing: 0.02em;
      }
    }

  }
}

//min-width: 1024px:
@media only screen and (min-width: 1024px) {
  #whatYouWillLearn-section {
    grid-template-columns: 15vw 2fr 15vw;
  }
}
  
