@import "../../globalStyles/helpers/variables";

#course_filter{
    width: 15em;
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    padding-inline: 1em;
    border-radius: 10px;
    box-shadow: -1px 2px 28px -3px #949269;
    -webkit-box-shadow: -1px 2px 28px -3px #949269;
    position: relative;
    user-select: none;

    .filter_title{
        width: fit-content;
        height: 100%;

        p{
            width: 100%;
            height: 100%;
            font-size: 1.3em;
            font-weight: 500;
            display: flex;
            align-items: center;
        }
    }

    .filter_cta{
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .filter_menu{
        width: 200px;
        height: 100px;
        position: absolute;
        left: .4em;
        top: 2.8em;
        background-color: white;
        padding: .5em .3em;
        z-index: 99;

        .ant-checkbox-group{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            input{
                width: 100%;
                height: 100%;
            }

            label{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}