@import '../../../../globalStyles/helpers/variables';
.course_cont{
    width: 100%;
    .mimicked_errorMessage{
        width: 100%;
        height: 10vh;
        text-align: center;
        font-size: 1em;
        color: $errorColor;
        // background-color: black;
        padding-block: 1em;
        margin-bottom: -2em;
    }

    .courses-container {
        width: 80%;
        margin: 30px auto;
        display: flex;
        gap: 2em;
        flex-wrap: wrap;
        justify-content: space-evenly;
        // align-items: center;
        // grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        // // grid-template-columns: repeat(4, 270px);
        // // margin: 30px 0;
        // padding-inline: 2em;
    }
}

// .popular-container {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//     gap: 1.5em 2em;
//     margin: 30px 0;
// }

// .trending-container {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//     gap: 1em 2em;
//     margin: 30px 0;
// }

// @media (min-width: 1910px) {
//     .top-picks-container {
//         display: grid;
//         grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//         gap: 20px 100px;
//         margin: 20px 0px 30px 0px;
//     }

//     // .top-picks-title {
//     //     font-size: 28px;
//     //     display: flex;
//     //     justify-content: center;
//     //     font-weight: 800;
//     //     margin-bottom: 20px;
//     // }
// }

// @media (max-width: 1935px) {
//     .top-picks-container{
//         gap: 5em;
//     }
//     .cards-section {
//         margin: 0 auto;
//         margin-bottom: 20px;
//     }
// }

// @media (max-width: 1050px) {
//     .cards-section {
//         // margin-left: 8%;
//         // margin-right: 8%;
//         margin-bottom: 20px;
//     }
// }

// @media (max-width: 1024px) {
//     .cards-section {
//         transform: scale(0.9);
//         margin: -10px -20px 20px -20px;
//     }
// }

// @media (max-width: 992px) {
//     .cards-section {
//         transform: scale(1);
//         // margin-left: 8%;
//         // margin-right: 8%;
//         margin-bottom: 20px;
//         margin-top: 40px;
//     }
// }

// @media (max-width: 727px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .cards-section {
//         margin-left: 15%;
//         margin-right: 15%;
//         margin-bottom: 20px;
//     }
// }

// @media (max-width: 630px) {
//     .courses-container{
//         display: flex;
//         flex-wrap: wrap;
//         gap: 1em;
//         justify-content: space-evenly;
//     }
// }

// @media (max-width: 432px) {
//     #course-section {
//         .courses-section-title{
//             .our-courses-info{
//                 width: 90%;
//             }
//         }
//     }
// }

// @media (max-width: 425px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .cards-section {
//         margin-left: 30px;
//     }
// }

// @media (max-width: 400px) {
//     .cards-section {
//         margin-left: 18px;
//     }
// }

// @media (max-width: 375px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .cards-section {
//         margin-left: 10px;
//     }
// }

// @media (max-width: 350px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }

//     .cards-section {
//         margin-left: -7px;
//     }
// }

// $primary: #3a86ff;

#course-section {
    background-color: #f5f5f5;
    padding: 3rem 0 0 0;

    .courses-section-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-top: 30px;
        // margin-bottom: 50px;
    }

    .our-courses-title {
        color: #3a86ff;
        margin-bottom: 0px;
    }

    .our-courses-info {
        color: #3a86ff;
        font-size: 20px;
        text-align: center;
        margin: 0px 70px;
    }

    .view-all-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .view-all-btn {
        padding: 10px 30px;
        background-color: #3a86ff;
        margin-top: 10px;
        margin-bottom: 4rem;
        border-radius: 10px;
        color: white;
        font-weight: 500;
        font-size: 18px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: #196bf0;
            box-shadow: .1em .1em 1em .1em rgb(42, 59, 77);
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 40px 45px;
        margin: 20px 200px 30px 200px;
    }
}
//     .course-container {
//         // display: grid;
//         // grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//         // gap: 20px 20px;
//         // margin: 20px 0px 30px 0px;

//         border-radius: 35px 35px 20px 20px;
//         background-color: white;
//         box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
//         cursor: pointer;
//         transition: 0.3s ease;
//     }

//     .course-title {
//         color: black;
//         font-size: 19px;
//         font-weight: 600;
//         margin: 25px 10px 10px 25px;
//     }

//     .course-container:hover {
//         .course-img {
//             opacity: 0.9;
//         }
//         transform: translateY(-8px);
//     }

//     .stu-cards-container {
//         display: flex;
//         gap: 1%;
//     }

//     .stu-card-title {
//         display: flex;
//     }

//     .within-card {
//         display: flex;
//         align-items: center;
//     }

//     .difficulty {
//         width: 20px;
//         height: auto;
//         margin-left: 25px;
//         margin-right: 5px;
//         text-align: center;
//     }

//     .info-divider {
//         font-weight: 300;
//         color: rgb(179, 179, 179);
//         margin-right: 5px;
//         text-align: center;
//     }

//     .course-numeric-rating {
//         color: rgb(0, 238, 159);
//         font-weight: 600;
//         text-align: center;
//     }

//     .course-stars-rating {
//         width: 100px;
//         height: auto;
//         text-align: center;
//     }

//     .course-number-of-ratings {
//         text-align: center;
//         margin-right: 25px;
//         padding-top: -2px;
//         font-size: 13px;
//     }

//     .course-author {
//         margin-left: 25px;
//         padding-bottom: 25px;
//         font-size: 13px;
//         color: grey;
//     }

//     .course-img-con {
//         width: 100%;
//         height: 160px;
//         border-radius: 25px 25px 0 0;
//         background-color: black;
//     }

//     .course-img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         border-radius: 25px 25px 0 0;
//         opacity: 0.6;
//         transition: all 0.3s ease-in-out;
//     }
// }

// @media (max-width: 2800px) {
//     #course-section {
//         .grid-container {
//             gap: 40px 40px;
//             margin: 20px 250px 30px 250px;
//         }
//     }
// }

// @media (max-width: 1800px) {
//     #course-section {
//         .grid-container {
//             gap: 40px 40px;
//             margin: 20px 170px 30px 170px;
//         }
//     }
// }

// @media (max-width: 1620px) {
//     #course-section {
//         .grid-container {
//             gap: 30px 25px;
//             margin: 20px 140px 30px 140px;
//         }
//     }
// }

// @media (max-width: 1440px) {
//     #course-section {
//         .grid-container {
//             gap: 30px 25px;
//             margin: 20px 60px 30px 60px;
//         }
//     }
// }

// @media (max-width: 1270px) {
//     #course-section {
//         .grid-container {
//             gap: 30px 20px;
//             margin: 20px 10px 30px 10px;
//             transform: scale(0.9);
//         }

//         .view-all-btn-container {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transform: scale(0.9);
//             margin-top: -40px;
//         }

//         .courses-section-title {
//             margin-bottom: 20px;
//         }
//     }
// }

// @media (max-width: 1120px) {
//     #course-section {
//         .grid-container {
//             gap: 30px 20px;
//             margin: 20px -60px 30px -60px;
//             transform: scale(0.8);
//         }

//         .view-all-btn-container {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transform: scale(0.8);
//             margin-top: -80px;
//         }

//         .courses-section-title {
//             margin-bottom: -30px;
//         }
//     }
// }

// @media (max-width: 985px) {
//     #course-section {
//         .grid-container {
//             gap: 30px 10px;
//             margin: 20px -80px 30px -80px;
//             transform: scale(0.8);
//         }

//         .view-all-btn-container {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transform: scale(0.8);
//             margin-top: -90px;
//         }

//         .courses-section-title {
//             margin-bottom: -30px;
//         }
//     }
// }

// @media (max-width: 840px) {
//     #course-section {
//         .grid-container {
//             gap: 30px 10px;
//             margin: -50px -120px 30px -120px;
//             transform: scale(0.7);
//         }

//         .view-all-btn-container {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transform: scale(0.7);
//             margin-top: -120px;
//         }

//         .courses-section-title {
//             margin-bottom: -30px;
//         }
//     }
// }

// @media (max-width: 780px) {
//     #course-section {
//         .grid-container {
//             display: grid;
//             grid-template-columns: 1fr 1fr;
//             gap: 40px 35px;
//             margin: -130px -20px 30px -20px;
//         }

//         .view-all-btn-container {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transform: scale(0.7);
//             margin-top: -200px;
//         }

//         .courses-section-title {
//             margin-bottom: -30px;
//         }
//     }
// }

// @media (max-width: 530px) {
//     #course-section {
//         .grid-container {
//             display: grid;
//             grid-template-columns: 1fr 1fr;
//             gap: 40px 35px;
//             margin: -130px -50px 30px -50px;
//         }

//         .view-all-btn-container {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transform: scale(0.7);
//             margin-top: -200px;
//         }

//         .courses-section-title {
//             margin-bottom: -30px;
//         }
//     }
// }

// @media (max-width: 430px) {
//     #course-section {
//         .grid-container {
//             display: grid;
//             grid-template-columns: 1fr;
//             gap: 40px 35px;
//             margin: -330px 10px 30px 10px;
//         }

//         .view-all-btn-container {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transform: scale(0.7);
//             margin-top: -400px;
//         }

//         .our-courses-info {
//             color: #3a86ff;
//             font-size: 16px;
//             text-align: center;
//             margin: 0px 70px;
//         }
//     }
// }

// // .container {
// //     h1 {
// //         color: $secondaryColor;
// //         text-align: center;
// //         margin: 2rem 0;
// //     }
// //     .category-container {
// //         .category-items {
// //             display: grid;
// //             grid-template-columns: repeat(auto-fill, 280px);
// //             gap: 25px 25px;
// //             grid-auto-flow: row;
// //             // grid-template-areas: ". . . .";
// //             justify-items: center;
// //             justify-content: center;
// //             .category-item {
// //                 height: 280px;
// //                 width: 280px;
// //                 display: flex;
// //                 align-items: center;
// //                 justify-content: center;
// //                 border-radius: 16px;
// //                 filter: drop-shadow(0px 3.5px 3.5px rgba(0, 0, 0, 0.1));
// //                 span {
// //                     color: white;
// //                     font-size: 22px;
// //                     font-weight: bold;
// //                     filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
// //                 }
// //             }
// //         }
// //     }
// // }
