#institutional-course-page {
    .loader_cont{
        padding-block: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .institutional-courses-container{
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        padding-inline: 1em;
    }
}