@import '../../../../../globalStyles/helpers/variables';

.review_form_cont{
    background-color: $offWhite;
    border-radius: 8px;
    padding: .5em 1.5em;
    margin-bottom: 1em;
    box-shadow: 0px 4.320000648498535px 4.320000648498535px 0px #0000001A;

    label{
        font-size: 1em;
        font-weight: 500;
    }

    .host_two{
        display: flex;
        // height: auto;

        .formm{
            width: 50%;
            margin-right: 1em;
            
            textarea.ant-input{
                width: 100%;
                height: 100%;
                border-radius: 8px;
                display: block;
            }
        }
        
    }
}
.ant-modal-content{
    .review_title{
        font-size: $smallHeadings;
        font-weight: 700;
        color: $secondaryColor;
    }
    .review_subInfo{
        .img_cont{
            margin-block: .4em;
        }
        
        p{
            color: $secondaryColor;
            font-size: $body;
            font-weight: 700;
            margin-block: .4em;
        }
    }
    .review_ctrl_btn{
        width: 100%;
        display: flex;
        justify-content: center;

        button{
            width: 160px;
            background: #3A86FF;
            color: $offWhite;
            padding: 10px;
            height: 40px;
            border-radius: 8px;
            font-weight: 700;
            font-size: 16px;
            margin-top: .5em;
        }
    }
}