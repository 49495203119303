#linked-account {
    width: 100%;
    min-height: 50vh;
    background: #ffffff;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 8px;

    .contents {
        width: 100%;
        margin: 10px auto;
        padding: 30px 50px;

        h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            /* identical to box height, or 121% */
            letter-spacing: 0.02em;
            color: #03023B;
            margin-bottom: 30px;
        }
        .flexbox {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            border: 0.5px solid gray;
            border-radius: 5px;
            padding: 5px;
            margin-top: 15px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height, or 165% */
            letter-spacing: 0.06em;
            /* Dark blue Text */
            color: #03023b;

            .flex-content {
                display: flex;
                flex-direction: row;
            }
        }

        .link {
            margin-right: 20px;
            color: #3a86ff;
            text-decoration: none;
        }
        img {
            margin-right: 10px;
            width: 1.3em;
            height: 1.3em;
            margin-top: 2%;
        }
        .disconnect {
            margin-right: 2%;
        }

        p {
            margin-top: 2%;
        }
    }
}
