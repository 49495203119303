#careers {
    margin: 2em 0;
    display: flex;
    width: 100%;
    height: 30vh;
    justify-content: center;

    .container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;

        .title{
            height: 40%;
            .header_img_cont{
                width: 60%;
                height: 80%;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
    
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            h1 {
                color: #3a86ff;
                font-size: 1em;
                // line-height: 100%; // remove when fixed from hero.css
                margin: 0;
                // margin-bottom: 2rem;
            }
        }

        .imgs {
            display: flex;
            justify-content: space-evenly;
            // margin-top: .2em;
            width: 90%;
            height: 30%;
            gap: 1em;

            .img_cont{
                .partner-logo {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        // p {
        //     text-align: center;
        //     margin-bottom: 2rem;
        //     margin-top: -15px;
        //     max-width: 800px;
        //     color: #3a86ff;
        //     font-size: 20px;
        // }
        // .careers-wide {
        //     width: 100%;
        //     padding: 1rem;
        //     max-width: 1126px;
        // }
        // .careers-mobile {
        //     display: none;
        //     padding: 1rem;
        // }
    }
}

// .handshake {
//     width: 120px;
//     height: auto;
// }


// @media (max-width: 1300px) {
//     #careers {
//         .container {
//             .imgs {
//                 transform: scale(0.9);
//             }
//         }
//     }
// }

// @media (max-width: 1200px) {
//     #careers {
//         .container {
//             .imgs {
//                 transform: scale(0.8);
//                 gap: 90px;
//             }
//         }
//     }
// }

// @media (max-width: 1000px) {
//     #careers {
//         .container {
//             .imgs {
//                 transform: scale(0.7);
//                 gap: 90px;
//             }
//         }
//     }
// }

@media (max-width: 900px) {
    #careers {
        .container {
            .imgs {
                width: 100%;
            }
        }
    }
}

@media (max-width: 660px) {
    #careers {
        .container {
            .imgs {
                .img_cont{
                    width: 95px;
                }
            }
        }
    }
}


@media (max-width: 636px) {
    #careers {
        height: 450px;
        .container {
            height: 400px;
            .title{
                height: 30%;
                .header_img_cont{
                    width: 40%;
                    height: 50%;
                }
            }
            .imgs {
                padding-block: 1em;
                height: 550px;
                /* flex-direction: column; */
                flex-wrap: wrap;
                .img_cont{
                    width: 130px;
                    height: 90px;
                }
            }
        }
    }
}

// @media (max-width: 640px) {
//     #careers {
//         .container {
//             margin-top: -40px;
//             .imgs {
//                 margin-top: -30px;
//                 flex-wrap: wrap;
//                 transform: scale(0.7);
//                 gap: 90px;
//             }
//         }
//     }
// }

