#sideBar {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            .link {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                text-decoration: none;
                padding: 10px 0;
                /* or 165% */

                display: flex;
                align-items: center;
                margin-bottom: 4px;

                /* Dark blue Text */
                color: #03023b63;
                transition: all 0.2s;
                &:hover {
                    // background: #03023b;
                    color: #03023b;
                    transform: translateX(10px);

                    // border-radius: 4px;
                    // color: white;
                }
            }
            .active_link {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                text-decoration: none;
                padding: 10px 0px;
                display: inline-flex;
                align-items: center;
                color: #03023b;
                transform: translateX(10px);
                margin-bottom: 4px;
                // border-bottom: 2px solid;
                // background: #03023b;
                // border-radius: 4px;
                // color: white;
            }
        }
    }
}
