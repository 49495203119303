@import 'src/globalStyles/helpers/variables';

#heroCourses {
    // background-color: yellow;
    width: 100%;
    height: 80vh;
    margin-bottom: 2em;
    .slider-hero {
        overflow: hidden;
        width: 100%;
        height: 100%;
        .swiper-wrapper {
            width: 100%;
            height: 100%;
            // background-color: blue;
        }
        .swiper-container {
            width: 100%;
            height: 100%;
            // background-color: red;
            .swiper-button-next {
                padding-right: 80px;
                color: white;
                opacity: 100%;
            }
            .swiper-button-prev {
                padding-left: 80px;
                color: white;
                opacity: 100%;
            }
            .swiper-pagination {
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    background: $grey5;
                    opacity: 80%;
                }
                .swiper-pagination-bullet-active {
                    background: $secondaryColor;
                }
            }

            .swiper-slide {
                position: relative;
                width: 100%;
                height: 100%;
                // overflow: hidden;
                // display: flex;
                // justify-content: center;
                // align-content: center;

                .imageCont{
                    position: relative;
                    width: 100%;
                    height: 100%;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                    &::after{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(69, 65, 65, 0.53);
                    }
                }

                .content {
                    position: absolute;
                    width: 60%;
                    height: 60%;
                    border-radius: 10px;
                    margin: 8% auto;
                    text-align: center;
                    box-shadow: .1em .1em .2em rgba(48, 44, 44, 0.531);
                    backdrop-filter: blur(10px);
                    padding-block: 2em;
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: center;
                    // background-image: url(./assets/hero-bg.png);
                    // background-repeat: no-repeat;
                    // background-position: center;
                    // background-size: cover;
                    // height: 100%;
                    // width: 100%;

                    .--header {
                        color: white;
                        font-size: 2.5em;
                        padding-bottom: 15px;
                    }

                    .text--about {
                        width: 60%;
                        margin: 0 auto;
                        color: white;
                        font-size: 1rem;
                        padding-bottom: 15px;
                    }
                    .watch {
                        color: white;
                        background-color: $secondaryColor;
                        cursor: pointer;
                        height: 34px;
                        width: 114px;
                        border-radius: 9px;
                        font-size: 16px;
                        font-weight: 700;
                        // margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1380px) {
    #heroCourses {
        .container-hero {
            .swiper-container {
                .swiper-slide {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    background-image: url(./assets/hero-bg.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    align-content: center;
                    // .text--about {
                    //     padding-left: 300px;
                    //     padding-right: 300px;
                    // }
                }
            }
        }
    }
}

@media only screen and (max-width: 1379px) {
    #heroCourses {
        .container-hero {
            .swiper-container {
                .swiper-slide {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    background-image: url(./assets/hero-bg.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    align-content: center;
                    // .text--about {
                    //     padding-left: 200px;
                    //     padding-right: 200px;
                    // }
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    #heroCourses {
        .container-hero {
            .swiper-wrapper {
                height: 45vh;
            }
            .swiper-container {
                .swiper-button-next {
                    padding-right: 40px;
                }
                .swiper-button-prev {
                    padding-left: 40px;
                }
                // .swiper-slide {
                //     .content {
                //         .text--about {
                //             padding-left: 150px;
                //             padding-right: 150px;
                //         }
                //     }
                // }
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    #heroCourses {
        .container-hero {
            .swiper-wrapper {
                height: 45vh;
            }
            .swiper-container {
                .swiper-button-next {
                    padding-right: 30px;
                }
                .swiper-button-prev {
                    padding-left: 30px;
                }
                .swiper-slide {
                    .content {
                        // .text--about {
                        //     padding-left: 100px;
                        //     padding-right: 100px;
                        // }
                        .watch {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    #heroCourses {
        .container-hero {
            .swiper-wrapper {
                height: calc(100vh - 90px);
            }
            .swiper-container {
                .swiper-button-next {
                    padding-right: 20px;
                }
                .swiper-button-prev {
                    padding-left: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    #heroCourses {
        .container-hero {
            .swiper-wrapper {
                height: calc(100vh - 90px);
            }
            .swiper-container {
                .swiper-button-next {
                    padding-right: 20px;
                }
                .swiper-button-prev {
                    padding-left: 20px;
                }
                .swiper-slide {
                    .text--about {
                        display: none;
                    }
                }
            }
        }
    }
}
