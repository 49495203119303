@import '../../globalStyles/helpers/_variables.scss';

@keyframes pulse {
    0% {
        opacity: 0.5;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes animate {
    0%{
        transform: rotate(360deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

#loading {
    height: 100vh;
    background: rgba(255, 255, 255, 0.199);
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    svg {
        animation-name: pulse;
        z-index: 1000;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-delay: 0;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
    }
}

.loader__cont{
    img {
        cursor: progress;
        margin: 0 auto;
        animation: animate 2s infinite linear;
        cursor: progress;
        // background-color: green;
    }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    #loading {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);
    }
}