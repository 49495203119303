$primary: #3a86ff;
$secondary: #FFCC00;

.course-container {
    width: 320px;
    min-width: 280px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 0.2em 5px 0 rgba(223, 29, 29, 0.2);
    cursor: pointer;
    transition: 0.3s ease;
    position: relative;
    min-height: 330px;
    height: 350px;
    
    .course-img-con.prem{
        background: $secondary;
    }
    .course-img-con.stand{
        background: white;
    }
    .course-img-con.institutional{
        background: $primary;
    }
    .course-img-con {
        width: 100%;
        height: 70%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        // border-radius: 20px;
        padding-top: 1.3em;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .box {
        min-height: 30px;
        .prem{
            background: $secondary;
        }
        .stand{
            background: white;
        }
        .institutional{
            background: $primary;
        }
    
        .course-title {
            width: 100%;
            color: black;
            font-size: 19px;
            font-weight: 600;
            padding-top: .3em;
            padding-left: 1em;
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .within-card {
        display: flex;
        width: 90%;
        margin: 0 auto;
        height: 30px;
        flex-direction: column;

        .first_layer{
            display: flex;
            height: 20px;
            width: 100%;
            align-items: center;
            margin-top: .4em;

            .img_cont{
                // width: 20%;
                height: 100%;
                margin-right: .1em;
                padding: 0;
                
                .difficulty{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    margin-top: -0.45em;
                }
            }
            
            .info-divider {
                font-weight: 300;
                color: rgb(179, 179, 179);
                margin-right: .1em;
                text-align: center;
                height: 100%;
            }
            
            .course-numeric-rating {
                color: rgb(0, 238, 159);
                font-weight: 600;
                text-align: center;
                font-size: 1em;
                margin-right: .2em;
                height: 100%;
            }
            
            .course-stars-rating {
                width: 30%;
                text-align: center;
                margin-right: .2em;
                height: 100%;
            }
            
            .course-number-of-ratings {
                text-align: center;
                font-size: .7em;
                // height: 100%;
                color: rgba(79, 79, 79, 1);
            }
        }
    }

    .group {
        display: flex;
        height: 30px;
        justify-content: space-between;
        align-items: center;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 13.3614px;
            color: #828282;
        }
        .course-price{
            font-size: medium;
            color: #828282;
        }
    }
}

.ant-popover-inner-content {
    padding: 0 !important;

    .popContainer{
        width: 290px;
        max-height: 300px;
        margin: 0;
        padding: 1em;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        border-radius: 4px;
        padding-bottom: 2em;
        overflow-y: scroll;
        box-shadow: 0px 4px 4px 0px #0000001A;
        border-radius: 8px;

        &::-webkit-scrollbar-track{
            background: rgba(217, 217, 217, 1);
        }
        &::-webkit-scrollbar-thumb {
            background: $primary;

            &:hover{
                background-color: #888;
            }
        }

        &.prem{
            &::-webkit-scrollbar-thumb {
                background: $secondary;
    
                &:hover{
                    background-color: #888;
                }
            }
        }

        h2{
            width: 70%;
            font-size: 20px;
            font-weight: 500;
        }

        .view{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 1em;
            right: 1em;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                color: $secondary;
                cursor: pointer;
            }
        }

        .date {
            .prem{
                color: $secondary;
                font-weight: 600;
            }
            .stand{
                color: white;
                font-weight: 600;
            }
            .institutional{
                color: $primary;
                font-weight: 600;
            }
        }

        .info{
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block: .6em;
            
            .wrap{
                display: flex;
                height: 100%;
                align-items: center;

                .img_cont{
                    width: 20%;
                    height: 100%;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                p{
                    font-size: 12px;
                    font-weight: 400;
                    height: 100%;
                    padding-top: .2em;
                    margin-left: .2em;
                }
            }
            
            p{
                font-size: 13px;
                font-weight: 400;
                padding-top: .1em;
                height: 100%;
            }
        }

        &>p{
            font-size: 13px;
            font-weight: 400;
        }

        .whatToLearn{
            height: auto;
            margin-bottom: 5px;

            h4{
                font-size: 14px;
                font-weight: 600;
            }
            .prem{
                color: $secondary;
            }
            .stand{
                color: white;
            }
            .institutional{
                color: $primary;
            }
        }

        .ant-btn{
            width: 150px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            border-radius: 8px;
            background-color: $secondary;
            border-color: $secondary;
            margin: 0 auto;
            position: absolute;
            bottom: 1em;
            left: calc((100% - 150px)/2);
            cursor: pointer;
            transition: all 1.2s ease-in;

            &:hover{
                transform: scale(1.1);
            }
        }
    }
}



.course-container:hover {
    .course-img {
        opacity: 0.9;
    }
    transform: translateY(-8px);
}
// #course-card-container {
//     max-width: 400px;
//     min-width: 340px;
//     border-radius: 0.75rem;
//     margin: 1rem auto;
//     background-color: #fff;
//     box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
//     justify-content: center;
//     .course-card-image-container {
//         max-width: fit-content;
//         img {
//             width: 100%;
//             border-radius: 0.75rem 0.75rem 0 0;
//             object-fit: cover;
//             object-position: center;
//         }
//     }
//     .course-card-content {
//         max-width: fit-content;
//         padding: 1.5rem 1rem;
//         .course-card-content-overview {
//             margin-top: 0.5rem;
//             display: flex;
//             align-items: center;
//             .course-card-difficulty {
//                 width: 50px;
//                 margin-right: 0.5rem;
//             }
//             .course-card-rating {
//                 font-size: 12px;
//                 color: #46d5b3;
//             }
//             .course-card-stars {
//                 width: 100px;
//             }
//             .course-card-count {
//                 font-size: 12px;
//                 color: #4f4f4f;
//             }
//         }
//         small {
//             color: #828282;
//             font-size: 12px;
//         }
//     }
// }

@media screen and (max-width: 992px) {
    .course-container {
        width: 250px;
        height: 320px;
        // margin-left: 17px;
    }
}

@media screen and (max-width: 727px) {
    .course-container {
        min-width: 200px;
        min-height: 320px;
        // background-color: red;
        // margin-left: 17px;
    }
}

@media screen and (max-width: 627px) {
    .course-container {
        width: 350px;
        min-height: 370px;
        // background-color: green;
        // margin-left: 17px;
    }
}

// @media screen and (max-width: 320px) {
//     #course-card-container {
//         margin-left: -2px;
//     }
// }
