.instructorApplications {
    .header {
        font-size: 40px;
        color: #3a86ff;
        padding-bottom: 20px;
        font-weight: bold;
    }
    .overview {
        font-weight: bold;
    }
    .tableContainer {
        background-color: white;
        width: 100%;
        padding: 1rem;
        border-radius: 10px;
        border: solid 5px #e3ecfb;
    }
    .historyTable {
        width: 100%;
        thead {
            background-color: #3a86ff;
            th {
                color: #fff;
                font-size: 20px;
                padding: 10px;
                &:hover {
                    background-color: #3171d8;
                }
            }
            .left {
                border-radius: 10px 0px 0px 10px;
            }
            .right {
                border-radius: 0px 10px 10px 0px;
            }
        }
        tbody {
            tr {
                td {
                    padding: 5px;
                }
                &:nth-child(even) {
                    background-color: RGBA(58, 134, 255, 0.15);
                }
            }
            .instructor {
                display: flex;
                font-size: 16px;
                font-weight: 700;
                align-items: center;
                p {
                    padding-left: 5px;
                }
            }
            .approved {
                background-color: #46d5b44c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .processing {
                background-color: #3a86ff4c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .rejected {
                background-color: #eb57574c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .Suspended {
                background-color: #ffcc004c;
                display: flex;
                justify-content: center;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
            }
            .date {
                font-size: 16px;
                font-weight: 700;
                display: flex;
                justify-content: center;
            }
        }
    }
}
