@import "../../../globalStyles/helpers/variables";

.form__cont{
    color: $secondaryColor;
    
    h2{
        color: $secondaryColor;
        font-size: 28px;
    }

    form{

        .form__item__cont{
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;

            &>p{
                text-align: center;
                margin-block: .3em;
                color: $grey4;
                font-size: $body;
                font-weight: 400;
            }

            label{
                text-align: center;
                font-weight: 700;
                font-size:24px;
                color: $secondaryColor;

                sup{
                    font-size: $phoneFontSize;
                    position: relative;
                    top: -1em;
                }
            }

            .box_container{
                width: 100%;
                height: 40vh;
                display: flex;
                margin-top: .5em;
                justify-content: space-between;

                .box{
                    width: 48%;
                    height: 100%;
                    border-radius: 8px;
                    box-shadow: 0px 4px 4px 0px #0000001A;
                    overflow-y: scroll;
                    padding: .3em;
                    border: 2px solid $secondaryColor;

                    &::-webkit-scrollbar-track{
                        background: rgba(217, 217, 217, 1);
                    }
                    &::-webkit-scrollbar-thumb {
                        background: rgba(58, 134, 255, 1);

                        &:hover{
                            background-color: #888;
                        }
                    }

                    .empty_box{
                        width: 80%;
                        text-align: center;
                        margin: 2em auto;
                        padding: 3.7em;
                        border-radius: 5px;
                        border: 2px dashed $grey5;

                        h4{
                            font-size: 1.5em;
                            font-weight: 700;
                            color: $secondaryColor;
                        }
                    }

                    .fetched_item{
                        width: 100%;
                        height: 50px;
                        margin-bottom: .6em;
                        font-size: $smallHeadings;
                        font-weight: 500;
                        padding: .6em 1em;
                        background-color: white;
                        box-shadow: 0px 4px 4px 0px #0000001A;
                        border: 1px solid $secondaryColor;
                        border-radius: 8px;
                    }
                    
                    .box_item{
                        width: 100%;
                        height: 50px;
                        margin-bottom: .6em;
                        font-size: $smallHeadings;
                        font-weight: 500;
                        border-radius: 8px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        
                        .order, .item_name{
                            height: 100%;
                            width: 10%;
                            background-color: white;
                            padding: .3em .5em;
                            height: 100%;
                            margin-right: .6em;
                            border-radius: 8px;
                            box-shadow: 0px 4px 4px 0px #0000001A;
                            border: 2px solid $grey4;                            
                        }
                        
                        .item_name{
                            width: 95%;
                            position: relative;
                            margin: 0;
                            display: flex;
                            align-items: center;

                            .first{
                                width: 90%;
                            }
                            
                            .del_item{
                                position: absolute;
                                right: 1em;
                                cursor: pointer;
                                // background-color: red;
                            }
                        }

                        .dragging{
                            border: none;                            
                            border-width: 2px;
                            background-color: $secondaryColor;
                        }

                    }

                    .dragging{
                        border-width: 2px;
                        background-color: $secondaryColor;
                        color: $offWhite;
                    }
                }

                .dragOver{
                    background-color: $grey5;
                }
            }

            .ant-collapse{
                height: auto;
                box-shadow: 0px 4px 4px 0px #0000001A;
                border-radius: 8px;
                background-color: white;
                font-size: $smallHeadings;
                font-weight: 600;

                .ant-collapse-header{
                    padding: 8px;
                }

                .pane_header{
                    width: 100%;
                    height: 6vh;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-transform: capitalize;
                    font-weight: 400;
                    
                    p{
                        width: 40%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 1em;
                        font-weight: 600;
                    }

                    div{
                        width: 55%;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        text-transform: none;

                        .info{
                            color: #888;
                            font-size: small;
                        }
                    }
                }
            }

            .ant-form-item{
                height: auto;
                // height: 20vh;
                box-shadow: 0px 4px 4px 0px #0000001A;
                
                .ant-input{
                    height: 50px;
                    border-radius: 8px;
                    letter-spacing: 0.06em;
                    border: none;
                    box-shadow: 0px 4px 4px 0px #0000001A;
                    
                    &:focus{
                        outline: 1px solid #BDBDBD;
                    }
                }
                
                .textbox{
                    height: 100px;
                }
                
                .ant-select{
                    min-height: 50px;
                    box-shadow: 0px 4px 4px 0px #0000001A;
                    border-radius: 8px;
                    border: none;

                    &:focus{
                        outline: 1px solid #BDBDBD;
                    }
                    
                    &-selector{
                        padding-block: .6em;
                        height: 100%;
                        line-height: 38px;
                        font-size: 16px;
                        letter-spacing: 0.06em;
                        box-shadow: 0px 4px 4px 0px #0000001A;
                        border-radius: 8px;
                        border: none;
                        
                        &:focus{
                            outline: 1px solid #BDBDBD;
                        }
                    }
                }
                
                .ant-select.inner__select{
                    height: 100%;
                }

                .objective_inputfield{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .ant-input{
                        width: 85%;
                    }

                    button {
                        width: 10%;
                        font-weight: 500;
                        background-color: $secondaryColor;
                        color: $pureWhite;
                        height: 50px;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                }
                
                .btn__cont{
                    min-width: 60%;
                    margin: 0 auto;
                    height: 50px;
                    display: flex;
                    gap: .5em;
                    justify-content: space-between;
                    align-items: center;
                    box-shadow: 0px 4px 4px 0px #0000001A;
                    
                    button{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    
                    .btn{
                        // min-width: 3em;
                        width: 30%;
                        height: 80%;
                        border: 2px solid $secondaryColor;
                        border-radius: 8px;
                        font-size: 16px;
                        font-weight: 800;
                        // padding-inline: 1em;

                        &:hover{
                            color: none;
                        }
                    }

                    .active{
                        background-color: $secondaryColor;
                        color: $offWhite;
                        width: 30%;
                        // min-width: 4em;
                        height: 80%;
                        border: 2px solid $secondaryColor;
                        border-radius: 8px;
                        font-size: 16px;
                        font-weight: 800;
                    }
                }
            }

            .uploadList{
                width: 100%;
                height: 50px;
                padding: 2px;
                display: flex;
                align-items: center;
                background-color: white;
                border: 1px solid rgba(189, 189, 189, 1);
                border-radius: 8px;
                margin-block: 8px;
                position: relative;

                .img__cont{
                    height: 60%;
                    width: 50px;

                    img{
                        height: 100%;
                        width: 100%;
                    }
                }

                p{
                    margin-left: 20px;
                    font-size: 14px;
                    width: 80%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    // white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .del__icon{
                    position: absolute;
                    right: 1em;
                    cursor: pointer;
                }
            }
            
            .ant-checkbox-wrapper{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: black;
                text-align: start;
            }

            .text__item{
                width: 100%;
                min-height: 5vh;
                padding-inline: 2em;
                margin-block: .6em;
                background-color: white;
                border-radius: 8px;
                position: relative;
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 4px  0px #0000001A;
            }
            
            .price__cont{
                width: 300px;
                // background-color: gold;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                font-size: $body;
                font-weight: 700;
                box-shadow: 0px 4px 4px 0px #0000001A;
                border-radius: 8px;
                
                span{
                    padding-inline: .5em;
                }


                input{
                    width: 40%;
                    height: 100%;
                    padding: .5em;
                    border-radius: 8px;
                    border: 2px solid $secondaryColor;
                }
                
                .error{
                    font-size: $body;
                    font-weight: 700;
                    outline: 2px solid $errorColor;
                    border: 2px solid $errorColor;
                }
            }

            .file__upload{
                padding: 1em;
                background-color: $offWhite;
                border-radius: 8px;
                box-shadow: 0px 4px 4px 0px #0000001A;

                h6{
                    text-align: center;
                }

                p{
                    border: 2px solid $secondaryColor;
                    width: fit-content;
                    margin: 0 auto;
                    padding: .5em;
                    border-radius: 40%;
                    text-align: center;
                }
            }

            .lang__cont{
                box-shadow: 0px 4px 4px 0px #0000001A;
                height: 50px;
                width: 100%;
                display: flex;
                
                h4{
                    display: flex;
                    background-color: $grey5;   
                    padding-left: 1em;
                    align-items: center;
                    width: 20%;
                    height: 100%;
                    border-radius: 8px;
                    box-shadow: 0px 4px 4px 0px #0000001A;
                }

                .ant-select{
                    height: 50px;
                    box-shadow: 0px 4px 4px 0px #0000001A;
                    border-radius: 8px;
                    border: none;

                    &:focus{
                        outline: 1px solid #BDBDBD;
                    }
                    
                    &-selector{
                        padding-block: .6em;
                        height: 100%;
                        line-height: 38px;
                        font-size: 16px;
                        letter-spacing: 0.06em;
                        box-shadow: 0px 4px 4px 0px #0000001A;
                        border-radius: 8px;
                        border: none;
                        
                        &:focus{
                            outline: 1px solid #BDBDBD;
                        }
                    }
                }
            }

        }
        input[type='checkbox']{
            outline: 2px solid $secondaryColor !important;
            border: 2px solid $secondaryColor;
        }

        .btn__cont{
            width: 60%;
            height: 50px;
            margin: 2em auto 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ant-btn{
                width: 40%;
                height: 100%;
                font-size: $body;
                font-weight: 500;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
}

.quiz_form{
    .product__summary{
        width: 100%;
        height: 50px;
        box-shadow: 0px 4px 4px 0px #0000001A;
        border-radius: 8px;
        border: 1px solid rgb(245, 245, 245);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        position: relative;
        padding: 10px;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 1em;

        .button__like{
            width: 180px;
            height: 100%;
            background: rgba(193, 205, 248, 1);
            border: 2px solid rgba(193, 205, 248, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #3A86FF;
            position: absolute;
            text-transform: uppercase;
            right: 10px;
            cursor: pointer;
            border-radius: 8px;
        }
    }
    .questionTableCont{
        background-color: #E4E4E4;
        height: auto;
        text-align: center;
        margin-bottom: 3em;

        h4{
            padding-block: 1vh;
        }

        #questionTable{
            max-height: 35vh;
        }
    }
    form {
        height: auto;
        .creation_info{
            width: 100%;
            height: 3vh;
            text-align: center;
            color: $grey4;
            font-size: .8em;
        }
        .btn__cont.creator{
            margin-top: .4em;
        }
        .form__item__cont{
            .subform{
                width: 100%;
                height: auto;
                border-radius: 8px;
                box-shadow: 0px 4px 4px 0px #0000001A;

                &#multichoice{
                    // min-height: 33vh;
                    background-color: white;

                    .btn-cont{
                        width: 200px;
                        height: 40px;
                        border-radius: 8px;
                        margin-top: .3em;
                        margin-left: auto;
                        cursor: pointer;

                        .ant-btn{
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                        }
                    }
                }
                .CEQ_cont{
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    padding-inline: 5px;
                    list-style-type: none;
                    list-style-position: inside;
                    background-color: white;

                    li{
                        height: calc(20vh/3);
                        display: flex;
                        align-items: center;
                        font-size: 1.1em;
                        padding-inline: .3em;

                        // START STYLE FOR MULTIPLECHOICE INPUT FIELD
                        &.option_cont{
                            display: flex;
                            padding: .2em;
                            justify-content: space-between;

                            .option_label{
                                width: 5%;
                                height: 100%;
                                border: 1px solid $secondaryColor;
                                border-radius: 8px;
                                text-align: center;
                                padding-block: .4em;
                            }

                            .option_input_field{
                                width: calc(100% - 6%);
                                height: 100%;
                                padding: 0;

                                .ant-form-item{
                                    height: 100%;
                                    padding: 0;
                                    box-shadow: none;
                                    
                                    .ant-input{
                                        outline: 1px solid $grey3;
                                        height: 38px;
                                    }
                                }
                            }
                        }
                        // END STYLE FOR MULTIPLECHOICE INPUT FIELD

                        .img_cont{
                            margin-right: 1.2em;
                            width: 20px;
                            text-align: center;
                            cursor: pointer;
                        }

                        button{
                            background-color: transparent;
                            width: 100%;
                            height: 100%;
                            text-align: left;
                            padding-left: .6em;

                            &.selected{
                                background: linear-gradient(0deg, #3A86FF, #3A86FF),
                                            linear-gradient(0deg, #A8CAFF, #A8CAFF);
                            }
                        }
                    }

                    &.CEQ_cont > li:nth-child(even){
                        border-block: 1px solid $grey4;
                    }
                    &.multichoice > li:first-child{
                        border-bottom: 1px solid $grey4;
                        border-radius: 8px;
                    }
                }
            }
        } 
    }
}

.form__wrapper{
    .product__summary{
        width: 100%;
        height: 50px;
        box-shadow: 0px 4px 4px 0px #0000001A;
        border-radius: 8px;
        border: 1px solid rgb(245, 245, 245);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        position: relative;
        padding: 10px;
        font-weight: 700;
        font-size: 16px;
    }

    .button__like{
        width: 100px;
        height: 100%;
        background: rgba(193, 205, 248, 1);
        border: 2px solid rgba(193, 205, 248, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
        color: #3A86FF;
        position: absolute;
        text-transform: uppercase;
        right: 10px;
        cursor: pointer;
        border-radius: 8px;
    }
}

@media screen and (max-width: 800px) {
    .form__cont form{
        .btn__cont .ant-btn{
            padding: 10px;
            width: 48%;
        }

        .form__item__cont {
            .box_container{
                flex-direction: column;
                height: auto;
        
                .box{
                    width: 100%;
                    margin-bottom: 1em;
                    height: 270px;

                    .empty_box{
                        font-size: $phoneFontSize;
                    }
        
                    .box_item .order{
                        padding-inline: .3em;
                    }
                }
            }

            .ant-collapse{
                .pane_header{
                    p{
                        width: 35%;
                    }
                    div{
                        width: 63%;
                        font-size: 13px;

                        &>.first_span{
                            width:30%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }

            .uploadList{
                p{
                    width: 70%;
                    margin-left: 7px;
                }
            }

            .ant-form-item {
                .objective_inputfield{
                    .ant-input{
                        width: 75%;
                    }
            
                    button{
                        width: 20%;
                    }
                }
                .btn__cont{
                    width: 70%;

                    .btn, .active{
                        font-size: 14px;
                        width: 45%;
                        padding: 2px;
                    }
                }
            }
        }
    }
    .lang__cont h4{
        font-size: 1em;
    }
}
@media screen and (max-width: 450px) {
    .form__cont  h2{
        font-size: $body;
    }

    .form__cont .ant-form {
        width: 98%;
        margin: 0 auto;

        .btn__cont{
            width: 100%;
            margin: 1em 0;
        }

        .form__item__cont {

            .box_container {
                .box{
                    .fetched_item, .box_item{
                        display: flex;
                        align-items: center;
                        font-size: $phoneFontSize;
                        padding-inline: 1em;
                    }
                    .box_item{
                        padding-inline: 0;
                    }
                }
            }
            .lang__cont h4{
                padding-left: 0;
                font-size: .9em;
            }

            &>p{
                font-size: $phoneFontSize;
            }

            label{
                font-size: 14px;
            }

            .uploadList{
                p{
                    width: 65%;
                    margin-left: 0;
                }
            }

            .ant-checkbox-wrapper{
                font-size: $phoneFontSize;
            }

            .ant-collapse{
                .pane_header{
                    p{
                        font-size: $phoneFontSize;
                    }

                    div{
                        flex-direction: column;
                        align-items: flex-start;
                        padding-left: .5em;

                        &>.first_span{
                            width:80%;
                        }
                    }
                }
            }

            .ant-form-item {
                .objective_inputfield{
                    .ant-input{
                        width: 75%;
                    }
            
                    button{
                        width: 20%;
                    }
                }

                .btn__cont{
                    width: 90%;

                    .btn, .active{
                        font-size: 11px;
                    }
                }
            }
        }
    }
}