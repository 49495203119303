#Preferences {

    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 8px;

    #Title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.02em;
        color: #03023B;
    }

    .SubTitle {
        margin-top: 20px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.06em;
        color: #03023B;
    }

    #LanguageSelector {
        border: 1px solid #BDBDBD;
        box-sizing: border-box;
        box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
        border-radius: 8px;
        width: 265px;
        height: 45px;
        padding: 10px;
    }

    #saveBtn, #cancelBtn {
        width: 120px;
        margin: 20px 10px 0px 0px;
    }
    #cancelBtn {
        border: 2px solid #03023B;
        box-sizing: border-box;
        box-shadow: inset 0px -1px 0.5px rgba(14, 14, 44, 0.4);
        border-radius: 8px;
        color: #03023B;
        font-weight: bold;
        background-color: #FFFFFF;
    }

    #DarkModeDiv {
        display: flex;
        p {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.06em;
            color: #3A86FF;
            margin-right: 80px;
        }
    }

}