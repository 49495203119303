@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import 'react-datepicker/dist/react-datepicker.css';
@import '../../../globalStyles/helpers/_variables.scss';

$primary: #3a86ff;

// Main container
.stu-container {
    position: relative;
    top: 1h;
    // background-color: red;
    display: grid;
    margin: 0% 5% 0 12%;
    grid-template-columns: 1fr 1fr .6fr;
    grid-template-rows: 0.7fr auto 1.2fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    // gap: 2% 1.5%;
    gap: 1em;
    grid-template-areas:
        'Header Header Cal-and-To-do'
        'Course-1 Course-1 Cal-and-To-do'
        'Line-Graph Bar-Chart Cal-and-To-do';

    .enrolled_course{ 
        width: 100%;
        grid-area: Course-1;
        // display: flex;

        p{
            text-align: center;
            font-size: $body;
            font-weight: 500;
            text-transform: capitalize;
            padding-block: 1em;
            box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background-color: $offWhite;
        }

        .course_container{
            width: 100%;
            height: auto;
            display: flex;
            // background-color: green;
            flex-wrap: wrap;
            gap: .6em;

            // Styling for both enrolled course cards START
            stu-enrolled-title {
                color: $primary;
            }
            .stu-course-container {
                position: relative;
                width: 270px;
                min-height: 250px;
                border-radius: 20px;
                background-color: white;
                box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
                cursor: pointer;
            }

            .upper_section {
                width: 100%;
                height: 200px;
                // background-color: red;
                position: relative;
                border-top-right-radius: 20px;
                border-top-left-radius: 20px;

                .img_cont{
                    width: 100%;
                    height: 100%;
                    border-top-right-radius: 20px;
                    border-top-left-radius: 20px;
                    // object-fit: contain;

                    img{
                        width: 100%;
                        height: 100%;
                        border-top-right-radius: 20px;
                        border-top-left-radius: 20px;
                        object-fit: cover;
                    }
                }

                .progress{
                    width: 100%;
                    position: absolute;
                    padding-inline: 1em;
                    bottom: .5em;
                    background: transparent;
                    display: flex;
                    align-items: center;

                    span{
                        min-width: 15%;
                        color: white;
                        font-size: 1em;
                        font-weight: 500;
                    }
                }
            }

            .lower_section{
                padding: .8em 1em;

                .course_heading{
                    color: black;
                    font-size: 1.3em;
                    font-weight: 600;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .middle_belt{
                    height: 30px;
                    display: flex;
                    justify-content: space-between;
                    margin-block: .3em;

                    .difficulty_indicator{
                        width: 25px;
                        height: 100%;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .divider{
                        border-left: 2px solid $grey4;
                    }

                    .course_rating{
                        width: 75%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span{
                            font-size: 1em;
                            font-weight: 500;
                        }
                    }
                }

                .author{
                    font-size: .8em;
                    color: black;
                    font-weight: 300;
                }
            }
            // Styling for both course cards END
        }
    }
}

// Main title
.stu-title {
    color: $primary;
    margin-top: 11px;
    position: absolute;
    top: 12px;
    font-weight: 800;
    z-index: 999;
}

.stu-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.change-to-instructor {
    position: absolute;
    top: 28px;
    right: 220px;
    z-index: 999;
}

// Header Card
.Header {
    position: relative;
    grid-area: Header;
}

.header-wrapper:hover {
    .stu-header-img {
        opacity: 1;
    }
}

.stu-header-img {
    border-radius: 25px;
    width: 100%;
    max-width: 100%;
    opacity: 0.7;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    transition: 0.2s ease;
    z-index: 1;
}

.stu-new-line {
    padding-top: 30px;
    padding-left: 15px;
}

.stu-img-title {
    position: absolute;
    color: white;
    font-size: 30px;
    font-weight: 700;
    top: 0;
    left: 0;
    margin-top: 9%;
    margin-left: 6%;
    letter-spacing: 1px;
    z-index: 2;
}

// Course card 1 (left enrolled card)
.Course-1 {
    grid-area: Course-1;
}

// Course card 2 (right enrolled card)
.Course-2 {
    grid-area: Course-2;
    margin-top: 41px;
}

// Line Graph
.Line-Graph {
    grid-area: Line-Graph;
}

.stu-grades-stats-title {
    color: $primary;
    padding-top: 15px;
}

.stu-grades-stats-container {
    border-radius: 20px;
    width: 100%;
    max-width: 100%;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.custom-tooltip {
    padding: 5px;
    background-color: #3a86ff;
    border-radius: 5px;
}

.intro {
    font-size: 16px;
    font-weight: 600;
    color: rgb(255, 218, 55);
}

.label {
    font-size: 15px;
    color: rgb(255, 255, 255);
}

.label-bar {
    font-size: 15px;
    color: rgb(255, 218, 55);
    font-weight: 600;
}

.grade-highlight {
    color: white;
}

.stu-views-title {
    display: flex;
    color: black;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
    letter-spacing: 5px;
}

// Bar Chart
.Bar-Chart {
    grid-area: Bar-Chart;
    padding-top: 40px;
}

// Right side - calender and to-do list
.Cal-and-To-do {
    grid-area: Cal-and-To-do;
    margin-left: 40px;
}

// Calendar
.cal-container {
    border-radius: 20px;
    // width: 70%;
    transform: scale(0.9);
    // margin-left: 80px;
    margin-top: -20px;
    max-width: 100%;
    padding: 25px 40px 40px 40px;
    background-color: white;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

// Imported and altered CSS for calendar from library START
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 40px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1.5em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    border-radius: 25px;
    padding: 10px 6.667px;
    background: none;
    text-align: center;
    line-height: 14px;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    border-radius: 50px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: #ffe600;
    border-radius: 50px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
    border-radius: 50px;
}
.react-calendar__tile--hasActive {
    background: #76baff;
    border-radius: 50px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
    border-radius: 50px;
}
.react-calendar__tile--active {
    background: #96cbff;
    border-radius: 50px;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #84c1ff;
    border-radius: 50px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
    border-radius: 50px;
}

.react-calendar__navigation__label {
    border-radius: 50px;
    color: #3a86ff;
}

.react-calendar__navigation__label__labelText {
    color: #1770ff;
    font-weight: 500;
    font-size: 19px;
}
// Imported and altered CSS for calendar from library END

// Tasks
.tasks-container {
    transform: scale(0.9);
    margin-top: -10px;
    border-radius: 20px;
    // width: 70%;
    height: auto;
    padding-bottom: 1em;
    // margin-left: 80px;
    max-width: 100%;
    background-color: white;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
}

.tasks-header {
    display: flex;
    padding: 25px 25px 5px 25px;
}

.stu-plus-icon {
    margin-left: auto;
    width: 20px;
    height: 20px;
    margin-top: 4px;
    cursor: pointer;
}

.stu-plus-icon:hover {
    transform: rotate(90deg);
}

.stu-plus-icon {
    transition: 0.2s ease;
}

.green-border {
    border-left: 5px solid rgb(0, 238, 159);
}

.yellow-border {
    border-left: 5px solid rgb(255, 218, 55);
}

.red-border {
    border-left: 5px solid #d10000;
}

.ticked-task {
    background-color: rgb(0, 238, 159);
    transition: 0.3s ease;
}

.crossed {
    opacity: 0.5;
    text-decoration: line-through;
}

.stu-tick {
    width: 25px;
    margin-top: 2px;
    margin-left: 2px;
}

.stu-task-card {
    position: relative;
    width: 94.5%;
    background-color: white;
    box-shadow: 0 0.7px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    margin-left: 10px;
    margin-bottom: 16px;
    padding: 15px;
    border-radius: 10px 10px 10px 10px;
}

.stu-circle {
    width: 30px;
    height: 30px;
    border-radius: 25px;
    border: 1px solid rgb(201, 201, 201);
    margin-top: 5px;
    cursor: pointer;
    transition: 0.3s ease;
}

.title-of-task {
    position: absolute;
    top: 18px;
    padding-left: 8px;
    margin-left: 28px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
}

.date-task {
    position: absolute;
    top: 35px;
    left: 52px;
    color: grey;
    font-size: 14px;
}

// Button that opens modal
.modal-click {
    margin-left: auto;
}

.stu-remove-display {
    display: none;
}

// Modal / Pop-up window
body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
    z-index: 999;
}

.modal-content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 0px 0px;
    border-radius: 20px;
    max-width: 600px;
    min-width: 300px;
}

.modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.close-modal {
    margin-left: auto;
    padding: 5px 10px;
    background-color: #d10000;
    border-radius: 25px;
    color: white;
}

.close-modal:hover {
    background-color: rgb(233, 0, 0);
}

.chevron {
    width: 10px;
    height: auto;
    cursor: pointer;
}

.go-tasks {
    padding-left: 5px;
    font-weight: 500;
    cursor: pointer;
}

.modal-body {
    display: flex;
    margin-top: 50px;
    margin-bottom: 30px;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.modal-new-task {
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
}

.modal-input-task {
    border-bottom: 1px solid black;
    background: white;
    width: 400px;
    margin-top: 15px;
    outline: none;
    margin-bottom: 70px;
}

.modal-new-task {
    display: flex;
}

.enter-date-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.date-picker {
    width: 400px;
    border-bottom: 1px solid black;
    outline: none;
    margin-top: 15px;
}

.calendarIcon {
    width: 20px;
    height: auto;
    position: absolute;
    top: 30%;
    right: 0;
    cursor: pointer;
}

.modal-colour {
    font-weight: 500;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
}

.colours-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 20px;
}

.ball-colour {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.light-blue {
    background-color: #3a86ff;
    color: #3a86ff;
    cursor: pointer;
}

.dark-blue {
    background-color: #0042ac;
    color: #0042ac;
    cursor: pointer;
}

.green {
    background-color: #00ee9f;
    color: #00ee9f;
    cursor: pointer;
}

.yellow {
    background-color: #ffee00;
    color: #ffee00;
    cursor: pointer;
}

.red {
    background-color: #d32000;
    color: #d32000;
    cursor: pointer;
}

.pick-colour {
    position: relative;
    border: 1px solid $primary;
    color: white;
    cursor: pointer;
}

.pick-color-plus {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 4px;
    left: 5px;
}

.modal-create-task {
    width: 150px;
    margin: 80px auto 30px auto;
    padding: 15px 10px 15px 10px;
    border: 3px solid $primary;
    background-color: white;
    font-weight: 500;
    color: $primary;
    border-radius: 15px;
    transition: 0.2s ease;
}

.modal-create-task:hover {
    background-color: $primary;
    color: white;
}

// Smaller laptop
@media (max-width: 1420px) {
    .stu-container {
        margin: 1% 2% 0 2%;
    }

    .Cal-and-To-do {
        grid-area: Cal-and-To-do;
        margin-left: 0px;
    }
}

// Moving instructor link to new position

@media (max-width: 992px) {
    .change-to-instructor {
        top: 20px;
        left: 5%;
    }

    .title-of-task {
        font-size: 16px;
    }

    .date-task {
        font-size: 13px;
    }
}

// Tablet
@media (max-width: 1200px) {
    .stu-container {
        margin: 0px 2em;
        grid-template-columns: 50% 50%;
        grid-template-areas:
            'Header Header'
            'Course-1 Course-1'
            'Line-Graph Bar-Chart'
            'Cal-and-To-do Cal-and-To-do'
            'Cal-and-To-do Cal-and-To-do';

        .enrolled_course .course_container .stu-course-container{
            // justify-content: space-around;
            width: 230px;
            min-height: 200px;


            .lower_section .middle_belt{
                .difficulty_indicator{
                    width: 20px;
                }
                .course_rating{
                    width: 80%;

                    span{
                        font-size: .8em;
                    }
                }
            }
        }
    }

    .change-to-instructor {
        margin-left: 80px;
        margin-top: 20px;
    }

    .stu-title {
        top: 2px;
    }

    .Cal-and-To-do {
        grid-area: Cal-and-To-do;
        margin-left: 0px;
    }

    .cal-container {
        width: 100%;
        transform: scale(1);
        margin-left: 0;
        margin-top: 0;
        max-width: 100%;
    }

    .tasks-container {
        transform: scale(1);
        width: 100%;
        height: 55%;
        margin-top: 30px;
        margin-left: 0;
        max-width: 100%;
    }

    .stu-new-line {
        padding-top: 0;
        padding-left: 95px;
        top: 35%;
        left: 30%;
    }

    .stu-img-title {
        position: absolute;
        color: white;
        font-size: 26px;
        font-weight: 700;
        top: 37%;
        left: 30%;
        margin-top: 0;
        margin-left: 0;
        letter-spacing: 1px;
        z-index: 2;
    }
}

// Tablet - adjustments for ipads
@media (min-height: 1170px) and (max-height: 1180px) {
    .change-to-instructor {
        top: 20px;
    }
}

// very slight adjust to containers when titles shrink
@media (max-width: 1024px) {
    .Course-2 {
        margin-top: 38px;
    }

    .Bar-Chart {
        padding-top: 38px;
    }

    .title-of-task {
        font-size: 16px;
    }
}

// Tablet title adjustment
@media (max-width: 730px) {
    .stu-img-title {
        top: 37%;
        left: 28%;
    }
}

// Tablet - container adjustments
@media (max-width: 640px) {
    .stu-container {
        margin: 10px 20px 0 20px;
    }

    .stu-title {
        margin-left: 27px;
    }

    .change-to-instructor {
        margin-left: 45px;
    }
}

// Tablet - container adjustments
@media (max-width: 530px) {
    .stu-container {
        margin: 10px 10px 0 0px;
    }

    .stu-title {
        margin-left: 35px;
    }

    .change-to-instructor {
        margin-left: 40px;
    }
}

// Large Mobile
@media (max-width: 520px) {
    .stu-container {
        margin: 10px 20px 0 20px;
        grid-template-columns: 100%;
        gap: 0.5% 1%;
        grid-template-areas:
            'Header'
            'Course-1'
            'Course-2'
            'Line-Graph'
            'Bar-Chart'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do';
    }

    .stu-header-img {
        width: 100%;
    }

    .title-of-task {
        font-size: 15px;
    }

    .date-task {
        color: grey;
        font-size: 12px;
    }

    .stu-new-line {
        padding-top: 0;
        padding-left: 60px;
        top: 35%;
        left: 30%;
    }

    .stu-img-title {
        font-size: 16px;
        top: 37%;
        left: 32%;
    }

    .Course-1 {
        grid-area: Course-1;
    }

    .Course-2 {
        grid-area: Course-2;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .Bar-Chart {
        grid-area: Bar-Chart;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .stu-title {
        top: 10px;
        left: 50px;
    }

    .change-to-instructor {
        display: none;
    }
}

//Large mobile - adjusting title in header card & making modal responsive
@media (max-width: 445px) {
    .stu-img-title {
        margin-left: -5%;
        margin-top: -1%;
    }

    .toggle-display {
        display: inline;
    }

    .modal-content {
        max-width: 90%;
    }

    .modal-body {
        max-width: 90%;
    }

    .modal-input-task {
        max-width: 100%;
    }

    .enter-date-container {
        max-width: 100%;
    }

    .date-picker {
        max-width: 200px;
    }
}

// Temporarily stopping shrinking of container by giving it a permanent size
@media (max-width: 395px) {
    .stu-container {
        margin: 10px 20px 0 20px;
        grid-template-columns: 300px;
        gap: 0.5% 1%;
        grid-template-areas:
            'Header'
            'Course-1'
            'Course-2'
            'Line-Graph'
            'Bar-Chart'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do';
    }
}

// Medium mobile
@media (max-width: 375px) {
    .stu-container {
        margin: 10px 20px 0 12px;
        grid-template-columns: 300px;
        gap: 0.5% 1%;
        grid-template-areas:
            'Header'
            'Course-1'
            'Course-2'
            'Line-Graph'
            'Bar-Chart'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do';
    }

    .stu-title {
        margin-top: 17px;
        font-size: 16px;
    }

    .stu-img-title {
        margin-top: -5px;
    }
}

// Small mobile
@media (max-width: 340px) {
    .stu-container {
        margin: 0px 0px 0 -15px;
        grid-template-columns: 300px;
        gap: 0.5% 1%;
        grid-template-areas:
            'Header'
            'Course-1'
            'Course-2'
            'Line-Graph'
            'Bar-Chart'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do'
            'Cal-and-To-do';
    }

    .colours-container {
        margin-left: -10px;
    }

    .stu-title {
        display: none;
    }

    .stu-header-img,
    .Course-1,
    .Course-2,
    .Line-Graph,
    .Bar-Chart,
    .Cal-and-To-do {
        transform: scale(0.9);
    }

    .Course-1,
    .Course-2,
    .Line-Graph {
        margin-top: -15px;
    }

    .Bar-Chart {
        margin-top: -30px;
    }

    .cal-container {
        margin-top: -50px;
    }

    .stu-img-title {
        top: 34%;
        left: 30%;
    }
}