@import "../../../../globalStyles/helpers/variables";

.main-course-container{
    .course-heading{
        width: 100%;
        height: 35vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        // background-color: red;
    
        .courseType{
            font-size: 1.8em;
            font-weight: 700;
            color: $secondaryColor;
        }

        .lists {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .list-items {
            cursor: pointer;
            list-style-type: none;
            background: #FFFFFF;
            border: 1px solid #3A86FF;
            border-radius: 10px;
            text-align: center;
            margin-bottom: 20px;
            margin-right: 10px;
            padding: 10px;
    
                &:hover {
                    cursor: pointer;
                    background: #708bb5f0;
                    border-radius: 8px;
                    color: white;
                }
    
                &.active {
                    background: #3A86FF;
                    border-radius: 8px;
                    color: white;
                }
        }
    }
    
    .loader_cont{
        padding-block: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .cards-section {
       width: 100%;
       margin: 0;
    }
    
    // .top-card {
    //     margin-top: 40px;
    // }
    
    .top-picks-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        // display: grid;
        // grid-template-columns: auto auto auto;
        // justify-content: center;
        // background-color: gold;
        // margin: 20px 0px 30px 0px;
        // border: 1px solid red;
        // width: 100%;
    
        .noCourse{
            height: 20vh;
            width: 100%;
            font-size: 1.6em;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
// .popular-container {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//     gap: 20px 20px;
//     margin: 20px 0px 30px 0px;
// }

// .trending-container {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
//     gap: 20px 20px;
//     // margin: 20px 0px 30px 0px;
// }

@media (min-width: 1910px) {
    // .top-picks-container {
    //     display: grid;
    //     grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    //     gap: 20px 100px;
    //     // margin: 20px 0px 30px 0px;
    // }

    // .top-picks-title {
    //     font-size: 28px;
    //     display: flex;
    //     justify-content: center;
    //     font-weight: 800;
    //     margin-bottom: 20px;
    // }
}

@media (max-width: 1935px) {
    .cards-section {
        // margin-left: 10%;
        // margin-right: 10%;
        margin-bottom: 20px;
    }
}

@media (max-width: 1050px) {
    .cards-section {
        // margin-left: 8%;
        // margin-right: 8%;
        margin-bottom: 20px;
    }
}

@media (max-width: 1024px) {
    .cards-section {
        transform: scale(0.9);
        // margin: -10px -20px 20px -20px;
    }
}

@media (max-width: 992px) {
    .cards-section {
        transform: scale(1);
        // margin-left: 8%;
        // margin-right: 8%;
        // margin-bottom: 20px;
        // margin-top: 40px;
    }
}

@media (max-width: 727px) {
    // .top-picks-container {
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // }
    // .popular-container {
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // }

    // .trending-container {
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // }

    .cards-section {
        // margin-left: 15%;
        // margin-right: 15%;
        margin-bottom: 20px;
    }
}

// @media (max-width: 425px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .cards-section {
//         margin-left: 30px;
//     }
// }

// @media (max-width: 400px) {
//     .cards-section {
//         margin-left: 18px;
//     }
// }

// @media (max-width: 375px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     .cards-section {
//         margin-left: 10px;
//     }
// }

// @media (max-width: 350px) {
//     .top-picks-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }
//     .popular-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }

//     .trending-container {
//         grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
//     }

//     .cards-section {
//         margin-left: -7px;
//     }
// }