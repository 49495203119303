@import "../../../globalStyles/helpers/variables";

// tablet and desktop
#pageNotFound {
  display: flex;
  align-items: center;
  height: calc(100vh - 90px);
  .container {
    text-align: center;
    max-width: 600px;
    width: 100%;
    h1 {
      margin-bottom: 3rem;
    }
    h3 {
      margin-top: 3rem;
    }
    img {
      width: 100%;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      list-style: none;
      font-weight: bold;
      overflow: hidden;
      margin-top: 1.5rem;
      li {
        font-size: 24px;
        position: relative;
        margin-right: 2rem;
        color: $secondaryColor;
        &::after {
          content: ".";
          position: absolute;
          right: -22px;
          font-size: 2.5rem;
          bottom: -5px;
        }
        &:last-of-type {
          margin-right: 0;
          &::after {
            content: "";
          }
        }
        a {
          text-decoration: none;
          color: $secondaryColor;
        }
      }
    }
  }
}

// mobile devices
@media screen and (max-width: 768px) {
  #pageNotFound {
    .container {
      h3 {
        margin-top: 1.5rem;
      }
      ul {
        // flex-wrap: wrap;
        li {
          font-size: 1.125rem;
          margin-right: 1.5rem;
          &::after {
            font-size: 2rem;
            right: -18px;
            bottom: -3px;
          }
        }
      }
    }
  }
}

// extra small mobile devices
@media screen and (max-width: 359px) {
  #pageNotFound {
    .container {
      ul {
        flex-wrap: wrap;
        li {
          width: 80px;
          margin-right: 0;
          margin: 8px;
          &::after {
            content: "";
          }
          a {
          }
        }
      }
    }
  }
}

// landscape for mobile devices
@media screen and (min-width: 568px) and (max-height: 599px) {
  #pageNotFound {
    height: 100%;
    .container {
      margin-bottom: 2rem;
      img {
        width: 70%;
        height: auto;
      }
    }
  }
}
