#description {
  margin: 20px 0px;

  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 2fr;

  #content {
    grid-column: 2;

    h2 {
      color: #03023b;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.02em;
      background-color: white;
      margin: 0px;
      text-align: center;
      padding: 20px 0px 5px 0px;
      border-radius: 10px 10px 0px 0px;
    }

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      /* or 165% */

      letter-spacing: 0.06em;

      color: #000000;
    }

    .text span {
      color: #0837e5;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }
  #Requirements {
    display: none;
  }
}

//min-width: 481px:
@media only screen and (min-width: 481px) {
  #description {
    #content {
      h2 {
        text-align: start;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        /* or 131% */

        letter-spacing: 0.02em;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #description {
    #content {
      h1 {
        font-size: 24px;
        line-height: 29px;
        /* or 121% */
        letter-spacing: 0.02em;
      }
    }
  }
}
//min-width: 1024px:
@media only screen and (min-width: 1024px) {
  #description {
    grid-template-columns: 15vw 2fr 15vw;

    #content {
      h2 {
        font-size: 24px;
        line-height: 29px;
        /* identical to box height, or 121% */

        letter-spacing: 0.02em;
      }
      p,
      li {
        font-size: 16px;
        line-height: 26px;
        /* or 165% */

        letter-spacing: 0.06em;
      }
      #Requirements {
        display: block;
        ul li {
          margin: 0px 1em;
        }
      }
    }
  }
}
