@import '../../../../globalStyles/helpers/variables';

.step-content {
  display: flex;
  align-items: center;
  height: 480px;
  position: relative;
  padding-bottom: 3rem;
  z-index: 1;
    .mobile-wave {
        display: none;
    }
    .image {
        width: 50%;
        height: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .text-content {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 3rem;
        h3 {
            color: $secondaryColor;
            margin-bottom: 2rem;
            font-size: 2em;
        }
        p {
          font-size: 1.3em;
            &:last-of-type {
                margin-top: 10px;
            }
            &:first-of-type {
                margin: 0;
            }
        }
        button {
            background: $secondaryColor;
            color: white;
            width: 126px;
            height: 56px;
            font-size: 1rem;
            font-weight: bold;
            margin-top: 2rem;
            border-radius: $normal-radius;
            cursor: pointer;
            &:first-child {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .step-content {
        .mobile-wave {
            display: block;
            position: absolute;
            bottom: 0;
            z-index: -1;
            width: 100%;
            height: 560px;
            pointer-events: none;
        }
        h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2.5rem auto;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            color: $secondaryColor;
            background: white;
            box-shadow: 0px 2.31795px 2.31795px rgba(0, 0, 0, 0.1);
        }
        .image img {
            min-width: 300px;
        }
        flex-direction: column;
        height: 100%;
        .text-content {
            width: 100%;
            margin-left: 0;
            text-align: center;
            h3 {
                margin: 1.25rem 0;
            }
            button {
                margin: 40px auto 0 auto;
            }
        }
    }
}
