$primary: #3a86ff;

// QNA Tab Page 1 - Start
.qna-container {
    max-width: 100%;
    max-width: 94%;
    margin-left: 15px;
}

.qna-header {
    display: flex;
    justify-content: flex-end;
}

.filter-by {
    font-size: 14px;
    font-weight: 600;
    align-self: center;
    margin-top: 8px;
    margin-right: auto;
}

.drop {
    border: none;
    padding: 7px;
    margin: 5px;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    justify-content: center;
}

.drop:focus {
    outline: none;
}

select option {
    background-color: rgb(228, 228, 228);
}

.qna-search-bar:focus {
    outline-color: $primary;
}

.qna-search-container {
    position: relative;
    display: flex;
}

input.qna-search-bar {
    width: 100%;
    height: 35px;
    max-height: 100%;
    max-width: 100%;
    margin-top: 10px;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 20px 0 20px 30px;
}

textarea.qna-ask-bar {
    display: none;
}

.magnify-icon {
    position: absolute;
    width: 25px;
    height: auto;
    right: 2%;
    top: 33%;
}

.qna-card-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    margin-top: 20px;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 35px;
}

.qna-tags {
    display: flex;
}

.qna-card-title {
    color: $primary;
    font-size: 24px;
    font-weight: 700;
    margin-right: 80px;
    margin-bottom: 10px;
    text-align: left;
    line-height: 1.1;
    cursor: pointer;
    background-color: white;
}

.qna-tag-yellow {
    max-height: 25px;
    max-width: 100%;
    background-color: rgb(255, 255, 177);
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    padding: 3px 10px;
    margin-right: 5px;
    border-radius: 3px;
}

.qna-tag-grey {
    max-height: 25px;
    max-width: 100%;
    background-color: rgb(184, 184, 184);
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    padding: 3px 10px;
    border-radius: 3px;
}

.qna-card-body {
    margin: 20px 100px 20px 0;
}

.qna-card-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.qna-avatar-name {
    font-weight: 700;
    font-size: 14px;
    margin-left: 10px;
}

.qna-card-avatar {
    width: 35px;
    height: auto;
    margin-right: 5px;
    cursor: pointer;
}

.qna-card-responses {
    margin-left: auto;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.qna-interactions {
    margin-left: auto;
}

.arrow-up {
    margin-right: 28px;
    width: 30px;
    height: 30px;
    opacity: 0.8;
    cursor: pointer;
}

.number-of-upvotes {
    padding-left: 11px;
    font-size: 14px;
    color: #828282;
    position: absolute;
}

// Adjustments to avoid collision 
@media (max-width: 660px) {
    .qna-card-body {
        margin: 20px 0 0 0;
    }

    .qna-card-container {
        max-width: 100%;
        width: 100%;
        margin: 15px 50px 25px 0px;
    }

    .qna-answer-container {
        max-width: 100%;
        width: 100%;
    }

    .qna-container {
        max-width: 100%;
        width: 94%;
    }

    .qna-search-container {
        max-width: 100%;
        width: 100%;
    }

    .arrow-up {
        display: none;
    }

    .number-of-upvotes {
        display: none;
    }

    .drop {
        max-width: 100%;
        width: 98%;
    }

    .qna-header {
        display: flex;
        flex-direction: column;
    }
}

// Tablet
@media (max-width: 600px) {
    .arrow-up {
        display: none;
    }

    .number-of-upvotes {
        display: none;
    }

    .qna-tag-yellow,
    .qna-tag-grey {
        display: none;
    }

    .qna-card-title {
        text-align: center;
        margin: 0;
    }

    .qna-card-body {
        text-align: center;
    }
}

// Mobile
@media (max-width: 470px) {
    .drop {
        max-width: 100%;
        width: 94%;
    }

    .filter-by {
        display: none;
    }

    .back-btn {
        justify-content: flex-end;
    }

    .qna-header {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 98%;
    }

    .qna-container {
        margin-left: 14px;
    }

    .qna-questions {
        display: none;
    }

    .qna-search-container {
        display: none;
    }

    textarea.qna-ask-bar {
        display: inline;
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        height: 55px;
        margin-top: 10px;
        box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 10px 0 10px 15px;
        border: none;
    }

    .qna-ask-bar:focus {
        outline-color: $primary;
    }

    .qna-card-avatar {
        display: none;
    }

    .qna-card-footer {
        display: block;
        text-align: center;
    }

    .qna-highlight {
        color: rgb(20, 20, 255);
    }

    .qna-card-title {
        font-size: 22px;
    }

    .drop {
        max-width: 100%;
        width: 345px;
    }
}

// QNA Tab Page 1 - End

// Q&A Question Page 2 Start
.back-btn {
    display: flex;
    max-width: 100%;
}

.qna-back {
    display: flex;
    justify-content: flex-end;
    border: none;
    padding: 7px;
    margin: 5px;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    font-weight: 700;
    background: white;
}

.qna-reply-highlight {
    color: $primary;
    margin-right: 23px;
}

.qna-responses {
    margin-left: auto;
    // margin-top: 30px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.qna-answer-container {
    position: relative;
    max-width: 100%;
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0.2px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 35px;
}

.qna-responses-answers {
    margin-left: auto;
    margin-top: 40px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.number-of-upvotes-ans {
    font-size: 14px;
    color: #828282;
    margin-left: auto;
    position: absolute;
    right: 0;
    padding-right: 74px;
    padding-top: 47px;
}

.arrow-up-ans {
    position: relative;
    margin-left: auto;
    margin-right: 28px;
    width: 30px;
    height: 30px;
    opacity: 0.8;
    cursor: pointer;
}

.qna-answer-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.qna-answer-divider {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #4f4f4f;
}

.qna-avatar-name-reply {
    font-weight: 700;
    font-size: 14px;
    margin-top: 30px;
    padding-bottom: 10px;
}

.arrow-up-grey-ans {
    position: relative;
    margin-left: auto;
    margin-right: 28px;
    margin-bottom: 5px;
    width: 30px;
    height: 30px;
    opacity: 0.8;
    cursor: pointer;
    opacity: 0.4;
}

@media (max-width: 670px) {
    .qna-answer-container {
        max-width: 100%;
        width: 100%;
        margin: 15px 50px 25px 0px;
    }

    .arrow-up-ans {
        display: none;
    }

    .arrow-up-grey-ans {
        display: none;
    }

    .number-of-upvotes-ans {
        display: none;
    }

    .qna-reply-highlight {
        margin-right: 0;
    }

    .qna-avatar-name {
        margin-left: 0;
    }


}