.inputTitle {
    border: 2px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
    width: 100%;
    padding-left: 1rem;
    &:focus {
        outline: none;
    }
}
