.scroll-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

// .scroll-to-top,
.scroll-to-bottom {
    padding: 5px 10px;
    background-color: #3A86FF;
    // box-shadow: 0 2px 30px 0 rgba(31, 38, 135, 0.07);
    // backdrop-filter: blur(4px);
    // -webkit-backdrop-filter: blur(4px);
    // border: 1px solid rgba(255, 255, 255, 0.18);
    color: whitesmoke;
    font-size: 12px;
    // border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    animation: pulse 1s infinite;
}

// .scroll-to-bottom::before {
//     content: '▼'; /* Arrow down symbol */
// }
// .scroll-to-top::before {
//     content: '▲'; /* Arrow up symbol */
// }

// .scroll-to-top:hover,
.scroll-to-bottom:hover {
    background-color: #0056b3;
    color: white;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1.0); /* Adjust scaling to your preference */
    }
}